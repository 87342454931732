import {
  Grid,
} from "@mui/material";
import SideInfo from "./Authentication/SideInfo";



const DataDeletion: React.FC = () => {

  

  return (
    <>
      <Grid container>
        <SideInfo />
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: { xs: "40px", md: 0 },
            paddingBottom: { xs: "40px", md: 0 },
          }}
        >
    
                <p>
                  To request your data deletion, kindly send a mail to admin@maihomm.com
                </p>
          
        </Grid>
      </Grid>
    </>
  );
};

export default DataDeletion;
