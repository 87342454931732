const Map = () => {
  const randNum = Math.random();
  return (
    <>
      <div className="lg:w-[50%] w-full"> {/* Width set to 24rem (equivalent to 600px) and centered */}
        <iframe
          title={randNum}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9517.520697978318!2d-2.5939998!3d53.3901389!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b02392f9e7f79%3A0x9d9c13fca51394f8!2sThe%20Works!5e0!3m2!1sen!2sng!4v1686726832334!5m2!1sen!2sng"
          className="w-full h-96 border-0" // Width 100% and height set to 24rem (450px)
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default Map;
