import { Formik, Form, Field } from "formik";
import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { userRequest } from "../../requestMethod";
import SideInfo from "./SideInfo";
import { loginUserSuccess } from "../../services/loginSlice";

const TwoFactorAuth = () => {
  // let loading = false;
  const [loading, setLoading] = useState(false);
  const [pendingUserData, setPendingUserData] = useState<any>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const user = Cookies.get("userData");
    if (user) {
      navigate("/overview");
      window.location.reload();
    } else {
      let pendingUser = Cookies.get("pendingUser") || "";
      setPendingUserData(JSON.parse(pendingUser));
      // console.log(JSON.parse(pendingUser));
    }
  }, [navigate]);

  const initialValues = {
    otp: 0,
  };

  const handleSubmit = async (values: { otp: number }) => {
    setLoading(true);
    try {
      const res = await userRequest.post(`/auth/2fa-code/verify`, {
        code: values.otp,
        email: pendingUserData.email,
      });
      const validatedUser = {...pendingUserData, token: res.data.data.token};
      Cookies.set("userData", JSON.stringify(validatedUser));
      Cookies.remove("pendingUserData");
      dispatch(loginUserSuccess(pendingUserData));
      toast.success("Login Successful");
      setTimeout(() => {
        navigate("/overview");
        window.location.reload();
      }, 2000);
    } catch (err) {
      toast.error("An error occured", {
        // position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
    setLoading(false);
  };

  const handleResend = async () => {
    setLoading(true)
    try {
      await userRequest.post(`/auth/2fa-code/send`,{
        "2fa_token":  pendingUserData['2fa_token']
      });
      toast.success("Otp resent", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (err) {
      toast.error("Error occured while resending otp", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
    setLoading(false);
  };

  const textFieldStyle = {
    fontSize: "12px",
    backgroundColor: "#1E1E1E",
    borderRadius: "10px",
    marginTop: "5px",
    padding: "10px 15px",
  };

  return (
    <Grid container>
      <SideInfo />
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: { xs: "40px", md: 0 },
          paddingBottom: { xs: "40px", md: 0 },
        }}
      >
        <Formik
          initialValues={initialValues}
          //   validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form style={{ maxWidth: "530px", width: "85%" }}>
            <div className="form-control">
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "1.25rem" }}
                gutterBottom
              >
                Two-Factor Authentication
              </Typography>
              <Typography variant="body1" sx={{ color: "#B6B7B9", mb: 5 }}>
                A one-time code has been sent to{" "}
                {pendingUserData &&
                  pendingUserData.email.slice(0, 2) +
                    "****" +
                    pendingUserData.email.slice(
                      pendingUserData.email.length - 12
                    )}
                . Ensure you also check your spam
              </Typography>

              <Typography
                sx={{ color: "#B6B7B9" }}
                variant="subtitle2"
                gutterBottom
              >
                Enter code
              </Typography>
              <Field
                as={TextField}
                type="number"
                // label="Enter code"
                name="otp"
                size="small"
                variant="standard"
                style={textFieldStyle}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
            </div>

            <Box sx={{ mt: 4 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={loading}
                sx={{ mb: 2 }}
              >
                {loading ? <CircularProgress size={24} /> : "Login"}
              </Button>

              <p>
                Haven't seen code?{" "}
                <Button sx={{ fontSize: ".9rem" }} onClick={handleResend}>
                  {" "}
                  Resend code
                </Button>
              </p>
            </Box>
          </Form>
        </Formik>
      </Grid>
    </Grid>
  );
};

export default TwoFactorAuth;
