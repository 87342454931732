import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaHeart, FaRegHandshake } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const Whishlist = () => {
  const userData = useSelector((state: any) => state.login.userData);
  const [wishlist, setWishlist] = useState<Array<{ id: string }>>([]);
  const [cartlist, setCartlist] = useState<Array<{ id: string }>>([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const fetchData = async () => {
      if (!userData || !userData.data.token) return;

      try {
        const response = await fetch(`${baseUrl}/user/properties/saved/properties`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData.data.token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        setWishlist(result?.data?.saved_properties || []);
      } catch (error) {
        console.error("Error fetching user overview:", error);
      }
    };

    fetchData();
  }, [baseUrl, userData]);
  const handleCartClick = async (id: string) => {
    console.log(id, "id")
    console.log("id")
    try {
      const response = await fetch(`${baseUrl}/user/carts/items/add/${id}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.data.token}`,
        },
       
      });
      const result = await response.json();
      toast.success(result.message);
    } catch (error) {
      console.error('Error making favorite request:', error);
    }
  };
  useEffect(() => {
    const fetchCartData = async () => {
      if (!userData || !userData.data.token) return;

      try {
        const response = await fetch(`${baseUrl}/user/carts/items`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData.data.token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        console.log(result, "cart list")
        setCartlist(result?.data || []);
      } catch (error) {
        console.error("Error fetching user overview:", error);
      }
    };

    fetchCartData();
  }, [baseUrl, userData]);
  return (
    <div className='w-full pb-[30px]'>
      <div className="flex flex-col gap-8">
        {wishlist.map((item: any, index: any) => {
           const isCart = cartlist.some(items => items.id === item.id);
          return (
          <div key={index} className="flex flex-col bg-[#1E1E1E]">
            <img src={item.files[0].url || '/assets/images/MaskGroup.svg'} alt="Placeholder" className="lg:w-full w-50 lg:h-full h-50 object-cover mb-2" />
            <div className='p-4'>
              <p className='lg:text-[20px] text-[14px] font-jakarta leading-[30px]'>{item.name}</p>
              <p className='lg:text-[14px] text-[12px] font-jakarta leading-[30px] text-[#FCFCFC]'>{item.location.address}</p>
            </div>
            <div className='p-4'>
              <div className='flex justify-between'>
                <p className='lg:text-[16px] text-[12px] font-jakarta'>{item.costings.price} per slot</p>
                <div className='flex gap-2'>
                  <FaHeart className="text-[#DEA838] h-[20px] w-[20px] cursor-pointer" />
                  <div className='z-10'>
                  <FaRegHandshake   onClick={() => handleCartClick(item.id)}  className={isCart ? 'text-yellow-500' : 'text-gray-400'}/>
                  </div>
                </div>
              </div>
              <hr className="border-t-2 border-[#B6B7B9] mt-2" />
              <div className='flex lg:flex-row flex-col lg:justify-between my-4'>
                <div className='flex gap-2 items-center'>
                  <img src="/assets/images/Bed.svg" className="h-[20px] w-[20px]" />
                  <p className='text-[#FCFCFC] font-jakarta'>{item.features.bedrooms} Bedrooms</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <img src="/assets/images/bathtub.svg" className="h-[20px] w-[20px]" />
                  <p className='text-[#FCFCFC] font-jakarta'>{item.features.bathrooms} Bathrooms</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <img src="/assets/images/dashboard.svg" className="h-[20px] w-[20px]" />
                  <p className='text-[#FCFCFC] font-jakarta'>{item.features.sqft} Sq.Ft</p>
                </div>
              </div>
            </div>
          </div>
);})}
      </div>
    </div>
  );
};

export default Whishlist;
