import { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import { GoSearch } from "react-icons/go";
import { useSelector } from "react-redux";
import Property from "../../components/property";

const Properties = () => {
  const [value, setValue] = useState("1");
  const [searchTerm, setSearchTerm] = useState("");
  const [properties, setProperties] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const userData = useSelector((state: any) => state.login.userData);

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const getProperties = async () => {
      try {
        setIsLoading(true); 

        const response = await fetch(`${baseUrl}/user/properties`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userData?.data?.token}`, 
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        setProperties(data.data.data); 
      } catch (err) {
        console.error(err);
        setError("Failed to load properties.");
      } finally {
        setIsLoading(false); 
      }
    };

    getProperties();
  }, [userData]);

  // Filter properties by status when value is '2' (Available)
  const filteredProperties = value === "2"
    ? properties.filter(property => property.status === "Available")
    : properties;

  return (
    <Wrapper>
      <div className="w-full">
        <div className="w-full flex lg:flex-row flex-col lg:gap-0 gap-3 lg:items-center lg:justify-between ">
          <div className="flex lg:w-[50%] w-full py-[6px] lg:px-[10px] lg:gap-3 bg-[#1E1E1E] rounded-lg">
            <button
              className={`text-white lg:text-[14px] text-[12px] font-jakarta lg:px-4 px-2 py-1 ${
                value === "1" ? "bg-[#DEA73A] text-black rounded-lg" : ""
              }`}
              onClick={() => handleChange("1")}
            >
              All
            </button>
            <button
              className={`text-white lg:text-[14px] text-[12px] font-jakarta flex items-center gap-1 lg:px-4 px-2 py-1 ${
                value === "2" ? "bg-[#DEA73A] text-black rounded-lg" : ""
              }`}
              onClick={() => handleChange("2")}
            >
              <img src="/assets/images/Ellipse 1844.svg"/>
              Available
            </button>
            <button
              className={`text-white lg:text-[14px] text-[12px] font-jakarta flex items-center gap-1 lg:px-4 px-2 py-1 ${
                value === "3" ? "bg-[#DEA73A] text-black rounded-lg" : ""
              }`}
              onClick={() => handleChange("3")}
            >
              <img src="/assets/images/Ellipse 1844 (1).svg"/>
              Sold Out
            </button>
            <button
              className={`text-white lg:text-[14px] text-[12px] flex font-jakarta items-center gap-1 lg:px-4 px-2 py-1 ${
                value === "4" ? "bg-[#DEA73A] text-black rounded-lg" : ""
              }`}
              onClick={() => handleChange("4")}
            >
              <img src="/assets/images/Ellipse 1844 (2).svg"/>
              Coming Soon
            </button>
          </div>
          <div className="flex items-center justify-between lg:ml-4 ml-0 bg-transparent border border-[#33383F] rounded-lg px-2">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search..."
              className="bg-transparent text-white px-4 py-[8px] rounded-lg outline-none w-48"
            />
            <GoSearch />
          </div>
        </div>
        <div className="py-5">
          {isLoading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {!isLoading && !error && value === "2" && <Property properties={filteredProperties} />}
          {!isLoading && !error && value === "1" && <Property properties={properties} />}
          {!isLoading && !error && value === "3" && <Property properties={properties.filter(property => property.status === "Sold")} />}
          {!isLoading && !error && value === "4" && <Property properties={properties.filter(property => property.status === "Coming Soon")} />}
        </div>
      </div>
    </Wrapper>
  );
};

export default Properties;
