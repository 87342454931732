export const formatPrice = (price) => {
    // eslint-disable-next-line
    if (price == Number(price)) {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: "GBP",
            minimumFractionDigits: 2,
        }).format(price / 1);
    } else {
        return price
    }
}