import { createSlice } from "@reduxjs/toolkit";

const applicationLoanSlice = createSlice({
  name: "loan",
  initialState: {
    data: [],
    status: "idle",
  },
  reducers: {
    setApplicationLoan(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});
export const { setApplicationLoan, setStatus } = applicationLoanSlice.actions;
export default applicationLoanSlice.reducer;
