import { useState } from "react";
import Wrapper from "../../components/Wrapper";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RiArrowDropDownLine } from "react-icons/ri";

const Support = () => {
  const [message, setMessage] = useState({
    title: "",
    text: "",
  });
  const [category, setCategory] = useState("Select Category");
  const [status, setStatus] = useState("Select Status");
  const [priority, setPriority] = useState("Select Priority");

  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isPriorityOpen, setIsPriorityOpen] = useState(false);

  const userData = useSelector((state: any) => state.login.userData);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  let new_form = new FormData();
  new_form.append("title", message.title);
  new_form.append("message", message.text);
  new_form.append("category", category);
  new_form.append("status", status);
  new_form.append("priority", priority);

  async function submitMess() {
    if (
      message.title &&
      message.text &&
      category !== "Select Category" &&
      status !== "Select Status" &&
      priority !== "Select Priority"
    ) {
      await axios
        .post(`${baseUrl}/user/supports/submit`, new_form, {
          headers: {
            Authorization: `Bearer ${userData?.data?.token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          toast.success("Message sent successfully");
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      toast.error("Please fill all fields before submitting.");
    }
  }

  const categories = ["Technical Issue", "Account Issue", "Other"];
  const statuses = ["Open", "In Progress", "Closed"];
  const priorities = ["Low", "Medium", "High"];

  return (
    <Wrapper>
      <form className="mt-5">
        {/* Title and Category Row */}
        <div className="flex justify-between mb-5">
          <div className="w-1/2 pr-2">
            <p className="font-jakarta text-white mb-2">Title</p>
            <input
              className="font-jakarta bg-[#1E1E1E] text-white w-full outline-none border-none rounded-lg p-4"
              type="text"
              onChange={(e) =>
                setMessage((prev) => {
                  return { ...prev, title: e.target.value };
                })
              }
            />
          </div>
          <div className="w-1/2 pl-2">
            <p className="font-jakarta text-white mb-2">Category</p>
            <div className="relative">
              <div
                className="bg-[#1E1E1E] flex justify-between items-center text-white p-4 rounded-lg cursor-pointer"
                onClick={() => setIsCategoryOpen(!isCategoryOpen)}
              >
                {category}
                <RiArrowDropDownLine />

              </div>
              {isCategoryOpen && (
                <div className="absolute bg-[#1E1E1E] w-full mt-1 rounded-lg z-10">
                  {categories.map((cat, index) => (
                    <div
                      key={index}
                      className="p-2 hover:bg-gray-700 cursor-pointer text-white"
                      onClick={() => {
                        setCategory(cat);
                        setIsCategoryOpen(false);
                      }}
                    >
                      {cat}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Status and Priority Row */}
        <div className="flex justify-between mb-5">
          <div className="w-1/2 pr-2">
            <p className="font-jakarta text-white mb-2">Status</p>
            <div className="relative">
              <div
                className="bg-[#1E1E1E] flex items-center justify-between text-white p-4 rounded-lg cursor-pointer"
                onClick={() => setIsStatusOpen(!isStatusOpen)}
              >
                {status}
                <RiArrowDropDownLine />
              </div>
              {isStatusOpen && (
                <div className="absolute bg-[#1E1E1E] w-full mt-1 rounded-lg z-10">
                  {statuses.map((stat, index) => (
                    <div
                      key={index}
                      className="p-2 hover:bg-gray-700 cursor-pointer text-white"
                      onClick={() => {
                        setStatus(stat);
                        setIsStatusOpen(false);
                      }}
                    >
                      {stat}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="w-1/2 pl-2">
            <p className="font-jakarta text-white mb-2">Priority</p>
            <div className="relative">
              <div
                className="bg-[#1E1E1E] flex items-center justify-between text-white p-4 rounded-lg cursor-pointer"
                onClick={() => setIsPriorityOpen(!isPriorityOpen)}
              >
                {priority}
                <RiArrowDropDownLine />
              </div>
              {isPriorityOpen && (
                <div className="absolute bg-[#1E1E1E] w-full mt-1 rounded-lg z-10">
                  {priorities.map((prio, index) => (
                    <div
                      key={index}
                      className="p-2 hover:bg-gray-700 cursor-pointer text-white"
                      onClick={() => {
                        setPriority(prio);
                        setIsPriorityOpen(false);
                      }}
                    >
                      {prio}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mb-5">
          <p className="font-jakarta text-white mb-2">Message</p>
          <textarea
            className="font-jakarta bg-[#1E1E1E] text-white w-full h-40 border-none rounded-lg p-4"
            onChange={(e) =>
              setMessage((prev) => {
                return { ...prev, text: e.target.value };
              })
            }
          />
        </div>

        <div>
          <button
            type="button"
            className="font-jakarta bg-[#DEA838] text-black py-2 px-4 rounded-lg mt-2"
            onClick={submitMess}
          >
            Send Now
          </button>
        </div>
      </form>
    </Wrapper>
  );
};

export default Support;
