import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import { userRequest } from "../../requestMethod";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogueUpload(props) {
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setFiles(event.target.files);
  };

  const handleUpload = async () => {
    try {
      await Promise.all([
        props.slots.map((property) =>
          // userRequest.post("user/properties/payment/send", {
          userRequest.post("user/carts/checkout", {
            // property_id: property[0],
            // slots: property[1],
            files: [...files],
          })
        ),
      ]);
      setOpen(false);
      toast.success('Payment proof sent successfully. Do not resend');
      setTimeout(() => {
        navigate("/portfolio");
        window.location.reload();
      }, 3000);
    } catch (err) {
      // console.log(err);
      setOpen(false);
      toast.error("error occured, possibly image format or server error");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{ margin: "10px 0" }}
        onClick={handleClickOpen}
      >
        I have made my payments
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ fontSize: 20, fontWeight: 300 }}>
          {"Upload Payment Proof"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box>
              <input type="file" multiple onChange={handleChange} />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: "#fff" }}>
            Close
          </Button>
          <Button onClick={handleUpload}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
