import { useEffect, useState } from "react";
import WalletBalance from "./walletBalance";
import Wrapper from "../../components/Wrapper";
import RewardBalance from "./rewardBalance";
import { useSelector } from "react-redux";
import { LiaTimesSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";

interface Transaction {
  description: string;
  date: string;
  amount: number;
  status: string;
}

interface UserWallet {
  id: number;
  currency: string;
  balance: string;
  locked_balance: string;
  total_credit: string;
  total_debit: string;
}

const Wallet = () => {
  const [userDatas, setUserData] = useState<any>(null);
  const [userWallet, setUserWallet] = useState<UserWallet | any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const userData = useSelector((state: any) => state.login.userData);
  const navigate = useNavigate();
  // Modal state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (userData?.data?.user_type === "Guest") {
      // Open login modal if the user is not authenticated
      setIsModalOpen(true);
    } 
  }, [userData]);
  const handleUpgradeClick = () => {
    navigate('/auth/kyc'); // Route to /auth/kyc when clicked
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${baseUrl}/user/transactions`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData?.data?.token}`, // Attach the token
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const data = await response.json();
        console.log(data, "transaction data")
        setUserData(data?.data?.data); // Set the user data
        setLoading(false);
      } catch (err) {
        setError("Unable to load profile");
        setLoading(false);
      }
    };

    fetchProfile();
  }, [userData?.data?.token]);

  useEffect(() => {
    const fetchWalletBalance = async () => {
      try {
        const response = await fetch(`${baseUrl}/user/wallets/my-wallet`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData?.data?.token}`, // Attach the token
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const data = await response.json();
        console.log(data, "wallet22");
        setUserWallet(data?.data); // Set the user wallet data
        setLoading(false);
      } catch (err) {
        setError("Unable to load profile");
        setLoading(false);
      }
    };

    fetchWalletBalance();
  }, [userData?.data?.token]);

  const transactionsData = [
    { description: "Transaction 1", date: "2024-07-01", amount: 100, status: "Completed" },
    { description: "Transaction 2", date: "2024-07-02", amount: 150, status: "Pending" },
    { description: "Transaction 3", date: "2024-07-03", amount: 200, status: "Completed" },
    // ... other transactions
  ];

  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(
    (userDatas && userDatas.length > 0 ? userDatas : transactionsData).length / itemsPerPage
  );

  const handlePrev = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const currentTransactions = (userDatas && userDatas.length > 0 ? userDatas : transactionsData).slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, (userDatas && userDatas.length > 0 ? userDatas : transactionsData).length);

  return (
    <Wrapper>
      {/* Login Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-black p-6 rounded-lg w-[30%] shadow-lg">
          <div className="flex items-center cursor-pointer justify-end">
            <LiaTimesSolid onClick={() => setIsModalOpen(false)} />
          </div>
          <p className="font-semibold text-[40px] text-center font-jakarta leading-[40px] mb-4">
            Upgrade to a Co-Owner Account
          </p>
          <p className="text-center font-jakarta leading-[20px]">
            To access the Portfolio, you have to upgrade to a co-owner’s account by uploading your KYC verification documents
          </p>
          <p
            className="bg-[#DEA838] p-3 font-jakarta rounded-[5px] cursor-pointer text-center font-[700] text-[16px] text-black my-4"
            onClick={handleUpgradeClick} // Call the route function here
          >
            Upgrade
          </p>
          <p
            className="border border-[#DEA838] text-[#DEA838] text-[16px] font-jakarta text-center cursor-pointer rounded-[5px] p-3"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </p>
        </div>
      </div>
      )}

      {/* Wallet and Transaction Table */}
      {!isModalOpen && (
        <>
          <div className="flex lg:flex-row flex-col gap-4">
            <div className="w-full sm:w-1/2">
              {userWallet && (
                <WalletBalance
                  currency={userWallet.currency}
                  reward_balance={userWallet.reward_balance}
                  wallet_balance={userWallet.wallet_balance}
                  locked_balance={userWallet.locked_balance}
                  total_credit={userWallet.total_credit}
                  total_debit={userWallet.total_debit}
                />
              )}
            </div>
            <div className="w-full sm:w-1/2">
              {userWallet && (
                <RewardBalance
                  currency={userWallet.currency}
                  reward_balance={userWallet.reward_balance}
                  locked_balance={userWallet.locked_balance}
                  total_credit={userWallet.total_credit}
                  total_debit={userWallet.total_debit}
                />
              )}
            </div>
          </div>

          <div>
            <h2 className="text-[16px] font-bold my-4 font-jakarta">Transaction</h2>
            <div className="bg-[#1E1E1E] w-full p-4 rounded-[10px] overflow-x-auto">
              <table className="lg:w-full w-[130%] bg-[#1E1E1E]">
                <thead>
                  <tr>
                    <th className="py-4 px-4 border-b border-b-[#33383F] font-jakarta text-start text-[14px] text-[#9A9FA5]">
                      Property
                    </th>
                    <th className="py-4 px-4 border-b border-b-[#33383F] font-jakarta text-start text-[14px] text-[#9A9FA5]">
                      Date
                    </th>
                    <th className="py-4 px-4 border-b border-b-[#33383F] font-jakarta text-start text-[14px] text-[#9A9FA5]">
                      Amount
                    </th>
                    <th className="py-4 px-4 border-b border-b-[#33383F] font-jakarta text-start text-[14px] text-[#9A9FA5]">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userWallet?.transactions?.map((transaction: any, index: number) => (
                    <tr key={index}>
                      <td className="py-3 px-4 border-b border-b-[#33383F] font-jakarta text-[12px]">
                        {transaction.description}
                      </td>
                      <td className="py-3 px-4 border-b border-b-[#33383F] font-jakarta text-[12px]">
                        {transaction.date}
                      </td>
                      <td className="py-3 px-4 border-b border-b-[#33383F] font-jakarta text-[12px]">
                      {transaction.currency}{transaction.amount}
                      </td>
                      <td className="py-3 px-4 border-b border-b-[#33383F] font-jakarta text-[12px]">
                        {transaction.status}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="lg:w-[90%] w-full px-[2%] flex justify-between items-center mt-4">
            <div className="text-[12px]">
              Showing {startItem} - {endItem} of {(userDatas && userDatas.length > 0 ? userDatas : transactionsData).length}
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={handlePrev}
                disabled={currentPage === 1}
                className="px-3 text-[14px] p-1 border border-[#33383F] font-jakarta rounded bg-[#33383F] text-white disabled:bg-[#33383F]"
              >
                Prev
              </button>
              <button
                onClick={() => handlePageClick(1)}
                className={`px-3 text-[14px] p-1 font-jakarta border border-[#DEA838] rounded ${
                  currentPage === 1 ? "bg-[#DEA838] text-black" : "bg-[#33383F] text-white"
                }`}
              >
                1
              </button>
              <button
                onClick={() => handlePageClick(2)}
                className={`px-3 text-[14px] p-1 font-jakarta border border-[#DEA838] rounded ${
                  currentPage === 2 ? "bg-[#DEA838] text-black" : "bg-[#33383F] text-white"
                }`}
              >
                2
              </button>
              <button
                onClick={handleNext}
                disabled={currentPage === totalPages}
                className="px-3 text-[14px] p-1 border border-[#33383F] font-jakarta rounded bg-[#33383F] text-white disabled:bg-[#33383F]"
              >
                Next
              </button>
            </div>
          </div>
            </div>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default Wallet;
