import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import { authImage, logo } from "../../assets";
import "tailwindcss/tailwind.css";
import { AiOutlineDown } from "react-icons/ai";
import { BsUpload } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { CgUnavailable } from "react-icons/cg";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const textFieldStyle =
  "text-[14px] text-[white] mb-4 py-3 px-5 bg-[#1E1E1E] border border-[#33383F] rounded-lg flex items-center";

const Kyc: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [idType, setIdType] = useState("Select Document");
  const [showErrors, setShowErrors] = useState(false);
  const [country, setCountry] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<"manual" | "third-party">("manual");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCountry(event.target.value);
  };

  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | undefined>(undefined);

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);

      // Create a preview URL using FileReader
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleIdTypeChange = (type: string) => {
    setIdType(type);
    setDropdownOpen(false);
  };

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    country: "",
    idType: "",
    file: "",
    checkbox: "",
  });
  const userData = useSelector((state: any) => state.login.userData);
  console.log(userData?.data?.token, "kyc tokeen")

  const handleSubmits = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
  
    const newErrors = { country: "", idType: "", file: "", checkbox: "" };
  
    if (!country) {
      newErrors.country = "Country is required";
    }
    if (!idType) {
      newErrors.idType = "ID Type is required";
    }
    if (!file?.name) {
      newErrors.file = "File is required";
    }
    if (!checked) {
      newErrors.checkbox = "You must agree to the terms";
    }
    if (newErrors.country || newErrors.idType || newErrors.file) {
      setErrors(newErrors);
      setLoading(false);
      setTimeout(() => {
        setErrors({ country: "", idType: "", file: "", checkbox: "" });
      }, 2000);
      return;
    }
  
    setErrors({ country: "", idType: "", file: "", checkbox: "" });
  
    // Retrieve the token from cookies
    const token = Cookies.get("token");
  
    const body = {
      id_type: idType,
      id_card: file?.name,
      nin: country,
    };
  
    // Log the body
    console.log("Request Body:", body);
  
    try {
      await userRequest.post("/user/profile/kyc-verification", body, {
        headers: {
          Authorization: `Bearer ${userData?.data?.token}`, // Include the token in the headers
        },
      });
  
      toast.success("ID sent for verification", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
  
      setTimeout(() => {
        navigate("/overview");
      }, 2000);
    } catch (err: any) {
      console.log(err);
      toast.error("ID verification failed", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  
    setLoading(false);
  };
  
  

  return (
    <div
      className="relative flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${authImage})` }}
    >
      <div className="absolute inset-0 bg-black opacity-90"></div>
      <div className="relative flex flex-col md:flex-row w-full max-w-6xl p-4 bg-transparent rounded-lg shadow-lg">
        <div className="flex flex-col justify-center w-full md:w-1/2 text-white p-4">
          <div className="flex justify-center md:absolute md:top-5 md:justify-start mb-10">
            <Link to={"/"}>
            <img src={logo} alt="logo" className="h-14 w-56 mb-4 md:mb-0" />
            </Link>
          </div>
          <h1 className="text-[24px] md:text-[42px] font-[600] w-full md:w-[90%] font-jakarta leading-[30px] md:leading-[45px] mb-4">
            Are my funds and asset safe at Maihomm?
          </h1>
          <p className="text-[14px] md:text-[16px] font-[400] w-full md:w-[83%] font-jakarta tracking-wider mb-8">
            All clients funds and co-owned properties are held in Trust by
            Maihomm Trustees, a UK registered trust and regulated by the HMRC.
          </p>
        </div>
        <div className="flex items-center justify-center w-full md:w-2/3 p-4">
          <div
            className="w-full p-4 md:p-8 py-[30px] rounded-[30px] custom-scroll-content font-jakarta border border-[#6F767E] shadow-lg"
            style={{
              overflowY: "auto",
              WebkitOverflowScrolling: "touch",
              background: "linear-gradient(135deg, #1B1818 0%, #1B1818 100%)",
            }}
          >
            <form className="max-w-lg w-full " onSubmit={handleSubmits}>
              <p className="text-[20px] md:text-[30px] font-jakarta font-[600]">
                Upload a proof of your Identity
              </p>
              <p className="text-[#9A9FA5] font-[400] font-jakarta mb-4 text-[12px] md:text-[14px]">
                Let’s get you started. Create an account to begin
              </p>
              <div className="flex w-full justify-between gap-5 my-[30px]">
                <p
                  className={`w-[90%] py-[7px] rounded-[8px] text-[14px] font-jakarta cursor-pointer text-center border ${
                    selectedOption === "manual"
                      ? "text-[#DEA838] border-yellow-500"
                      : "text-white border-[#6F767E] bg-[#33383F]"
                  }`}
                  onClick={() => setSelectedOption("manual")}
                >
                  Manual
                </p>
                <p
                  className={`w-[90%] py-[7px] rounded-[8px] text-[14px] font-jakarta cursor-pointer text-center border ${
                    selectedOption === "third-party"
                      ? "text-[#DEA838] border-yellow-500"
                      : "text-white border-[#6F767E] bg-[#33383F]"
                  }`}
                  onClick={() => setSelectedOption("third-party")}
                >
                  Third-party verification
                </p>
              </div>
              {selectedOption === "manual" ? (
                <div>
                  <div className="mb-4">
                    <div className="w-full">
                      <label
                        htmlFor="country"
                        className="text-[20px] font-[500] font-jakarta"
                      >
                        Nationality
                      </label>
                      <div
                        className={`${
                          errors.country ? "border border-[red]" : ""
                        } w-full border border-[#33383F] rounded-lg mt-4`}
                      >
                        <input
                          name="country"
                          type="text"
                          placeholder="United Kingdom"
                          className={`p-2 rounded-[10px] text-white bg-[#1E1E1E] font-jakarta placeholder-[#33383F] outline-none w-full`}
                          value={country}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.country && (
                        <div className="text-red-500 text-xs mt-1">
                          country need
                        </div>
                      )}
                    </div>
                    <p className="text-[20px] font-[500] mb-3 font-jakarta">
                      Document
                    </p>
                    <div className="relative mb-4">
                      <div
                        className={`${textFieldStyle} ${
                          errors.idType ? "border border-[red]" : ""
                        } cursor-pointer`}
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                      >
                        <span>{idType}</span>
                        <AiOutlineDown className="ml-2" />
                      </div>
                      {dropdownOpen && (
                        <div className="absolute z-10 mt-1 w-full bg-[#33383F] rounded-lg shadow-lg">
                          <p
                            className="px-4 py-2 cursor-pointer hover:bg-[#9A9FA5]"
                            onClick={() => handleIdTypeChange("NIN")}
                          >
                            NIN
                          </p>
                          <p
                            className="px-4 py-2 cursor-pointer hover:bg-[#9A9FA5]"
                            onClick={() =>
                              handleIdTypeChange("International Passport")
                            }
                          >
                            International Passport
                          </p>
                          <p
                            className="px-4 py-2 cursor-pointer hover:bg-[#9A9FA5]"
                            onClick={() => handleIdTypeChange("Drivers License")}
                          >
                            Drivers License
                          </p>
                          <p
                            className="px-4 py-2 cursor-pointer hover:bg-[#9A9FA5]"
                            onClick={() => handleIdTypeChange("Voters Card")}
                          >
                            Voters Card
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="file"
                      className="text-[20px] font-[500] font-jakarta"
                    >
                      Upload Document
                    </label>
                    <div className=" mb-4">
                  <label className="flex flex-col gap-3 py-7 items-center justify-center w-full border-2 border-[#33383F] bg-[#1E1E1E] rounded-lg">
                    <div className="flex flex-col items-center justify-center">
                      <BsUpload className="w-8 h-8 text-white" />
                      <p className="text-[16px] text-white mb-2">
                        Upload your document
                      </p>
                      <p className="text-[14px] text-[#9A9FA5] text-center px-[14%] mb-4">Upload the front side of your document.
                        Supports: JPG, PNG,PDF</p>

                      <p className="text-[#DEA838] rounded-[5px] text-[14px] border border-[#DEA838] py-[4px] px-[15px] cursor-pointer">Choose a file</p>
                      <input
                        id="id_card"
                        name="id_card"
                        type="file"
                        accept="application/pdf, image/png, image/jpeg"
                        className="hidden"
                        onChange={handleChanges}
                      />
                      {file && (
                        <div className="mt-4">
                          {file.type.includes('image/') ? (
                            <img
                              src={preview}
                              alt="Uploaded File"
                              className="max-w-full h-auto rounded-lg"
                            />
                          ) : (
                            <p className="text-white">File type not supported for preview.</p>
                          )}
                        </div>
                      )}
                    </div>
                  </label>
                  {errors.file && <div className="text-red-500 text-xs mt-1">file needed</div>}
                </div>
                  </div>
                  <div className="flex mt-4">
                  <div className={`checkbox-container ${showErrors && !checked ? "border border-[red]" : ""}`}>
                    <div className="checkbox" onClick={() => setChecked(!checked)}>
                      {checked && (
                        <FaCheck className="checkbox-checkmark" />
                      )}
                    </div>
                  </div>
                  <p className="text-[12px] text-[#D5D5D5] font-jakarta ml-4">
                    I acknowledge that I have read, understood and agree with the Privacy statement and collection notice below and the lorem ipsum
                  </p>
                </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="w-full p-3 bg-[#DEA838] text-black rounded-lg flex items-center justify-center font-jakarta hover:bg-[#4F545A]"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <span className="flex items-center">
                          <FaCheck className="mr-2" />
                          Verify
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center text-center">
                  <p className="text-[#DEA838] text-[30px]">Not Available</p>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kyc;
