import AdminWrapper from "../../components/AdminWrapper";
import { GrPowerForceShutdown } from "react-icons/gr";
import { useState, useEffect } from "react";
import { MdOutlineClose, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";


    interface Investment{
        investments: any
      }

const FirstCard: React.FC<Investment> = ({investments}) => {
    const initialCurrency = localStorage.getItem("selectedCurrency") || "£";
    const [currency, setCurrency] = useState<string>(initialCurrency);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        localStorage.setItem("selectedCurrency", currency);
    }, [currency]);

    const handleCurrencyChange = (selectedCurrency: string) => {
        setCurrency(selectedCurrency);
        setIsDropdownOpen(false);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <>
            <div className="flex flex-col lg:w-[90%] w-full lg:flex-row gap-4">
                <div className="w-full flex flex-col gap-10 lg:w-[66%] bg-[#1E1E1E] rounded-[10px] py-3 px-4">
                    <div className="flex justify-between">
                        <div className="flex justify-between gap-2 items-center">
                            <p className="text-[#9A9FA5] font-jakarta text-[14px]">Total Portfolio Value</p>
                            <GrPowerForceShutdown className="text-[#9A9FA5]" />
                        </div>

                        <div>
                            <div className="relative inline-block text-left">
                                <div>
                                    <button
                                        type="button"
                                        className="border border-[#6F767E] text-white px-[10px] font-jakarta py-[7px] flex justify-between gap-3 items-center rounded"
                                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                    >
                                        {currency}
                                        <MdOutlineKeyboardArrowDown
                                            className={`transform ${isDropdownOpen ? "rotate-180" : ""}`}
                                        />
                                    </button>
                                </div>

                                {isDropdownOpen && (
                                    <div className="absolute right-0 mt-2 w-20 z-10 rounded-md shadow-lg bg-[black] ring-1 ring-black ring-opacity-5">
                                        <div className="py-1">
                                            <div
                                                className="block px-4 py-2 text-white font-jakarta cursor-pointer"
                                                onClick={() => handleCurrencyChange("£")}
                                            >
                                                £
                                            </div>
                                            <div
                                                className="block px-4 py-2 text-white font-jakarta cursor-pointer"
                                                onClick={() => handleCurrencyChange("$")}
                                            >
                                                $
                                            </div>
                                            <div
                                                className="block px-4 py-2 text-white font-jakarta cursor-pointer"
                                                onClick={() => handleCurrencyChange("€")}
                                            >
                                                €
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between">
                        <div className="flex justify-between items-center">
                            <p className="text-[20px] font-jakarta">{currency} {investments?.total_portfolio_value
                            }</p>
                        </div>

                        <div>
                            <div className="relative inline-block text-left">
                                <div>
                                    <button
                                        type="button"
                                        className="border border-[#33383F] text-[#9A9FA5] px-[20px] py-[10px] rounded"
                                    >
                                        <IoIosArrowForward />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-col gap-12 lg:w-[33%] bg-[#1E1E1E] rounded-[10px] p-4">
                    <div className="flex h-full items-center justify-center">
                        <div className="flex gap-2 items-center" onClick={toggleModal}>
                            <p className="text-[#9A9FA5] text-[14px] font-jakarta cursor-pointer">Apply for loan</p>
                            <GrPowerForceShutdown className="text-[#9A9FA5]" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col lg:w-[90%] w-full mt-[20px] lg:flex-row gap-4">
                <div className="w-full flex flex-col gap-12 lg:w-[33%] bg-transparent border border-[#33383F] rounded-[10px] p-3">
                    <div className="flex flex-col gap-3 py-[10px] h-full items-start justify-start">
                        <div className="flex gap-2 items-center">
                            <p className="text-[#9A9FA5] text-[14px] font-jakarta">Number of Slot</p>
                            <GrPowerForceShutdown className="text-[#9A9FA5]" />
                        </div>
                        <div>
                            <p className="text-[20px] font-jakarta">{investments?.total_slot}</p>
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-col gap-12 lg:w-[33%] bg-transparent border border-[#33383F] rounded-[10px] p-3">
                    <div className="flex flex-col gap-3 py-[10px] h-full items-start justify-start">
                       
                        <div className="flex gap-2 items-center">
                            <p className="text-[#9A9FA5] text-[14px] font-jakarta">ROI</p>
                            <GrPowerForceShutdown className="text-[#9A9FA5]" />
                        </div>
                       
                        <div>
                            <p className="text-[20px] font-jakarta">{investments?.rois}</p>
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-col gap-12 lg:w-[33%] bg-[#1E1E1E] rounded-[10px] p-3">
                    <Link to={"/portfolio/income-history"} className="flex h-full items-center justify-center">
                        <div className="flex gap-2 items-center">
                            <p className="text-[#9A9FA5] text-[14px] font-jakarta cursor-pointer">ROI pay-in</p>
                            <GrPowerForceShutdown className="text-[#9A9FA5]" />
                        </div>
                    </Link>
                </div>
               
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center  bg-black bg-opacity-70">
                    <div className="w-[30%] bg-[#1E1E1E] rounded-[10px] border border-[#6F767E] p-7">
                       

                        <div className="flex flex-col text-center font-jakarta align-middle items-center justify-center">
                            <img src="/assets/images/Frame 586.png" alt="frame" className="h-[70px] w-[70px] mb-[10px]" />

                            <p className="text-[20px] font-[600] font-jakarta w-[60%] ">Coming soon</p>
                            <p className="text-[#9A9FA5] text-[14px] font-jakarta w-[80%]">
                            The apply for loan feature is coming soon
                            </p>
                            <button className="my-[30px] font-jakarta py-[12px]  w-full font-[700] rounded-[10px] px-[20px] bg-[#DEA838] text-black"
                                onClick={toggleModal}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FirstCard;
