import React from 'react';
import { Grid, Typography, Box } from "@mui/material";
import { authImage } from "../../assets";
import { logo } from "../../assets";

const SideInfo: React.FC = () => {
  return (
    <Grid item xs={12} md={5}>
      <Box
        className="relative min-h-[95vh] sm:min-h-[100vh] h-full"
      >
        <img
          src={logo}
          alt="logo"
          className="absolute top-[15%] left-[10%] z-10 h-14 w-[230px]"
        />
        <div
          className="absolute top-[55%] left-[20%] transform -translate-x-[12%] -translate-y-1/2 z-10"
        >
          <p
            className="text-white w-[30%] font-[600] mb-6 text-[38px] leading-tight"
          >
           Are my funds and asset safe at Maihomm?
          </p>
          <p className="text-white text-[20px] leading-[25px] font-[400] w-[37%]">
          All clients funds and co-owned properties are held in Trust by Maihomm Trustees, a UK registered trust and regulated by the HMRC.
          </p>
        </div>
        <div
          className="absolute top-0 left-0 w-full h-full z-0"
        >
          <img
            src={authImage}
            alt="background"
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          <div
            className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80"
          ></div>
        </div>
      </Box>
    </Grid>
  );
};

export default SideInfo;
