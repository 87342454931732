import Wrapper from "../../components/Wrapper";
import {
  Box,
  Button,
  Paper,
  Typography,
  Divider,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarIcon from "@mui/icons-material/Star";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WifiIcon from "@mui/icons-material/Wifi";
import ComputerIcon from "@mui/icons-material/Computer";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import Ratings from "./rating";
import TopBar from "../../components/topNav";
import PhotoSliderComponents from "../../components/PhotoSliderComponents";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethod";
import { useParams, Link } from "react-router-dom";
import Selector from "./Selector";
import BasicModal from "./BasicModal";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import { toast } from "react-toastify";
import { IoIosHeartEmpty } from "react-icons/io";
import { IoIosMore } from "react-icons/io";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { AiOutlineHome } from "react-icons/ai";
import { TbFlag3 } from "react-icons/tb";
import DatePicker from "./Datepicker";
import Checkout from "./Checkout";
import ModalContent from "./ModalContent";
import PModalContent from "./PModalContent";
import { useSelector } from "react-redux";


const BookingDetails = () => {
  const params = useParams();
  const { id } = params;
  const [property, setProperty] = useState<any>();
  const [checkInDate, setCheckInDate] = useState<Dayjs | null>(null);
  const [checkOutDate, setCheckOutDate] = useState<Dayjs | null>(null);
  const [open, setOpen] = useState(false);
  const [hasHabitableCode, setHasHabitableCode] = useState(false);
  const [habitableCode, setHabitableCode] = useState("");
  const [userAvailableDays, setUserAvailableDays] = useState(0);
  const [days, setDays] = useState(0);
  const [propertyReviewsLength, setPropertyReviewsLength] = useState(0);
  const [propertyRatingsAverage, setPropertyRatingsAverage] = useState(0);

  const handleReserve = () => {
    const makeReservation = async () => {
      try {
        if (hasHabitableCode) {
          await userRequest.post("/user/bookings/book", {
            property_id: id,
            check_in: checkInDate?.format("DD/MM/YYYY"),
            check_out: checkOutDate?.format("DD/MM/YYYY"),
            number_of_guests: 2,
            slots: 1,
            habitable_code: habitableCode,
            habitable_days_usage: days,
          });
        } else {
          await userRequest.post("/user/bookings/book", {
            property_id: id,
            check_in: checkInDate?.format("DD/MM/YYYY"),
            check_out: checkOutDate?.format("DD/MM/YYYY"),
            number_of_guests: 2,
            slots: 1,
          });
        }
        // console.log(res);
        toast.success(
          "booking request made, confirm payment to secure reservation"
        );
        setOpen(true);
      } catch (err) {
        toast.error("error occured while making reservation", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          hideProgressBar: true,
        });
      }
    };

    makeReservation();
  };
  const userData = useSelector((state: any) => state.login.userData);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    window.scrollTo(0, 0);
    
    const getPropertyData = async () => {
      const token = "your-auth-token"; // Replace with your actual token
      try {
        const res = await fetch(`${baseUrl}/user/properties/show/${id}`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${userData?.data?.token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await res.json();
        console.log(data, "property detail")
        setProperty(data.data);
      } catch (error) {
        console.error("Error fetching property data:", error);
      }
    };
    getPropertyData();
  }, [id]);

  const validateHabitableCode = async () => {
    setShowModal(true);
    try {
      const res = await userRequest.post("user/bookings/check-code", {
        habitable_code: habitableCode,
      });
      // console.log(res);
      setUserAvailableDays(res.data.data.available_days);
      setHasHabitableCode(true);
    } catch (err: any) {
      // console.log(err);
      setHasHabitableCode(false);
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        hideProgressBar: true,
      });
    }
  };
  // const [specialInstructions, setSpecialInstructions] = useState('');
  // const [selectedDate, setSelectedDate] = useState(new Date());
  // const [selectedDates, setSelectedDates] = useState(new Date());

  const [specialInstructions, setSpecialInstructions] = useState('');
  // const [habitableCode, setHabitableCode] = useState('');
  // const [hasHabitableCode, setHasHabitableCode] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDates, setSelectedDates] = useState(new Date());

  const [showModal, setShowModal] = useState(false);
  const [showModals, setShowModals] = useState(false);

  const handleSpecialInstructionsClick = () => {
    setShowModals(true);
    // Implement your logic for handling special instructions here
    console.log("Special Instructions:", specialInstructions);
    // You might want to save the instructions or perform other actions
  };
 

  const handleProceedToPayment = () => {
    // Implement your logic for proceeding to payment here
    console.log("Proceeding to payment with selected date:", selectedDate);
    // For example, you might want to navigate to a payment page or display a modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  const handleCloseModals = () => {
    setShowModals(false); // Close the modal
  };
  return (
    <Wrapper>
      <Box>
        <Link to="/bookings">
          <button
            className="flex items-center font-jakarta text-primary-500 border border-[#33383F] rounded px-3 py-1 text-sm font-medium hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-500"

          >
            <MdKeyboardArrowLeft />

            Back
          </button>
        </Link>

        <div className="flex justify-between items-center mt-5">
          <div className="flex-grow">
            <p className="text-[50px] font-jakarta font-bold">
              {property?.name}
            </p>
            <div className="flex text-gray-400">
              <div className="flex items-center mr-5">
                <StarIcon className="text-yellow-500 mr-2 w-5" />
                <p className="font-jakarta">
                  {propertyRatingsAverage} ({propertyReviewsLength} reviews)
                </p>
              </div>
              <div className="flex items-center mr-5">
                <HomeOutlinedIcon className="w-5 mr-2" />
                <p className="font-jakarta">Superhost</p>
              </div>
              <div className="flex items-center mr-5">
                <LocationOnOutlinedIcon className="w-5 mr-2" />
                <p className="font-jakarta">
                  {property?.location?.address}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <IoIosHeartEmpty className="text-[30px] bg-[#1E1E1E] text-[#9A9FA5] rounded-full" />

            <IoIosMore className="text-[30px] bg-[#1E1E1E] text-[#9A9FA5] rounded-full" />

          </div>
        </div>


        <Box sx={{ my: 3 }}>
          {property?.files && (
            <PhotoSliderComponents images={property?.files} />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 5,
            mt: 12,
          }}
        >
          <div className="flex-grow">
            <div>
              <div className="flex  text-[#9A9FA5]">
                <div className="flex items-center text-[20px] mr-5">
                  {/* <img src="bed_icon.png" alt="Bed Icon" className="w-5 mr-1" /> */}
                  <AiOutlineHome />

                  <div className="font-jakarta">{property?.features?.bedrooms} guests</div>
                </div>
                <div className="flex items-center text-[20px] mr-5">
                  {/* <img src="bathtub_icon.png" alt="Bathtub Icon" className="w-5 mr-1" /> */}
                  <TbFlag3 />

                  <div className="font-jakarta">{property?.features?.bathrooms} baths</div>
                </div>
                <div className="flex items-center text-[20px] mr-5">
                  {/* <img src="space_dashboard_icon.png" alt="Space Icon" className="w-5 mr-1" /> */}
                  <TbFlag3 />
                  <div className="font-jakarta">{property?.features?.sqft} Sq.Ft</div>
                </div>
              </div>
              <div className="mt-4 font-jakarta text-[#9A9FA5]">
              {property?.description}
                              </div>
              <hr className="border-t border-[#33383F] my-5" />
              <div className="my-4">
                <div className="text-lg font-bold font-jakarta">Amenities</div>
              </div>

              <div className="flex items-center">
                <div className="flex flex-col gap-5">
                  <div className="flex items-center mr-5 h-full">
                    <img src="/assets/images/Line.svg" alt="Wifi Icon" className="w-5 mr-2.5" />
                    <p className="text-[#9A9FA5]">Free wifi 24/7</p>
                  </div>
                  <div className="flex items-center mr-5 h-full">
                    <img src="/assets/images/Shape.svg" alt="Bathtub Icon" className="w-5 mr-2.5" />
                    <p className="text-[#9A9FA5] font-jakarta">Free Computer</p>
                  </div>
                  <div className="flex items-center mr-5 h-full">
                    <img src="/assets/images/Lines.svg" alt="Computer Icon" className="w-5 mr-2.5" />
                    <p className="text-[#9A9FA5] font-jakarta">Free wifi 24/7</p>
                  </div>
                  <div className="flex items-center mr-5 h-full">
                    <img src="/assets/images/Liness.svg" alt="Breakfast Icon" className="w-5 mr-2.5" />
                    <p className="text-[#9A9FA5] font-jakarta">Free wifi 24/7</p>
                  </div>
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex items-center mr-5 h-full">
                    <img src="/assets/images/Line1.svg" alt="Wifi Icon" className="w-5 mr-2.5" />
                    <p className="text-[#9A9FA5] font-jakarta">Free clean Bathroom</p>
                  </div>
                  <div className="flex items-center mr-5 h-full">
                    <img src="/assets/images/Line2.svg" alt="Bathtub Icon" className="w-5 mr-2.5" />
                    <p className="text-[#9A9FA5] font-jakarta">Breakfast Included</p>
                  </div>
                  <div className="flex items-center mr-5 h-full">
                    <img src="/assets/images/Line3.svg" alt="Computer Icon" className="w-5 mr-2.5" />
                    <p className="text-[#9A9FA5] font-jakarta">ATM</p>
                  </div>
                  <div className="flex items-center mr-5 h-full">
                    <img src="/assets/images/Line4.svg" alt="Breakfast Icon" className="w-5 mr-2.5" />
                    <p className="text-[#9A9FA5] font-jakarta">Nearby city</p>
                  </div>
                </div>
              </div>

              <hr className="border-t border-[#33383F] my-5" />
            </div>
          </div>


          <div className="ml-4 bg-[#1E1E1E]">
      <div className="rounded-lg min-w-[400px] pt-5 pb-3 px-3">
        <h5 className="text-2xl font-jakarta">£145</h5>
        <div className="text-gray-400 font-jakarta">price per day</div>
        <div className="flex justify-between items-center gap-2 my-4">
          <div className="text-xs">
            <DatePicker selectedDate={selectedDate} onDateChange={setSelectedDate} />
            <p className="mt-4 text-center font-jakarta hidden">
              Selected Date: {selectedDate.toDateString()}
            </p>
          </div>
          <div className="text-xs">
            <Checkout selectedDate={selectedDates} onDateChange={setSelectedDates} />
            <p className="mt-4 text-center hidden">
              Selected Date: {selectedDates.toDateString()}
            </p>
          </div>
        </div>
        <div className="border border-gray-600 p-4 rounded-lg">
          <div className="my-2">
            <label htmlFor="habitable" className="block text-[14px] font-jakarta font-medium mt-3">Habitable days</label>
            <p className="text-[#9A9FA5] text-[12px] font-jakarta my-3">Enter Code and get two Habitable days for free</p>
            <div className="relative flex">
              <input
                type="text"
                id="habitable"
                placeholder="Input habitable days code"
                className="border border-[#33383F] rounded-full font-jakarta bg-transparent px-3 py-3 text-sm w-full"
                onChange={(e) => setHabitableCode(e.target.value)}
              />
              <button
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-[#DEA838] w-[15%] rounded-full p-2 flex items-center justify-center cursor-pointer"
                onClick={validateHabitableCode}
              >
                <span className="text-white text-lg">→</span>
              </button>
            </div>
            {hasHabitableCode && (
              <div className="ml-2 mt-1">
                <div className="text-sm text-gray-400 font-jakarta">
                  Habitable Days left: <span id="userAvailableDays"></span>
                </div>
                <div className="flex items-center mt-2 mb-1">
                  <span className="text-sm font-medium font-jakarta">Select the habitable days:</span>
                  <div id="selector" className="ml-2"></div> {/* Assuming you have a custom selector component */}
                </div>
                <div className="text-sm text-gray-400 font-jakarta">
                  Enter the number of habitable days you want to use
                </div>
              </div>
            )}
          </div>
          <div className="my-2">
            <label htmlFor="special-instructions" className="block text-[14px] font-medium my-3 font-jakarta">Promo</label>
            <div className="relative flex">
              <input
                type="text"
                id="special-instructions"
                placeholder="Enter any special instructions"
                className="border border-[#33383F] rounded-full font-jakarta bg-transparent px-3 py-3 text-sm w-full"
                onChange={(e) => setSpecialInstructions(e.target.value)}
              />
              <button
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-[#DEA838] w-[15%] rounded-full p-2 flex items-center justify-center cursor-pointer"
                onClick={handleSpecialInstructionsClick}
              >
                <span className="text-white text-lg">→</span>
              </button>
            </div>
          </div>
          <div className="my-4">
            <button
              className="bg-[#DEA838] text-black rounded-[10px] py-4 px-4 w-full"
              onClick={handleProceedToPayment}
            >
              Reserve
            </button>
          </div>
        </div>
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <ModalContent 
                  close={handleCloseModal}
                  />
          </div>
        )}
         {showModals && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <PModalContent
                  close={handleCloseModal}
                  />
          </div>
        )}
      </div>
    </div>





        </Box>
        <Ratings
          propertyId={id}
          setPropertyReviewsLength={setPropertyReviewsLength}
          setPropertyRatingsAverage={setPropertyRatingsAverage}
        />
      </Box>
    </Wrapper>
  );
};

export default BookingDetails;
