import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { userRequest } from "../requestMethod";
import { formatPrice } from "../utils/helpers";
import { saveProduct } from "../services/cartReducer";
import { Link } from "react-router-dom";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { FaRegHandshake, FaRegHeart } from "react-icons/fa";

type PropertyProps = {
  properties: any[];
};

const Property: React.FC<PropertyProps> = (props) => {
  const dispatch = useDispatch();
  const [userCartLength, setUserCartLength] = React.useState(0);
  const [visibleItems, setVisibleItems] = React.useState(6);
  const [properties, setProperties] = React.useState(props.properties);
  const [loading, setLoading] = React.useState(false);
  const userData = useSelector((state: any) => state.login.userData);

  // State to track IDs of items in the cart and favorites
  const [cartItemIds, setCartItemIds] = React.useState<string[]>([]);
  const [favoriteItemIds, setFavoriteItemIds] = React.useState<string[]>([]); // New state for favorites
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const addToCart = async (id: string) => {
    // Prevent adding to cart if the item is already in the cart
    if (cartItemIds.includes(id)) {
      toast.warn("Item already in cart", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        hideProgressBar: true,
      });
      return;
    }

    setUserCartLength(userCartLength + 1);
    dispatch(saveProduct);
    toast.success("Item added to cart", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
      hideProgressBar: true,
    });

    try {
      const response = await fetch(`${baseUrl}/user/carts/items/add/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${userData?.data?.token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to add item to cart');
      }
      await response.json();
      
      // Update cartItemIds to include the newly added item
      setCartItemIds((prevIds) => [...prevIds, id]);
    } catch (err) {
      console.log(err);
    }
  };

  // New function to handle heart icon click
  const handleFavoriteToggle = async (id: string) => {
    try {
      const response = await fetch(`${baseUrl}/user/properties/add-to-favorites/${id}`, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${userData?.data?.token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to toggle favorite');
      }
      const data = await response.json();
      // Update favoriteItemIds to reflect the new state
      setFavoriteItemIds((prevIds) =>
        prevIds.includes(id) ? prevIds.filter(favId => favId !== id) : [...prevIds, id]
      );
      toast.success(data.message || "Favorite toggled", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        hideProgressBar: true,
      });
    } catch (err: any) {
      toast.error(err.message || 'Failed to toggle favorite');
    }
  };

  React.useEffect(() => {
    const getUserCartLength = async () => {
      try {
        const response = await fetch(`${baseUrl}/user/carts/items`, {
          headers: {
            "Authorization": `Bearer ${userData?.data?.token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch cart length');
        }
        const data = await response.json();
        console.log(data, "get cart");
        setUserCartLength(data?.data?.length);
        // Store the IDs of items in the cart
        setCartItemIds(data?.data?.map((item: any) => item.property.id) || []);
      } catch (err: any) {
        toast.error(err.message || 'Failed to fetch cart length');
      }
    };

    getUserCartLength();
  }, [userData]);

  const handleLoadMore = () => {
    setLoading(true);
    setTimeout(() => {
      setVisibleItems(visibleItems + 6);
      setLoading(false);
    }, 2000);
  };

  return properties.length >= 1 ? (
    <div className="mt-3">
      <div className="grid font-jakarta grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {properties.slice(0, visibleItems).map((datas) => (
          <div
            key={datas.id}
            className="rounded-[15px] overflow-hidden flex flex-col bg-[#1E1E1E]"
          >
            <div className="relative">
              <img src={datas?.default_image?.url} className="w-full h-auto object-cover" />
              <div className={`absolute top-2 right-2 w-4 h-4 ${datas?.status === "Available" ? "bg-green-600" : datas?.status === "Sold" ? "bg-red-600" : datas?.status === "Coming Soon" ? "bg-yellow-600" : ""} rounded-full`}></div>
            </div>

            <div className="p-4 flex flex-col flex-1">
              <h2 className="text-lg font-semibold mb-2">
                <Link
                  to={`/properties/${datas?.id}`}
                  className="text-white no-underline font-jakarta text-[14px] hover:underline"
                >
                  {datas?.name}
                </Link>
              </h2>
              <p className="text-gray-400 font-jakarta text-[12px]">{datas?.location?.address}</p>
             
              <div className='flex justify-between'>
                <span className="text-[14px] font-jakarta">{formatPrice(datas?.costings?.per_Slot)} <span className="ml-[6px]">per slot</span></span>
                <div className='flex gap-2 items-center'>
                  <FaRegHeart 
                    onClick={() => handleFavoriteToggle(datas.id)} // Add click handler
                    className={`text-gray-400 ${favoriteItemIds.includes(datas.id) ? 'text-red-500' : ''}`} // Change color to red if favorited
                  />
                  <FaRegHandshake
                    onClick={() => addToCart(datas.id)}
                    className={`text-gray-400 ${cartItemIds.includes(datas.id) ? 'text-yellow-500' : ''}`} // Change color to yellow if in cart
                  />
                </div>
              </div>
            </div>
            <div className="p-4 border-t">
              <div className="flex flex-col sm:flex-row sm:gap-4">
                <div className="flex flex-row gap-2 items-center w-full sm:w-auto">
                  <BedOutlinedIcon style={{ color: "#DEA73A", width: 16 }} />
                  <div className="flex flex-row items-center text-xs">
                    <p className="mr-1 font-jakarta">{datas?.features?.bedrooms}</p>
                    <p>Bedrooms</p>
                  </div>
                </div>
                <div className="flex flex-row gap-2 items-center w-full sm:w-auto">
                  <BathtubOutlinedIcon style={{ color: "#DEA73A", width: 16 }} />
                  <div className="flex font-jakarta flex-row items-center text-xs">
                    <p className="mr-1 font-jakarta">{datas?.features?.bathrooms}</p>
                    <p>Bathrooms</p>
                  </div>
                </div>
                <div className="flex flex-row gap-2 items-center w-full sm:w-auto">
                  <SpaceDashboardOutlinedIcon style={{ color: "#DEA73A", width: 16 }} />
                  <div className="flex flex-row items-center text-xs">
                    <p className="mr-1 font-jakarta">{datas?.features?.sqft}</p>
                    <p>Sq.Ft</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {visibleItems < properties.length && (
        <div className="flex justify-center mt-4">
          <button
            onClick={handleLoadMore}
            className={`px-4 py-2 bg-[#DEA73A] text-black rounded font-jakarta ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'See More'}
          </button>
        </div>
      )}
    </div>
  ) : null;
};

export default Property;
