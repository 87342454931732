import React, { useEffect, useState } from 'react';
import { FaRegHandshake, FaRegHeart } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const FutureProperty: React.FC = () => {
  const params = useParams();
  const { id } = params;
  const [properties, setProperties] = useState<Record<string, any>[]>([]); // Changed Property[] to Record<string, any>[]
  const userData = useSelector((state: any) => state.login.userData);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await fetch(`${baseUrl}/user/properties/related-properties/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData?.data?.token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log(data?.data?.data, "future");
        setProperties(data?.data?.data); // Assuming the API returns the property data
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };

    fetchProperties();
  }, [userData?.data?.token, id]); // Added 'id' as a dependency

  return (
    <div className='lg:w-[90%] w-full pb-[30px]'>
      <div className="w-full flex justify-start my-5">
        <p className='text-[14px] font-jakarta'>See Similar Home you may like</p>
      </div>
      <div className="flex lg:flex-row flex-col lg:items-center lg:gap-0 gap-4 lg:space-x-4">
        {properties.slice(0, 3).map(property => ( // Map only the first 3 properties
          <div key={property.id} className="flex flex-col lg:w-1/2 w-full bg-[#1E1E1E] rounded-[10px]">
            <img src={property.default_image?.url} alt="Placeholder" className="w-full h-full object-cover mb-2" />
            <div className='p-4'>
              <p className='text-[14px] leading-[30px] font-jakarta'>{property.name}</p>
              <p className='text-[12px] leading-[30px] font-jakarta text-[#afadad]'>{property?.location?.address}</p>
            </div>
            <div className='p-4'>
              <div className='flex justify-between'>
                <p className='text-[14px] font-jakarta'>{property.costings.price}</p>
                <div className='flex gap-2 items-center'>
                  <FaRegHeart className='text-gray-400'/>
                  <FaRegHandshake className='text-gray-400'/>
                </div>
              </div>
              <hr className="border-t-2 border-[#B6B7B9] mt-2" />
              <div className='flex justify-between my-4'>
                <div className='flex gap-2 items-center'>
                  <img src="/assets/images/Bed.svg" className="h-[20px] w-[20px]" />
                  <p className='text-[#FCFCFC] text-[8px] font-jakarta'>{property.features.bedrooms} Bedrooms</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <img src="/assets/images/bathtub.svg" className="h-[20px] w-[20px]" />
                  <p className='text-[#FCFCFC] text-[8px] font-jakarta'>{property.features.bathrooms} Bathrooms</p>
                </div>
                <div className='flex gap-2 items-center'>
                  <img src="/assets/images/dashboard.svg" className="h-[20px] w-[20px]" />
                  <p className='text-[#FCFCFC] text-[8px] font-jakarta'>{property.features.sqft}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FutureProperty;
