import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { CircularProgress } from "@mui/material";
import { logo, authImage } from "../../assets";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import styled from "@emotion/styled";
import { publicRequest } from "../../requestMethod";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledInput = styled.input`
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

interface FormData {
  email: string;
  otp: string;
  password: string;
  passwordConfirmed: string;
}

interface ValidationError {
  path?: string;
  message: string;
}

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [display, setDisplay] = useState(true);
  const [errs, setErrs] = useState("");
  const [reset, setReset] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmedVisible, setPasswordConfirmedVisible] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    email: "",
    otp: "",
    password: "",
    passwordConfirmed: "",
  });
  const [errors, setErrors] = useState<Partial<FormData>>({
    email: "",
    otp: "",
    password: "",
    passwordConfirmed: "",
  });
  const [errorTimeout, setErrorTimeout] = useState<number | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    number: false,
    symbol: false,
  });
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState(false);

  const initialTime = 5 * 60; // 5 minutes in seconds
  const [countdown, setCountdown] = useState(initialTime);
  const [isActive, setIsActive] = useState(false);
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isActive) {
      timerRef.current = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(timerRef.current!);
            setIsActive(false);
            return initialTime;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    } else {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isActive]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    const user = Cookies.get("userData");
    if (user) {
      navigate("/overview");
      window.location.reload();
    }
  }, [navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear error message when user starts typing
    setErrors({
      ...errors,
      [name]: "",
    });
    if (name === "password") {
      validatePassword(value);
    }
  };

  const validatePassword = (password: string) => {
    setPasswordCriteria({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      symbol: /[@#$]/.test(password),
    });
  };

  const sendOtp = () => {
    setLoading(true);
  
    setTimeout(() => {
      if (formData.otp === "") {
        setReset(false);
        setErrs("Input the OTP");
  
        // Clear the error message after 2 seconds
        setTimeout(() => {
          setErrs("");
        }, 2000);
      } else {
        setReset(true);
      }
  
      setLoading(false); // Set loading to false after 2 seconds
    }, 2000);
  };
  

  const resendOtp = async () => {
    setIsActive(false);
    setCountdown(initialTime);
    try {
      setLoadings(true);
      await publicRequest.post("/forgot-password", {
        email: formData.email,
      });
      toast.success(`OTP resent to ${formData.email}`, {
        autoClose: 5000,
        hideProgressBar: true,
      });
      setIsActive(true);
      setOtpSent(true);
    } catch (error: any) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
        hideProgressBar: true,
      });
    } finally {
      setLoadings(false);
    }
  };

  const handleReset = async () => {
    if (!otpSent) {
      try {
        setLoading(true);
        await publicRequest.post("/forgot-password", {
          email: formData.email,
        });
        toast.success(`OTP sent to ${formData.email}`, {
          autoClose: 5000,
          hideProgressBar: true,
        });
        setIsActive(true);
        setOtpSent(true);
      } catch (error: any) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } finally {
        setLoading(false);
      }
    } else if (otpSent && !reset) {
      try {
        setLoading(true);
        await publicRequest.post("/verify-otp", {
          code: formData.otp,
          email: formData.email,
        });
        toast.success("OTP verified successfully", {
          autoClose: 2000,
          hideProgressBar: true,
        });
        setReset(true);
      } catch (error: any) {
        toast.error(error.response.data.error_debug, {
          autoClose: 1000,
          hideProgressBar: true,
        });
      } finally {
        setLoading(false);
      }
    } else if (reset) {
      try {
        setLoading(true);
        await publicRequest.post("/password-reset", {
          code: formData.otp,
          email: formData.email,
          new_password: formData.password,
          confirm_password: formData.passwordConfirmed,
        });
        setDisplay(false)
        setEmailSent(true);
        toast.success("Password Reset successful", {
          autoClose: 2000,
          hideProgressBar: true,
        });
        // setTimeout(() => {
        //   navigate("/auth/login");
        // }, 1000);
      } catch (error: any) {
        toast.error(error.response.data.error_debug, {
          autoClose: 1000,
          hideProgressBar: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const validateForm = () => {
    const schema = Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Email is required"),
      otp: otpSent ? Yup.string().required("OTP is required") : Yup.string().notRequired(),
      password: reset
        ? Yup.string().min(8, "Password must have at least 8 characters").required("Password is required")
        : Yup.string().notRequired(),
      passwordConfirmed: reset
        ? Yup.string().oneOf([Yup.ref("password")], "Passwords must match").required("Confirm password is required")
        : Yup.string().notRequired(),
    });

    schema
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({
          email: "",
          otp: "",
          password: "",
          passwordConfirmed: "",
        });
        handleReset();
      })
      .catch((validationErrors: Yup.ValidationError) => {
        const newErrors: Partial<FormData> = {
          email: "",
          otp: "",
          password: "",
          passwordConfirmed: "",
        };
        validationErrors.inner.forEach((error: ValidationError) => {
          if (error.path) {
            newErrors[error.path as keyof FormData] = error.message;
          }
        });
        setErrors(newErrors);

        // Clear previous timeout
        if (errorTimeout) {
          clearTimeout(errorTimeout);
        }

        // Set new timeout to clear errors after 2 seconds
        const timeout = setTimeout(() => {
          setErrors({
            email: "",
            otp: "",
            password: "",
            passwordConfirmed: "",
          });
        }, 2000);
        setErrorTimeout(timeout as unknown as number);
      });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const togglePasswordConfirmedVisibility = () => {
    setPasswordConfirmedVisible(!passwordConfirmedVisible);
  };

  const textFieldStyle = `
    text-sm mb-2 py-[15px] px-3 font-jakarta rounded-lg border outline-none bg-[#1E1E1E] w-full
  `;

  const focusedStyle = `
    text-white font-jakarta
  `;

  const errorStyle = `
    border-red-500 font-jakarta
  `;

  const buttonStyle = `
    bg-[#DEA838] font-jakarta hover:bg-[#DEA838] text-black font-[700] py-2 px-4 rounded-lg w-full flex justify-center
  `;

  const passwordCriteriaStyle = (isValid: boolean) => `
    text-[14px] font-jakarta flex gap-[5px] items-center ${isValid ? "text-green-500" : "text-white"}
  `;

  const passwordCriteriaStyleCheck = (isValid: boolean) => `
  py-[8px] font-jakarta rounded-[3px] px-[8px] bg-[#33383F] ${isValid ? "border border-green-500" : "border border-[#6F767E]"}
`;


  return (

    <div className="relative flex items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: `url(${authImage})` }}>
      <div className="absolute inset-0 bg-black opacity-90"></div>
      <div className="relative flex flex-col md:flex-row w-full max-w-6xl p-4 bg-transparent rounded-lg shadow-lg">
      <div className="flex flex-col justify-center w-full md:w-1/2 text-white p-4">
          <div className="flex justify-center md:absolute md:top-5 md:justify-start mb-10">
            <img src={logo} alt="logo" className="h-14 w-56 mb-4 md:mb-0" />
          </div>
          <h1 className="text-[24px] md:text-[42px] font-[600] w-full md:w-[90%] font-jakarta leading-[30px] md:leading-[45px] mb-4">Are my funds and asset safe at Maihomm?</h1>
          <p className="text-[14px] md:text-[16px] font-[400] w-full md:w-[83%] font-jakarta tracking-wider mb-8">All clients funds and co-owned properties are held in Trust by Maihomm Trustees, a UK registered trust and regulated by the HMRC.</p>
        </div>
        <div className="flex items-center justify-center w-full md:w-2/3 p-4">
        
          <div className={`w-full ${emailSent ? "pb-[50px] px-[50px] " : "pb-[150px] pl-4 md:pl-[65px] py-4 md:py-[50px]"} w-full  rounded-[30px] border border-[#6F767E] shadow-lg`} style={{ background: "linear-gradient(135deg, #1B1818 0%, #1B1818 100%)" }}>
            {
              display ? (
                <div className="w-[75%]">
                  {!otpSent && !reset && (
                    <>
                      <p className="text-[20px] md:text-[36px] font-jakarta font-[600]">Forgot Password</p>
                      <p className="text-[#9A9FA5] font-[400] font-jakarta mb-4 text-[12px] md:text-[14px]">Enter your Email address, We will send a link to your email to recover your password</p>
                      <div className="form-control mb-4">
                        <p className="block text-[12px] md:text-[14px] font-jakarta font-[500] text-white mb-3">Email</p>
                        <StyledInput
                          type="email"
                          name="email"
                          placeholder="Enter your email address"
                          value={formData.email}
                          onChange={handleInputChange}
                          onFocus={() => setIsFocused(true)}
                          onBlur={() => setIsFocused(false)}
                          className={`${textFieldStyle} ${errors.email ? errorStyle : "border-[#33383F]"} ${isFocused && focusedStyle}`}
                        />
                        {errors.email && <p className="text-red-500 font-jakarta text-sm">{errors.email}</p>}
                      </div>
                      <button
                        disabled={loading}
                        onClick={validateForm}
                        className={buttonStyle + `${loading ? "cursor-not-allowed md:mb-[120px] mb-0" : "md:mb-[120px] font-jakarta mb-0"}`}
                      >
                        {loading ? <CircularProgress size={24} color="inherit" /> : "Forgot password"}
                      </button>
                    </>
                  )}
                  {otpSent && !reset && (
                    <>
                      <p className="text-[20px] md:text-[36px] font-jakarta font-[600]">Reset Password</p>
                      <p className="text-[#9A9FA5] font-[400] font-jakarta mb-4 text-[12px] md:text-[14px]">We just sent you a verification code. Check your inbox to get them.</p>
                      <div className="form-control mb-4">
                        <StyledInput
                          type="text"
                          name="otp"
                          placeholder="Enter OTP"
                          value={formData.otp}
                          onChange={handleInputChange}
                          onFocus={() => setIsFocused(true)}
                          onBlur={() => setIsFocused(false)}
                          className={`${textFieldStyle} ${errs ? errorStyle : "border-[#33383F]"} ${isFocused && focusedStyle}`}
                        />
                        {errs && <p className="text-red-500 font-jakarta text-sm">{errs}</p>}
                      </div>
                      <button
                        onClick={sendOtp}
                        className={buttonStyle}
                      >
                        {loading ? <CircularProgress size={24} color="inherit" /> : "Reset password"}
                      </button>
                      <div className="flex items-center justify-between mt-10 md:mb-[120px] mb-0">
                        <p className="text-[#9A9FA5] text-[14px]">OTP expires in <span className="text-white">{formatTime(countdown)}</span> mins</p>
                        <button
                          disabled={loadings}
                          onClick={resendOtp}
                          className={`${loadings ? "cursor-not-allowed " : " border border-[#DEA838] rounded-[5px] text-[#DEA838] p-[8px]"}`}
                        >
                          {loadings ? <CircularProgress size={24} color="inherit" /> : "Resend OTP"}
                        </button>
                      </div>

                    </>
                  )}
                  {otpSent && reset && (
                    <>
                      <p className="text-[20px] md:text-[36px] font-jakarta font-[600]">Set New Password</p>
                      <p className="text-[#9A9FA5] font-[400] font-jakarta mb-4 text-[12px] md:text-[14px]">input your new password</p>
                      <div className="form-control mb-4">
                        <p className="block text-[12px] md:text-[14px] font-jakarta font-[500] text-white mb-3">Password</p>
                        <div className={`flex justify-between font-jakarta ${textFieldStyle} ${errors.password ? errorStyle : "border-[#33383F]"} ${isFocused && focusedStyle}`}>
                          <StyledInput
                            type={passwordVisible ? "text" : "password"}
                            name="password"
                            placeholder="Enter your new password"
                            value={formData.password}
                            onChange={handleInputChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            className="outline-none font-jakarta bg-transparent w-full"
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            className=" flex items-center cursor-pointer"
                          >
                            {passwordVisible ? (
                              <AiFillEyeInvisible className="text-gray-400 h-[20px] w-[20px]" />
                            ) : (
                              <AiFillEye className="text-gray-400 h-[20px] w-[20px]" />
                            )}
                          </span>

                        </div>
                        {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
                      </div>
                      <div className="mt-4 flex flex-col gap-1">
                        <p className={passwordCriteriaStyle(passwordCriteria.length)}> <span className={passwordCriteriaStyleCheck(passwordCriteria.length)}></span> At least 8 characters</p>
                        <p className={passwordCriteriaStyle(passwordCriteria.uppercase)}><span className={passwordCriteriaStyleCheck(passwordCriteria.length)}></span>At least one uppercase letter</p>
                        <p className={passwordCriteriaStyle(passwordCriteria.number)}><span className={passwordCriteriaStyleCheck(passwordCriteria.length)}></span>At least one number</p>
                        <p className={passwordCriteriaStyle(passwordCriteria.symbol)}><span className={passwordCriteriaStyleCheck(passwordCriteria.length)}></span>At least one symbol (@, #, $)</p>
                      </div>
                      <div className="form-control my-4">
                        <p className="block text-[12px] md:text-[14px] font-jakarta font-[500] text-white mb-3">Confirm Password</p>
                        <div className={` flex font-jakarta justify-between${textFieldStyle} ${errors.passwordConfirmed ? errorStyle : "border-[#33383F]"} ${isFocused && focusedStyle}`}>
                          <StyledInput
                            type={passwordConfirmedVisible ? "text" : "password"}
                            name="passwordConfirmed"
                            placeholder="Confirm your new password"
                            value={formData.passwordConfirmed}
                            onChange={handleInputChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            className="outline-none font-jakarta bg-transparent w-full"
                          />
                          <span
                            onClick={togglePasswordConfirmedVisibility}
                            className=" flex font-jakarta items-center cursor-pointer"
                          >
                            {passwordConfirmedVisible ? (
                              <AiFillEyeInvisible className="text-gray-400 h-[20px] w-[20px]" />
                            ) : (
                              <AiFillEye className="text-gray-400 h-[20px] w-[20px]" />
                            )}
                          </span>

                        </div>
                        {errors.passwordConfirmed && <p className="text-red-500 text-sm">{errors.passwordConfirmed}</p>}
                      </div>
                      <button
                        disabled={loading}
                        onClick={validateForm}
                        className={buttonStyle + `${loading ? "cursor-not-allowed" : "font-jakarta"}`}
                      >
                        {loading ? <CircularProgress size={24} color="inherit" /> : "Done"}
                      </button>
                    </>
                  )}


                </div>

              ) : (
                <div className="w-full p-[40px]">
                  {emailSent && (
                    <div className="flex flex-col text-center font-jakarta align-middle items-center justify-center">
                      <img src="/assets/images/success.svg" className="h-[70px] w-[70px] mb-[10px]"/>

                      <p className="text-[30px] font-[600] font-jakarta w-[60%]">Verification in Progress</p>
                      <p className="text-[#9A9FA5] font-jakarta w-[80%]">
                      Your KYC verification is in progress, the verification will take 3 working days
                      </p>
                      <button className="my-[30px] font-jakarta py-[12px] w-full font-[700] rounded-[10px] px-[20px] bg-[#DEA838] text-black"
                      onClick={() => navigate("/auth/login")}
                      >
                       Login
                      </button>
                    </div>
                  )}

                </div>
              )
            }
          </div>
    
        </div>
      </div>
    </div>
   
  );
};

export default ForgotPassword;
