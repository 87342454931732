import React, { useState, useEffect } from "react";
import { InputLabel } from "@mui/material";
import Wrapper from "../../components/Wrapper";
import HelpIcon from "@mui/icons-material/Help";
import ProfileNav from "./ProfileNav";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Kycs from "./Knowyc";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const Profile = () => {
  const [file, setFile] = useState<File | null>(null);
  const [number, setNumber] = useState<number>(1); // Default number
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [userDatas, setUserData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false); // Track uploading status
  const userData = useSelector((state: any) => state.login.userData);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${baseUrl}/user/profile`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData?.data?.token}`, // Attach the token
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const data = await response.json();
        setUserData(data); // Set the user data
        setLoading(false);
      } catch (err) {
        setError("Unable to load profile");
        setLoading(false);
      }
    };

    fetchProfile();
  }, [userData?.data?.token]);

  const defaultImage = userData?.data.profile_pic; // Set your default image URL here
  const [preview, setPreview] = useState<string>(defaultImage);

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);

      // Create a preview URL using FileReader
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);

      // Trigger the image upload
      handleUpload(selectedFile);
    }
  };

  const handleRemove = () => {
    setFile(null);
    setPreview(defaultImage);
  };

  const handleUpload = async (image: File) => {
    setUploading(true);

    const formData = new FormData();
    formData.append("avatar", image);

    try {
      const response = await fetch(`${baseUrl}/user/profile/update`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userData?.data?.token}`,
          // No need to set 'Content-Type' for FormData; browser will set it automatically.
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to update profile picture");
      }

      const result = await response.json();
      toast.success("Profile picture upload successfully");
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Profile picture upload failed");
      setError("Failed to upload image");
    } finally {
      setUploading(false);
    }
  };

  const handleButtonClick = () => {
    if (number === 1) {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getButtonStyle = () => {
    if (userData?.data?.status === "Active") {
      return "bg-[#83BF6E] text-white"; // Green for "Verified"
    } else if (userData?.data?.status === "Inactive") {
      return "bg-[#FC6C6C] text-white"; // Red for "Failed"
    } else if (userData?.data?.status === "Pending") {
      return "bg-[#FDA577] text-white"; // Orange for "Pending"
    }
    return "";
  };

  const getButtonText = () => {
    if (userData?.data?.status === "Active") {
      return "Verified";
    } else if (userData?.data?.status === "Inactive") {
      return "Failed";
    } else if (userData?.data?.status === "Pending") {
      return "Pending";
    }
    return "";
  };

  return (
    <Wrapper>
    <div className="w-full h-full lg:px-4 px-2">
      <div className="flex flex-col md:flex-row">
        <ProfileNav />
        <div className="flex flex-col lg:right-[6%] lg:absolute w-full lg:pt-0 pt-10 lg:md:w-[60%]">
          <form className="flex flex-col" onSubmit={(e) => e.preventDefault()}>
            <div className="flex">
              <label className="flex flex-col gap-3 py-4 w-full rounded-lg">
                <div className="flex flex-col">
                  <div className="flex flex-row gap-5 items-center">
                    <div className="mt-4">
                      <img
                        src={preview}
                        alt="Profile"
                        className="max-w-[80px] h-[80px] rounded-full"
                      />
                    </div>

                    <div className="flex items-center gap-2">
                      <p className="rounded-[5px] font-jakarta text-[14px] bg-[#33383F] border border-[#33383F] py-[14px] px-[25px] flex items-center gap-1 cursor-pointer">
                        + Upload new picture
                      </p>
                      <input
                        id="id_card"
                        name="id_card"
                        type="file"
                        accept="application/pdf, image/png, image/jpeg"
                        className="hidden"
                        onChange={handleChanges}
                      />
                      <button
                        type="button"
                        onClick={handleRemove}
                        className="rounded-[5px] text-[14px] font-jakarta bg-[#FCFCFC] border border-[#FCFCFC] py-[14px] text-black px-[15px] flex items-center gap-1 cursor-pointer"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </label>
            </div>
              <div className="flex flex-col md:flex-row md:gap-4 mb-5">
                <div className="flex flex-col w-full">
                  <div className="flex items-center mb-2.5">
                    <InputLabel htmlFor="first_name font-jakarta">First Name</InputLabel>
                  </div>
                  <input
                    id="first_name"
                    type="text"
                    className="text-xs bg-[#1E1E1E] font-jakarta rounded-md mt-1.25 px-3.5 py-4 outline-none"
                    value={userData && userDatas?.data.first_name}
                    readOnly
                  />
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex items-center mb-2.5">
                    <InputLabel htmlFor="last_name font-jakarta">Last Name</InputLabel>
                  </div>
                  <input
                    id="last_name"
                    type="text"
                    className="text-xs bg-[#1E1E1E] font-jakarta rounded-md mt-1.25 px-3.5 py-4 outline-none"
                    value={userData && userDatas?.data.last_name}
                    readOnly
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:gap-4 mb-5">
                <div className="flex flex-col w-full">
                  <div className="flex items-center font-jakarta mb-2.5">
                    <InputLabel htmlFor="email">Email</InputLabel>
                  </div>
                  <input
                    id="email"
                    type="text"
                    className="text-xs bg-[#1E1E1E] font-jakarta rounded-md mt-1.25 px-3.5 py-4 outline-none"
                    value={userData && userDatas?.data.email}
                    readOnly
                  />
                </div>

                <div className="flex flex-col w-full">
                  <div className="flex items-center font-jakarta mb-2.5">
                    <InputLabel htmlFor="phonenumber">Phone Number</InputLabel>
                  </div>
                  <input
                    id="phonenumber"
                    type="number"
                    className="text-xs bg-[#1E1E1E] font-jakarta rounded-md mt-1.25 px-3.5 py-4 outline-none"
                    value={userData && userDatas?.data.phone_no}
                    readOnly
                  />
                </div>
              </div>

              <div className="flex flex-col mb-5">
                <div className="flex items-center font-jakarta mb-2.5">
                  <InputLabel htmlFor="nationality">Nationality</InputLabel>
                </div>
                <input
                  id="nationality"
                  type="text"
                  className="text-xs bg-[#1E1E1E] font-jakarta rounded-md mt-1.25 px-3.5 py-4 outline-none"
                  value={userData && userDatas?.data.country}
                  readOnly
                />
              </div>

              <div className="flex flex-col mb-5">
                <div className="flex items-center font-jakarta mb-2.5">
                  <InputLabel htmlFor="address">Address</InputLabel>
                </div>
                <input
                  id="address"
                  type="text"
                  className="text-xs bg-[#1E1E1E] font-jakarta rounded-md mt-1.25 px-3.5 py-4 outline-none"
                  value={userData && userDatas?.data.home_address}
                  readOnly
                />
              </div>

              <div className="flex justify-between items-center mb-5">
                <span className="text-sm text-[#FCFCFC] font-jakarta">KYC Verification</span>
                <button
                  type="button"
                  className={`py-2 px-4 rounded ${getButtonStyle()}`}
                  onClick={handleButtonClick}
                >
                  {getButtonText()}
                </button>
              </div>

              <div className="flex gap-[50px] my-4 items-center">
                <span className="font-jakarta">Need help updating information?</span>
                <Link to="/support" className=" flex items-center font-jakarta rounded-md">
                  <HelpIcon />
                  Support
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-black p-6 rounded-lg shadow-lg w-[40%] mt-[10%] text-center">
            <Kycs 
            
            Close={handleCloseModal}
            />
           
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default Profile;
