import React from 'react';
import AppBar from '@mui/material/AppBar';
import { FaRegHandshake } from 'react-icons/fa';
import { LuHeart } from 'react-icons/lu';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { LiaTimesSolid } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';
import { userRequest } from '../requestMethod';
import { toast } from 'react-toastify';
import Whishlist from './Whishlist';
import { useSelector } from 'react-redux';
import { RootState } from '../services/store';
import { logo } from "../assets/images";

interface TopBarProps {
  activeLink: string;
}

const TopBar: React.FC<TopBarProps> = ({ activeLink }) => {
  const [notificationList, setNotificationList] = React.useState<any[]>([]);
  const [unreadNotificationsIds, setUnreadNotificationsIds] = React.useState<any[]>([]);
  const [unreadNotificationLength, setUnreadNotificationLength] = React.useState(0);
  const [userCartLength, setUserCartLength] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [handshakeIconColor, setHandshakeIconColor] = React.useState<string>('#9A9FA5');
  const navigate = useNavigate();

  const userData = useSelector((state: any) => state.login.userData);

  React.useEffect(() => {
    const getUserCartLength = async () => {
      try {
        const res = await userRequest.get('/user/carts/items', {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${userData?.data?.token}`,
          },
        });
        setUserCartLength(res?.data?.data?.length);
      } catch (err: any) {
        toast.error(err.response?.data?.message || 'Failed to fetch cart items');
      }
    };
    getUserCartLength();
  }, [userData?.data?.token]);

  React.useEffect(() => {
    const getUnreadNotificationsLength = async () => {
      try {
        const res = await userRequest.get('/notifications', {
          headers: {
            Authorization: `Bearer ${userData?.data?.token}`,
          },
        });
        let data = res.data.data.data;
        let count = 0;
        for (let element of data) {
          if (element.is_read === false) {
            count++;
          } else {
            break;
          }
        }
        setUnreadNotificationLength(count);
      } catch (err) {
        console.error(err);
      }
    };
    getUnreadNotificationsLength();
  }, [userData?.data?.token]);

  const handleHandshakeIconClick = () => {
    setHandshakeIconColor('red');
    setTimeout(() => {
      navigate('/cart');
    }, 0);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="border-b border-gray-500">
      <AppBar position="static" className="bg-[#06080A] py-6 lg:px-6 px-2" sx={{ background: '#06080A' }}>
        <div className="flex justify-between items-center">
          <a href='/overview' className="lg:hidden block">
            <img src={logo} alt="logo" className="w-24" />
          </a>
          <p className="hidden sm:flex text-2xl font-jakarta">
            {activeLink === 'overview' ? `Welcome, ${userData?.data?.first_name}` : `${activeLink.charAt(0).toUpperCase() + activeLink.slice(1)}`}
          </p>
          <div className="flex lg:gap-6 gap-2 pr-9 lg:pr-4">
            <FaRegHandshake
              className={`h-5 w-5 cursor-pointer`}
              style={{ color: handshakeIconColor }}
              onClick={handleHandshakeIconClick}
            />
            <LuHeart className="text-[#9A9FA5] h-5 w-5 cursor-pointer" onClick={toggleModal} />
            <IoMdNotificationsOutline className="text-[#9A9FA5] h-5 w-5 cursor-pointer" />
          </div>
        </div>
      </AppBar>

      {isModalOpen && (
        <div className="fixed top-0 right-0 h-full lg:w-[30%] w-[80%] lg:p-5 p-1 bg-black border-l border-gray-500 shadow-lg z-50">
          <div className="bg-black z-10 h-[10vh]">
            <div className="flex pr-3 pt-3 flex-row justify-end text-end w-full">
              <LiaTimesSolid onClick={toggleModal} className="bg-gray-500 text-white text-[25px] cursor-pointer" />
            </div>
            <p className="text-[20px] font-jakarta">Whishlist</p>
          </div>
          <div className="custom-scroll-content pt-7">
            <Whishlist />
          </div>
        </div>
      )}
    </div>
  );
};

export default TopBar;
