import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import UserOverview from "../UserOverview/useroverview";
import { useSelector } from "react-redux";
import Wrapper from "../../components/Wrapper";

// Styled components
const ImgContainer = styled.div`
  width: 104px;
  height: 84px;
  overflow: hidden;
`;

const Img = styled.img`
  border-radius: 10px;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const Overview = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);

  // Get userData from Redux state
  const userData = useSelector((state: any) => state.login.userData);

  // Fetch base URL from env variables
  const baseUrl = process.env.REACT_APP_BASE_URL;
console.log(baseUrl)
  // Fetch User Overview data
  useEffect(() => {
    const fetchData = async () => {
      if (!userData || !userData.data.token) return;

      setLoading(true);

      try {
        const response = await fetch(`${baseUrl}/user/overviews`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData.data.token}`,
          },
         
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        setData(result); // Set the fetched data
      } catch (error) {
        console.error("Error fetching user overview:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [baseUrl, userData]);

  return (
    <Wrapper>
      {loading ? (
        <p>Loading...</p>
      ) : (
        data ? (
          <UserOverview  />
        ) : (
          <p>No data available</p>
        )
      )}
    </Wrapper>
  );
};

export default Overview;
