export {default as Home} from './Home';
export {default as Profile } from './Profile/Profile';
export {default as Properties } from './Properties';
export {default as Register } from '././Authentication/Registar';
export {default as Kyc } from '././Authentication/Kyc';
export {default as SkipKyc} from '././Authentication/SkipKyc';
export {default as Login} from './Authentication/Login';
export {default as TwoFactorAuth} from './Authentication/TwoFactorAuth';
export {default as ForgotPassword} from './Authentication/ForgotPassword';
export {default as Account} from './Authentication/Account'
export {default as Settings} from './Profile/Settings'
export {default as PropertyDetails} from './PropertyDetails'
export {default as Portfolio} from './Portfolio/Portfolio'
export {default as IncomeHistory } from './Portfolio/IncomeHistory'
export {default as Loan} from './Portfolio/Loan'
export {default as Cart} from './cart'
export {default as Booking} from './Booking'
export {default as BookingHistory} from './BookingHistory'
export {default as BookingDetails} from './BookingDetails'
export {default as Rewards} from './Rewards/Rewards'
export {default as Support} from './Support/Support'
export {default as Overview} from './Overview/Overview'
export {default as Payment} from './Payment/Payment'
export {default as Wallet} from './Wallet'