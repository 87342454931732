import axios from "axios";
import Cookies from "js-cookie";
const user = Cookies.get("userData");
const userData = user && JSON.parse(user);
const token = userData?.token;

export default axios.create({
  baseURL: "https://maihomminvest.com/api",
  
  headers: {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
});
