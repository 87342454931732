import {
    createSlice
} from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        // products: [],
        quantity: 0
    },
    reducers: {
        saveProduct: (state) => {
            console.log('hit')
            // state.products.push(action.payload);
            state.quantity += state.quantity
            // state.quantity = state.quantity.size;
        },
        removeSavedProduct: (state, action) => {
            // state.products = state.products.filter((e) => e != action.payload)
            if(state.quantity === 0){
                return;
            }
            state.quantity--
            // state.quantity = state.quantity.size;
        },
        resetSavedProducts: (state, action) => {
            // state.products = action.payload;
            // state.quantity = state.products.length;
        },
    },
});

export const {
    saveProduct,
    removeSavedProduct,
    resetSavedProducts
} = cartSlice.actions;
export default cartSlice.reducer;