import React, { useEffect, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { FaEye, FaEyeSlash, FaCheck } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { publicRequest } from '../../requestMethod'; // Adjust import path based on your project structure
import { CircularProgress } from '@mui/material';
import { MdOutlineKeyboardArrowDown, MdClose } from "react-icons/md";
import { GoSearch } from "react-icons/go";
import Verification from './Verification';
import { toast } from 'react-toastify';
import { loginUserSuccess } from '../../services/loginSlice';
import { useDispatch, useSelector } from "react-redux";

const countries = [
    {
        id: 1,
        name: "Afghanistan",
        shortname: "AF",
        phonecode: "93",
        created_at: null,
        updated_at: null
    },
    {
        id: 2,
        name: "Albania",
        shortname: "AL",
        phonecode: "355",
        created_at: null,
        updated_at: null
    },
    {
        "id": 3,
        "name": "Algeria",
        "shortname": "DZ",
        "phonecode": "213",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 4,
        "name": "American Samoa",
        "shortname": "AS",
        "phonecode": "1684",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 5,
        "name": "Andorra",
        "shortname": "AD",
        "phonecode": "376",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 6,
        "name": "Angola",
        "shortname": "AO",
        "phonecode": "244",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 7,
        "name": "Anguilla",
        "shortname": "AI",
        "phonecode": "1264",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 8,
        "name": "Antarctica",
        "shortname": "AQ",
        "phonecode": "0",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 9,
        "name": "Antigua And Barbuda",
        "shortname": "AG",
        "phonecode": "1268",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 10,
        "name": "Argentina",
        "shortname": "AR",
        "phonecode": "54",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 11,
        "name": "Armenia",
        "shortname": "AM",
        "phonecode": "374",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 12,
        "name": "Aruba",
        "shortname": "AW",
        "phonecode": "297",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 13,
        "name": "Australia",
        "shortname": "AU",
        "phonecode": "61",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 14,
        "name": "Austria",
        "shortname": "AT",
        "phonecode": "43",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 15,
        "name": "Azerbaijan",
        "shortname": "AZ",
        "phonecode": "994",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 16,
        "name": "Bahamas The",
        "shortname": "BS",
        "phonecode": "1242",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 17,
        "name": "Bahrain",
        "shortname": "BH",
        "phonecode": "973",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 18,
        "name": "Bangladesh",
        "shortname": "BD",
        "phonecode": "880",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 19,
        "name": "Barbados",
        "shortname": "BB",
        "phonecode": "1246",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 20,
        "name": "Belarus",
        "shortname": "BY",
        "phonecode": "375",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 21,
        "name": "Belgium",
        "shortname": "BE",
        "phonecode": "32",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 22,
        "name": "Belize",
        "shortname": "BZ",
        "phonecode": "501",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 23,
        "name": "Benin",
        "shortname": "BJ",
        "phonecode": "229",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 24,
        "name": "Bermuda",
        "shortname": "BM",
        "phonecode": "1441",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 25,
        "name": "Bhutan",
        "shortname": "BT",
        "phonecode": "975",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 26,
        "name": "Bolivia",
        "shortname": "BO",
        "phonecode": "591",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 27,
        "name": "Bosnia and Herzegovina",
        "shortname": "BA",
        "phonecode": "387",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 28,
        "name": "Botswana",
        "shortname": "BW",
        "phonecode": "267",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 29,
        "name": "Bouvet Island",
        "shortname": "BV",
        "phonecode": "0",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 30,
        "name": "Brazil",
        "shortname": "BR",
        "phonecode": "55",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 31,
        "name": "British Indian Ocean Territory",
        "shortname": "IO",
        "phonecode": "246",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 32,
        "name": "Brunei",
        "shortname": "BN",
        "phonecode": "673",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 33,
        "name": "Bulgaria",
        "shortname": "BG",
        "phonecode": "359",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 34,
        "name": "Burkina Faso",
        "shortname": "BF",
        "phonecode": "226",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 35,
        "name": "Burundi",
        "shortname": "BI",
        "phonecode": "257",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 36,
        "name": "Cambodia",
        "shortname": "KH",
        "phonecode": "855",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 37,
        "name": "Cameroon",
        "shortname": "CM",
        "phonecode": "237",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 38,
        "name": "Canada",
        "shortname": "CA",
        "phonecode": "1",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 39,
        "name": "Cape Verde",
        "shortname": "CV",
        "phonecode": "238",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 40,
        "name": "Cayman Islands",
        "shortname": "KY",
        "phonecode": "1345",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 41,
        "name": "Central African Republic",
        "shortname": "CF",
        "phonecode": "236",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 42,
        "name": "Chad",
        "shortname": "TD",
        "phonecode": "235",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 43,
        "name": "Chile",
        "shortname": "CL",
        "phonecode": "56",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 44,
        "name": "China",
        "shortname": "CN",
        "phonecode": "86",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 45,
        "name": "Christmas Island",
        "shortname": "CX",
        "phonecode": "61",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 46,
        "name": "Cocos (Keeling) Islands",
        "shortname": "CC",
        "phonecode": "672",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 47,
        "name": "Colombia",
        "shortname": "CO",
        "phonecode": "57",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 48,
        "name": "Comoros",
        "shortname": "KM",
        "phonecode": "269",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 49,
        "name": "Republic Of The Congo",
        "shortname": "CG",
        "phonecode": "242",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 50,
        "name": "Democratic Republic Of The Congo",
        "shortname": "CD",
        "phonecode": "242",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 51,
        "name": "Cook Islands",
        "shortname": "CK",
        "phonecode": "682",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 52,
        "name": "Costa Rica",
        "shortname": "CR",
        "phonecode": "506",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 53,
        "name": "Cote D'Ivoire (Ivory Coast)",
        "shortname": "CI",
        "phonecode": "225",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 54,
        "name": "Croatia (Hrvatska)",
        "shortname": "HR",
        "phonecode": "385",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 55,
        "name": "Cuba",
        "shortname": "CU",
        "phonecode": "53",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 56,
        "name": "Cyprus",
        "shortname": "CY",
        "phonecode": "357",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 57,
        "name": "Czech Republic",
        "shortname": "CZ",
        "phonecode": "420",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 58,
        "name": "Denmark",
        "shortname": "DK",
        "phonecode": "45",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 59,
        "name": "Djibouti",
        "shortname": "DJ",
        "phonecode": "253",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 60,
        "name": "Dominica",
        "shortname": "DM",
        "phonecode": "1767",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 61,
        "name": "Dominican Republic",
        "shortname": "DO",
        "phonecode": "1809",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 62,
        "name": "East Timor",
        "shortname": "TP",
        "phonecode": "670",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 63,
        "name": "Ecuador",
        "shortname": "EC",
        "phonecode": "593",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 64,
        "name": "Egypt",
        "shortname": "EG",
        "phonecode": "20",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 65,
        "name": "El Salvador",
        "shortname": "SV",
        "phonecode": "503",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 66,
        "name": "Equatorial Guinea",
        "shortname": "GQ",
        "phonecode": "240",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 67,
        "name": "Eritrea",
        "shortname": "ER",
        "phonecode": "291",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 68,
        "name": "Estonia",
        "shortname": "EE",
        "phonecode": "372",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 69,
        "name": "Ethiopia",
        "shortname": "ET",
        "phonecode": "251",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 70,
        "name": "External Territories of Australia",
        "shortname": "XA",
        "phonecode": "61",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 71,
        "name": "Falkland Islands",
        "shortname": "FK",
        "phonecode": "500",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 72,
        "name": "Faroe Islands",
        "shortname": "FO",
        "phonecode": "298",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 73,
        "name": "Fiji Islands",
        "shortname": "FJ",
        "phonecode": "679",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 74,
        "name": "Finland",
        "shortname": "FI",
        "phonecode": "358",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 75,
        "name": "France",
        "shortname": "FR",
        "phonecode": "33",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 76,
        "name": "French Guiana",
        "shortname": "GF",
        "phonecode": "594",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 77,
        "name": "French Polynesia",
        "shortname": "PF",
        "phonecode": "689",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 78,
        "name": "French Southern Territories",
        "shortname": "TF",
        "phonecode": "0",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 79,
        "name": "Gabon",
        "shortname": "GA",
        "phonecode": "241",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 80,
        "name": "Gambia The",
        "shortname": "GM",
        "phonecode": "220",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 81,
        "name": "Georgia",
        "shortname": "GE",
        "phonecode": "995",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 82,
        "name": "Germany",
        "shortname": "DE",
        "phonecode": "49",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 83,
        "name": "Ghana",
        "shortname": "GH",
        "phonecode": "233",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 84,
        "name": "Gibraltar",
        "shortname": "GI",
        "phonecode": "350",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 85,
        "name": "Greece",
        "shortname": "GR",
        "phonecode": "30",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 86,
        "name": "Greenland",
        "shortname": "GL",
        "phonecode": "299",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 87,
        "name": "Grenada",
        "shortname": "GD",
        "phonecode": "1473",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 88,
        "name": "Guadeloupe",
        "shortname": "GP",
        "phonecode": "590",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 89,
        "name": "Guam",
        "shortname": "GU",
        "phonecode": "1671",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 90,
        "name": "Guatemala",
        "shortname": "GT",
        "phonecode": "502",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 91,
        "name": "Guernsey and Alderney",
        "shortname": "XU",
        "phonecode": "44",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 92,
        "name": "Guinea",
        "shortname": "GN",
        "phonecode": "224",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 93,
        "name": "Guinea-Bissau",
        "shortname": "GW",
        "phonecode": "245",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 94,
        "name": "Guyana",
        "shortname": "GY",
        "phonecode": "592",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 95,
        "name": "Haiti",
        "shortname": "HT",
        "phonecode": "509",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 96,
        "name": "Heard and McDonald Islands",
        "shortname": "HM",
        "phonecode": "0",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 97,
        "name": "Honduras",
        "shortname": "HN",
        "phonecode": "504",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 98,
        "name": "Hong Kong S.A.R.",
        "shortname": "HK",
        "phonecode": "852",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 99,
        "name": "Hungary",
        "shortname": "HU",
        "phonecode": "36",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 100,
        "name": "Iceland",
        "shortname": "IS",
        "phonecode": "354",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 101,
        "name": "India",
        "shortname": "IN",
        "phonecode": "91",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 102,
        "name": "Indonesia",
        "shortname": "ID",
        "phonecode": "62",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 103,
        "name": "Iran",
        "shortname": "IR",
        "phonecode": "98",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 104,
        "name": "Iraq",
        "shortname": "IQ",
        "phonecode": "964",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 105,
        "name": "Ireland",
        "shortname": "IE",
        "phonecode": "353",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 106,
        "name": "Israel",
        "shortname": "IL",
        "phonecode": "972",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 107,
        "name": "Italy",
        "shortname": "IT",
        "phonecode": "39",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 108,
        "name": "Jamaica",
        "shortname": "JM",
        "phonecode": "1876",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 109,
        "name": "Japan",
        "shortname": "JP",
        "phonecode": "81",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 110,
        "name": "Jersey",
        "shortname": "XJ",
        "phonecode": "44",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 111,
        "name": "Jordan",
        "shortname": "JO",
        "phonecode": "962",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 112,
        "name": "Kazakhstan",
        "shortname": "KZ",
        "phonecode": "7",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 113,
        "name": "Kenya",
        "shortname": "KE",
        "phonecode": "254",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 114,
        "name": "Kiribati",
        "shortname": "KI",
        "phonecode": "686",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 115,
        "name": "Korea North",
        "shortname": "KP",
        "phonecode": "850",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 116,
        "name": "Korea South",
        "shortname": "KR",
        "phonecode": "82",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 117,
        "name": "Kuwait",
        "shortname": "KW",
        "phonecode": "965",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 118,
        "name": "Kyrgyzstan",
        "shortname": "KG",
        "phonecode": "996",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 119,
        "name": "Laos",
        "shortname": "LA",
        "phonecode": "856",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 120,
        "name": "Latvia",
        "shortname": "LV",
        "phonecode": "371",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 121,
        "name": "Lebanon",
        "shortname": "LB",
        "phonecode": "961",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 122,
        "name": "Lesotho",
        "shortname": "LS",
        "phonecode": "266",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 123,
        "name": "Liberia",
        "shortname": "LR",
        "phonecode": "231",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 124,
        "name": "Libya",
        "shortname": "LY",
        "phonecode": "218",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 125,
        "name": "Liechtenstein",
        "shortname": "LI",
        "phonecode": "423",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 126,
        "name": "Lithuania",
        "shortname": "LT",
        "phonecode": "370",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 127,
        "name": "Luxembourg",
        "shortname": "LU",
        "phonecode": "352",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 128,
        "name": "Macau S.A.R.",
        "shortname": "MO",
        "phonecode": "853",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 129,
        "name": "Macedonia",
        "shortname": "MK",
        "phonecode": "389",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 130,
        "name": "Madagascar",
        "shortname": "MG",
        "phonecode": "261",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 131,
        "name": "Malawi",
        "shortname": "MW",
        "phonecode": "265",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 132,
        "name": "Malaysia",
        "shortname": "MY",
        "phonecode": "60",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 133,
        "name": "Maldives",
        "shortname": "MV",
        "phonecode": "960",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 134,
        "name": "Mali",
        "shortname": "ML",
        "phonecode": "223",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 135,
        "name": "Malta",
        "shortname": "MT",
        "phonecode": "356",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 136,
        "name": "Man (Isle of)",
        "shortname": "XM",
        "phonecode": "44",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 137,
        "name": "Marshall Islands",
        "shortname": "MH",
        "phonecode": "692",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 138,
        "name": "Martinique",
        "shortname": "MQ",
        "phonecode": "596",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 139,
        "name": "Mauritania",
        "shortname": "MR",
        "phonecode": "222",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 140,
        "name": "Mauritius",
        "shortname": "MU",
        "phonecode": "230",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 141,
        "name": "Mayotte",
        "shortname": "YT",
        "phonecode": "269",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 142,
        "name": "Mexico",
        "shortname": "MX",
        "phonecode": "52",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 143,
        "name": "Micronesia",
        "shortname": "FM",
        "phonecode": "691",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 144,
        "name": "Moldova",
        "shortname": "MD",
        "phonecode": "373",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 145,
        "name": "Monaco",
        "shortname": "MC",
        "phonecode": "377",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 146,
        "name": "Mongolia",
        "shortname": "MN",
        "phonecode": "976",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 147,
        "name": "Montserrat",
        "shortname": "MS",
        "phonecode": "1664",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 148,
        "name": "Morocco",
        "shortname": "MA",
        "phonecode": "212",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 149,
        "name": "Mozambique",
        "shortname": "MZ",
        "phonecode": "258",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 150,
        "name": "Myanmar",
        "shortname": "MM",
        "phonecode": "95",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 151,
        "name": "Namibia",
        "shortname": "NA",
        "phonecode": "264",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 152,
        "name": "Nauru",
        "shortname": "NR",
        "phonecode": "674",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 153,
        "name": "Nepal",
        "shortname": "NP",
        "phonecode": "977",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 154,
        "name": "Netherlands Antilles",
        "shortname": "AN",
        "phonecode": "599",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 155,
        "name": "Netherlands The",
        "shortname": "NL",
        "phonecode": "31",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 156,
        "name": "New Caledonia",
        "shortname": "NC",
        "phonecode": "687",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 157,
        "name": "New Zealand",
        "shortname": "NZ",
        "phonecode": "64",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 158,
        "name": "Nicaragua",
        "shortname": "NI",
        "phonecode": "505",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 159,
        "name": "Niger",
        "shortname": "NE",
        "phonecode": "227",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 160,
        "name": "Nigeria",
        "shortname": "NG",
        "phonecode": "234",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 161,
        "name": "Niue",
        "shortname": "NU",
        "phonecode": "683",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 162,
        "name": "Norfolk Island",
        "shortname": "NF",
        "phonecode": "672",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 163,
        "name": "Northern Mariana Islands",
        "shortname": "MP",
        "phonecode": "1670",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 164,
        "name": "Norway",
        "shortname": "NO",
        "phonecode": "47",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 165,
        "name": "Oman",
        "shortname": "OM",
        "phonecode": "968",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 166,
        "name": "Pakistan",
        "shortname": "PK",
        "phonecode": "92",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 167,
        "name": "Palau",
        "shortname": "PW",
        "phonecode": "680",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 168,
        "name": "Palestinian Territory Occupied",
        "shortname": "PS",
        "phonecode": "970",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 169,
        "name": "Panama",
        "shortname": "PA",
        "phonecode": "507",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 170,
        "name": "Papua new Guinea",
        "shortname": "PG",
        "phonecode": "675",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 171,
        "name": "Paraguay",
        "shortname": "PY",
        "phonecode": "595",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 172,
        "name": "Peru",
        "shortname": "PE",
        "phonecode": "51",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 173,
        "name": "Philippines",
        "shortname": "PH",
        "phonecode": "63",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 174,
        "name": "Pitcairn Island",
        "shortname": "PN",
        "phonecode": "0",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 175,
        "name": "Poland",
        "shortname": "PL",
        "phonecode": "48",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 176,
        "name": "Portugal",
        "shortname": "PT",
        "phonecode": "351",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 177,
        "name": "Puerto Rico",
        "shortname": "PR",
        "phonecode": "1787",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 178,
        "name": "Qatar",
        "shortname": "QA",
        "phonecode": "974",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 179,
        "name": "Reunion",
        "shortname": "RE",
        "phonecode": "262",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 180,
        "name": "Romania",
        "shortname": "RO",
        "phonecode": "40",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 181,
        "name": "Russia",
        "shortname": "RU",
        "phonecode": "70",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 182,
        "name": "Rwanda",
        "shortname": "RW",
        "phonecode": "250",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 183,
        "name": "Saint Helena",
        "shortname": "SH",
        "phonecode": "290",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 184,
        "name": "Saint Kitts And Nevis",
        "shortname": "KN",
        "phonecode": "1869",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 185,
        "name": "Saint Lucia",
        "shortname": "LC",
        "phonecode": "1758",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 186,
        "name": "Saint Pierre and Miquelon",
        "shortname": "PM",
        "phonecode": "508",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 187,
        "name": "Saint Vincent And The Grenadines",
        "shortname": "VC",
        "phonecode": "1784",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 188,
        "name": "Samoa",
        "shortname": "WS",
        "phonecode": "684",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 189,
        "name": "San Marino",
        "shortname": "SM",
        "phonecode": "378",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 190,
        "name": "Sao Tome and Principe",
        "shortname": "ST",
        "phonecode": "239",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 191,
        "name": "Saudi Arabia",
        "shortname": "SA",
        "phonecode": "966",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 192,
        "name": "Senegal",
        "shortname": "SN",
        "phonecode": "221",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 193,
        "name": "Serbia",
        "shortname": "RS",
        "phonecode": "381",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 194,
        "name": "Seychelles",
        "shortname": "SC",
        "phonecode": "248",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 195,
        "name": "Sierra Leone",
        "shortname": "SL",
        "phonecode": "232",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 196,
        "name": "Singapore",
        "shortname": "SG",
        "phonecode": "65",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 197,
        "name": "Slovakia",
        "shortname": "SK",
        "phonecode": "421",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 198,
        "name": "Slovenia",
        "shortname": "SI",
        "phonecode": "386",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 199,
        "name": "Smaller Territories of the UK",
        "shortname": "XG",
        "phonecode": "44",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 200,
        "name": "Solomon Islands",
        "shortname": "SB",
        "phonecode": "677",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 201,
        "name": "Somalia",
        "shortname": "SO",
        "phonecode": "252",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 202,
        "name": "South Africa",
        "shortname": "ZA",
        "phonecode": "27",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 203,
        "name": "South Georgia",
        "shortname": "GS",
        "phonecode": "0",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 204,
        "name": "South Sudan",
        "shortname": "SS",
        "phonecode": "211",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 205,
        "name": "Spain",
        "shortname": "ES",
        "phonecode": "34",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 206,
        "name": "Sri Lanka",
        "shortname": "LK",
        "phonecode": "94",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 207,
        "name": "Sudan",
        "shortname": "SD",
        "phonecode": "249",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 208,
        "name": "Suriname",
        "shortname": "SR",
        "phonecode": "597",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 209,
        "name": "Svalbard And Jan Mayen Islands",
        "shortname": "SJ",
        "phonecode": "47",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 210,
        "name": "Swaziland",
        "shortname": "SZ",
        "phonecode": "268",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 211,
        "name": "Sweden",
        "shortname": "SE",
        "phonecode": "46",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 212,
        "name": "Switzerland",
        "shortname": "CH",
        "phonecode": "41",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 213,
        "name": "Syria",
        "shortname": "SY",
        "phonecode": "963",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 214,
        "name": "Taiwan",
        "shortname": "TW",
        "phonecode": "886",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 215,
        "name": "Tajikistan",
        "shortname": "TJ",
        "phonecode": "992",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 216,
        "name": "Tanzania",
        "shortname": "TZ",
        "phonecode": "255",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 217,
        "name": "Thailand",
        "shortname": "TH",
        "phonecode": "66",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 218,
        "name": "Togo",
        "shortname": "TG",
        "phonecode": "228",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 219,
        "name": "Tokelau",
        "shortname": "TK",
        "phonecode": "690",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 220,
        "name": "Tonga",
        "shortname": "TO",
        "phonecode": "676",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 221,
        "name": "Trinidad And Tobago",
        "shortname": "TT",
        "phonecode": "1868",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 222,
        "name": "Tunisia",
        "shortname": "TN",
        "phonecode": "216",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 223,
        "name": "Turkey",
        "shortname": "TR",
        "phonecode": "90",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 224,
        "name": "Turkmenistan",
        "shortname": "TM",
        "phonecode": "7370",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 225,
        "name": "Turks And Caicos Islands",
        "shortname": "TC",
        "phonecode": "1649",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 226,
        "name": "Tuvalu",
        "shortname": "TV",
        "phonecode": "688",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 227,
        "name": "Uganda",
        "shortname": "UG",
        "phonecode": "256",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 228,
        "name": "Ukraine",
        "shortname": "UA",
        "phonecode": "380",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 229,
        "name": "United Arab Emirates",
        "shortname": "AE",
        "phonecode": "971",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 230,
        "name": "United Kingdom",
        "shortname": "GB",
        "phonecode": "44",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 231,
        "name": "United States",
        "shortname": "US",
        "phonecode": "1",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 232,
        "name": "United States Minor Outlying Islands",
        "shortname": "UM",
        "phonecode": "1",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 233,
        "name": "Uruguay",
        "shortname": "UY",
        "phonecode": "598",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 234,
        "name": "Uzbekistan",
        "shortname": "UZ",
        "phonecode": "998",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 235,
        "name": "Vanuatu",
        "shortname": "VU",
        "phonecode": "678",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 236,
        "name": "Vatican City State (Holy See)",
        "shortname": "VA",
        "phonecode": "39",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 237,
        "name": "Venezuela",
        "shortname": "VE",
        "phonecode": "58",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 238,
        "name": "Vietnam",
        "shortname": "VN",
        "phonecode": "84",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 239,
        "name": "Virgin Islands (British)",
        "shortname": "VG",
        "phonecode": "1284",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 240,
        "name": "Virgin Islands (US)",
        "shortname": "VI",
        "phonecode": "1340",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 241,
        "name": "Wallis And Futuna Islands",
        "shortname": "WF",
        "phonecode": "681",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 242,
        "name": "Western Sahara",
        "shortname": "EH",
        "phonecode": "212",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 243,
        "name": "Yemen",
        "shortname": "YE",
        "phonecode": "967",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 244,
        "name": "Yugoslavia",
        "shortname": "YU",
        "phonecode": "38",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 245,
        "name": "Zambia",
        "shortname": "ZM",
        "phonecode": "260",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 246,
        "name": "Zimbabwe",
        "shortname": "ZW",
        "phonecode": "263",
        "created_at": null,
        "updated_at": null
    }
    // Add more countries as needed
];

const nationalities = [
    'Nigerian',
    'Afghanistan',
    'American',
    'British',
    // Add more nationalities as needed
];

interface RegisterData {
    first_name: string;
    last_name: string;
    phone_no: string;
    email: string;
    password: string;
}

const UserSignup: React.FC = () => {
    const navigate = useNavigate();
    const [selectedCountry, setSelectedCountry] = useState(countries[0]); // Default to the first country
    const [selectedNationality, setSelectedNationality] = useState(''); // Default to empty nationality
    const [showCountryDropdown, setShowCountryDropdown] = useState(false); // State to manage country dropdown visibility
    const [showNationalityDropdown, setShowNationalityDropdown] = useState(false); // State to manage nationality dropdown visibility
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [passwordRequirements, setPasswordRequirements] = useState({
        length: false,
        uppercase: false,
        number: false,
        symbol: false,
    });
    const [isLoading, setIsLoading] = useState(false); // State to manage loading state
    const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to manage confirm password visibility
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);
    const [verification, setVerification] = useState(false);
    const [registerData, setRegisterData] = useState<RegisterData | null>(null);
    const [searchQuery, setSearchQuery] = useState('');

    const handleCountryChange = (country: typeof countries[0]) => {
        setSelectedCountry(country);
        setShowCountryDropdown(false); // Close country dropdown after selection
    };

    const handleNationalityChange = (nationality: string) => {
        setSelectedNationality(nationality);
        setShowNationalityDropdown(false); // Close nationality dropdown after selection
    };

    const toggleCountryDropdown = () => {
        setShowCountryDropdown(!showCountryDropdown);
        setShowNationalityDropdown(false);
    };

    const filteredCountries = countries.filter(country =>
        country.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const toggleNationalityDropdown = () => {
        setShowNationalityDropdown(!showNationalityDropdown);
        setShowCountryDropdown(false);
    };

    const handlePasswordChange = (value: string) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const isLengthValid = value.length >= 8;
        const isUppercaseValid = /[A-Z]/.test(value);
        const isNumberValid = /\d/.test(value);
        const isSymbolValid = /[@$!%*?&]/.test(value);

        setPasswordRequirements({
            length: isLengthValid,
            uppercase: isUppercaseValid,
            number: isNumberValid,
            symbol: isSymbolValid,
        });
    };

    const dispatch = useDispatch();

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
          const userData = JSON.parse(storedUserData);
          dispatch(loginUserSuccess({ userData }));
        }
      }, [dispatch]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData: any = {};
        const formElements = Array.from(e.currentTarget.elements) as HTMLInputElement[];
        const newErrors = {};

        if (!isCheckboxChecked) {
            setIsLoading(false);
            setCheckboxError(true);
            setTimeout(() => {
                setCheckboxError(false);
            }, 2600);
        } else {
            setIsLoading(false);
            setCheckboxError(false);
        }

        if (Object.keys(newErrors).length === 0 && isCheckboxChecked) {
            setIsLoading(false);
            // Perform form submission
            // After form submission, reset isLoading
            setIsLoading(false);
        }
        setErrors(newErrors);
        // Reset errors
        setErrors({});
        setIsLoading(true); // Set loading state to true when form submission starts

        // Collect form data and validate
        formElements.forEach((element) => {
            if (element.name) {
                formData[element.name] = element.value.trim();

                // Basic validation - check if required fields are filled
                if (!formData[element.name]) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [element.name]: `${element.name.charAt(0).toUpperCase() + element.name.slice(1)} is required.`,
                    }));
                }
            }
        });

        // Additional validation like password matching
        if (formData.password !== formData.confirmPassword) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                confirmPassword: 'Passwords do not match.',
            }));
        }

        // Validate nationality dropdown
        if (!selectedNationality) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                nationality: 'Please select your nationality.',
            }));
        }

        // If there are errors, stop form submission
        if (Object.keys(errors).length > 0) {
            setIsLoading(false); // Set loading state to false if there are errors
            return;
        }

        // Determine country_id based on selected nationality

        // Construct payload with only the required fields
        const payload = {

            first_name: formData.firstName,
            last_name: formData.lastName,
            phone_no: formData.phoneNumber,
            email: formData.email,
            password: formData.password,
            country_id: selectedCountry.phonecode, 
            home_address: formData.address,
            user_type: "Co-Owner"
        };
        setRegisterData(payload)
        setVerification(true)

        // Log payload to console
        console.log(payload, "payload");
        try {
            const response = await publicRequest({
                method: 'POST',
                url: '/register',
                data: payload,
            });
            const userData = response.data;
            // Handle response based on your API logic
            console.log('API Response:', response);
            localStorage.setItem('userData', JSON.stringify(userData));
            dispatch(loginUserSuccess({ userData }));
            toast.success("Registered Successful");
            if (userData.data.email_verified === "no") {
                // Resend email verification OTP
                try {
                  localStorage.setItem('userData', JSON.stringify(userData));
                  await publicRequest.post(`/resend-email-otp/${userData.data.id}`);
                //   toast.success("OTP has been resent. Please check your email.");
                } catch (otpError) {
                //   toast.error("Failed to resend OTP. Please try again.");
                }
                navigate("/email/verification");
              } else{
                  setTimeout(() => {
                      navigate("/overview");
                  }, 2000);

              }
            toast.success("OTP has been resent. Please check your email.");
            // Redirect or perform any action after successful signup
            navigate('/email/verification'); // Example redirect to dashboard after signup
        } catch (error) {
            console.error('Signup Error:', error);
            // Handle error state or display error message to the user
        } finally {
            setIsLoading(false); // Set loading state to false when submission ends
            // Clear errors after 2 seconds
            setTimeout(() => {
                setErrors({});
            }, 2000);
        }
    };


    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const toggleCheckbox = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
        setCheckboxError(false);
    };



    return (
        <div>
            <div className='flex flex-col justify-start'>
                <h2 className="text-[20px] md:text-[36px] font-jakarta font-[600]">Create your account</h2>
                <p className="text-[#9A9FA5] font-[400] font-jakarta mb-4 text-[12px] md:text-[14px]">Let’s get you started. Create an account to begin</p>
            </div>
            {/* Google login section */}
            <div className="flex items-center justify-center py-[10px] px-[15px] rounded-[12px] mb-6 border cursor-pointer border-[#33383F]">
                <img src="/assets/images/Google.svg" alt="Google Login" className="mr-2" />
                <p className='text-[12px] md:text-[14px] font-jakarta'>Log in with Google</p>
            </div>
            {/* Divider line */}
            <div className="flex items-center my-6 w-full">
                <div className="flex-grow border-t border-[#6F767E]"></div>
                <span className="mx-4 text-[14px] text-[#6F767E] font-jakarta">or log in with</span>
                <div className="flex-grow border-t border-[#6F767E]"></div>
            </div>

            {/* Signup form */}
            <form className="w-full flex flex-col gap-4" onSubmit={handleSubmit}>
                {/* First and Last Name inputs */}
                <div className="flex flex-col gap-4 md:flex-row items-start">
                    <div className="flex flex-col w-full md:w-1/2">
                        <label htmlFor="firstName" className="text-[#9A9FA5] text-[14px] font-jakarta">First Name</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            className={`p-3 rounded-[10px] font-jakarta bg-[#1E1E1E] text-white placeholder-[#33383F] outline-none ${errors.firstName ? 'border border-red-500' : 'border border-[#6F767E]'
                                }`}
                            placeholder="First Name"
                        />
                        {errors.firstName && <span className="text-red-500">{errors.firstName}</span>}
                    </div>
                    <div className="flex flex-col w-full md:w-1/2">
                        <label htmlFor="lastName" className="text-[#9A9FA5] font-jakarta text-[14px]">Last Name</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            className={`p-3 rounded-[10px] bg-[#1E1E1E] font-jakarta text-white placeholder-[#33383F] outline-none ${errors.lastName ? 'border border-red-500' : 'border border-[#6F767E]'
                                }`}
                            placeholder="Last Name"
                        />
                        {errors.lastName && <span className="text-red-500">{errors.lastName}</span>}
                    </div>
                </div>
                {/* Email and Phone Number inputs */}
                <div className="flex flex-col gap-3 md:flex-row md:gap-4 items-start">
                    <div className="relative flex flex-col w-full md:flex-1">
                        <label htmlFor="email" className="text-[#9A9FA5] font-jakarta text-[14px]">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className={`p-3 rounded-[10px] font-jakarta bg-[#1E1E1E] text-white placeholder-[#33383F] outline-none ${errors.email ? 'border border-red-500' : 'border border-[#6F767E]'
                                }`}
                            placeholder="Email"
                        />
                        {errors.email && <span className="text-red-500">{errors.email}</span>}
                    </div>
                    <div className="relative flex flex-col w-full md:flex-1">
                        <label htmlFor="phoneNumber" className="text-[#9A9FA5] font-jakarta text-[14px]">Phone Number</label>
                        <div className={`rounded-[10px] flex items-center bg-[#1E1E1E] relative ${errors.phoneNumber ? 'border border-red-500' : 'border border-[#6F767E]'
                            }`}>
                            {/* Custom dropdown for countries */}
                            <div className="relative flex-shrink-0">
                                <div
                                    className={`flex items-center px-[5px] bg-[#1E1E1E] font-jakarta rounded-l-[10px] cursor-pointer`}
                                    onClick={toggleCountryDropdown}
                                >
                                   + <p>{selectedCountry.phonecode}</p>
                                    {/* <p>{selectedCountry.shortname}</p> */}
                                    <MdOutlineKeyboardArrowDown size={25} className="text-[#6F767E]" />
                                </div>
                                {showCountryDropdown && (
                                    <div className="absolute z-10 w-[300%] bg-[#1E1E1E] custom-scroll-content h-[30vh] font-jakarta border border-[#6F767E] mt-1 rounded-[10px]">
                                        <div className="p-2 flex items-center ">
                                            <div className='flex items-center px-2 border border-[#353945] rounded-[10px]'>
                                                <input
                                                    type="text"
                                                    placeholder="Search..."
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                    className="w-full p-2  bg-transparent  text-white outline-none"
                                                />
                                                <GoSearch />
                                            </div>
                                            <MdClose
                                                size={24}
                                                className="text-white ml-2 cursor-pointer"
                                                onClick={() => {
                                                    setShowCountryDropdown(false);
                                                    setSearchQuery('');
                                                }}
                                            />
                                        </div>
                                        {filteredCountries.length > 0 ? (
                                            filteredCountries.map((country, index) => (
                                                <div
                                                    key={index}
                                                    className="py-2 px-4 flex items-center gap-2 cursor-pointer text-[14px] hover:text-white"
                                                    onClick={() => {
                                                        handleCountryChange(country);
                                                        setShowCountryDropdown(false); // Close dropdown after selection
                                                    }}
                                                >
                                                    <span className='text-[14px]'>+ {country.phonecode}</span>
                                                    <span className='text-[14px]'> {country.name}</span>
                                                  
                                                </div>
                                            ))
                                        ) : (
                                            <div className="py-2 px-4 text-white">No results found</div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                className={`p-3 rounded-r-[10px] font-jakarta bg-[#1E1E1E] text-white placeholder-[#33383F] outline-none w-full ${errors.phoneNumber ? 'border border-red-500' : 'border border-[#6F767E]'
                                    }`}
                                placeholder="Phone Number"
                            />
                        </div>
                        {errors.phoneNumber && <span className="text-red-500 font-jakarta">{errors.phoneNumber}</span>}
                    </div>
                </div>


              
                <div className="flex flex-col w-full">
                    <label htmlFor="firstName" className="text-[#9A9FA5] text-[14px] font-jakarta">Address</label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        className={`p-3 rounded-[10px] font-jakarta bg-[#1E1E1E] text-white placeholder-[#33383F] outline-none ${errors.firstName ? 'border border-red-500' : 'border border-[#6F767E]'
                            }`}
                        placeholder="First Name"
                    />
                    {errors.address && <span className="text-red-500">{errors.addresss}</span>}
                </div>
                {/* Password and Confirm Password inputs */}
                <div className="flex flex-col gap-4 md:flex-row items-start">
                    <div className="flex flex-col w-full">
                        <label htmlFor="password" className="text-[#9A9FA5] font-jakarta text-[14px]">Password</label>
                        <div className="relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                name="password"
                                className={`p-3 rounded-[10px] bg-[#1E1E1E] font-jakarta text-white placeholder-[#33383F] outline-none w-full ${errors.password ? 'border border-red-500' : 'border border-[#6F767E]'
                                    }`}
                                placeholder="Password"
                                onChange={(e) => handlePasswordChange(e.target.value)}
                            />
                            <div className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer" onClick={toggleShowPassword}>
                                {showPassword ? <FaEyeSlash className="text-gray-500" /> : <FaEye className="text-gray-500" />}
                            </div>
                        </div>
                        {errors.password && <span className="text-red-500">{errors.password}</span>}
                        {/* Password complexity indicator */}
                        <div className="mt-2 flex-col flex gap-2">
                            <p className={`text-[14px] font-jakarta flex gap-2 ${passwordRequirements.length ? 'text-green-500' : 'text-[#FCFCFC]'}`}>
                                <span className={`px-[9px] py-[1px] bg-[#33383F] border border-[#6F767E] rounded-[5px] ${passwordRequirements.length ? 'border-green-500' : ""}`}></span>
                                Minimum 8 characters
                            </p>
                            <p className={`text-[14px] font-jakarta flex gap-2 ${passwordRequirements.uppercase ? 'text-green-500' : 'text-[#FCFCFC]'}`}>
                                <span className={`px-[9px] py-[1px] bg-[#33383F] border border-[#6F767E] rounded-[5px] ${passwordRequirements.uppercase ? 'border-green-500' : ""}`}></span>
                                At least one uppercase letter
                            </p>
                            <p className={`text-[14px] font-jakarta flex gap-2 ${passwordRequirements.number ? 'text-green-500' : 'text-[#FCFCFC]'}`}>
                                <span className={`px-[9px] py-[1px] bg-[#33383F] border border-[#6F767E] rounded-[5px] ${passwordRequirements.number ? 'border-green-500' : ""}`}></span>
                                At least one number
                            </p>
                            <p className={`text-[14px] font-jakarta flex gap-2 ${passwordRequirements.symbol ? 'text-green-500' : 'text-[#FCFCFC]'}`}>
                                <span className={`px-[9px] py-[1px] bg-[#33383F] border border-[#6F767E] rounded-[5px] ${passwordRequirements.symbol ? 'border-green-500' : ""}`}></span>
                                At least one special character (@$!%*?&)
                            </p>
                        </div>
                    </div>
                </div>
                <div className="relative flex flex-col w-full">
                    <label htmlFor="confirmPassword" className="text-[#9A9FA5] font-jakarta text-[14px]">Confirm Password</label>
                    <div className="relative">
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            name="confirmPassword"
                            className={`p-3 rounded-[10px] bg-[#1E1E1E] font-jakarta text-white placeholder-[#33383F] outline-none w-full ${errors.confirmPassword ? 'border border-red-500' : 'border border-[#6F767E]'
                                }`}
                            placeholder="Confirm Password"
                        />
                        <div className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer" onClick={toggleShowConfirmPassword}>
                            {showConfirmPassword ? <FaEyeSlash className="text-gray-500" /> : <FaEye className="text-gray-500" />}
                        </div>
                    </div>
                    {errors.confirmPassword && <span className="text-red-500">{errors.confirmPassword}</span>}
                </div>
                <div className="flex flex-col gap-4 md:flex-row items-start">


                </div>

                <div className="flex items-center">
                    <div
                        className={`w-6 h-6 rounded-md cursor-pointer flex items-center justify-center ${isCheckboxChecked ? 'bg-[#DEA838]' : `bg-[#1E1E1E]  ${checkboxError ? "border border-[red]" : "border border-[#6F767E]"}`
                            }`}
                        onClick={toggleCheckbox}
                    >
                        {isCheckboxChecked && <FaCheck className="text-white" />}
                    </div>
                    <label htmlFor="checkbox" className="ml-2 text-[#FCFCFC] font-jakarta text-[14px] cursor-pointer" onClick={toggleCheckbox}>
                        I agree to Maihomm’s Terms of use & Privacy Policy
                    </label>

                </div>
                {/* Sign Up button */}
                <button
                    type="submit"
                    className="w-full py-[10px] font-jakarta text-black bg-[#DEA838] rounded-[8px] font-semibold text-[18px] outline-none"
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Create account'}
                </button>
                {/* Sign in text link */}
                <p className="text-center font-jakarta text-[#6F767E] text-[14px] font-[400]">
                    Have an account? <a href="/auth/login" className="text-[#DEA838]">Sign in</a>
                </p>
            </form>
        </div>

    );
};

export default UserSignup;

export { }