import React, { useEffect, useState } from 'react';
import GridViewIcon from '@mui/icons-material/GridView';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BookOnlineOutlinedIcon from '@mui/icons-material/BookOnlineOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PersonIcon from '@mui/icons-material/Person';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { logo } from "../assets/images";

interface SideBarProps {
  setActiveLink: (link: string) => void;
}

const SideBar: React.FC<SideBarProps> = ({ setActiveLink }) => {
  const [activeLinkLocal, setActiveLinkLocal] = useState<string>('');

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (
      currentPath === "/portfolio" ||
      currentPath === "/portfolio/income-history" ||
      currentPath === "/portfolio/details" ||
      /^\/sell\/\d+$/.test(currentPath)
    ) {
      setActiveLinkLocal("portfolio");
    } else if (currentPath === "/properties" || /^\/properties\/\d+$/.test(currentPath)) {
      setActiveLinkLocal("properties");
    } else if (
      currentPath === "/bookings" ||
      /^\/bookings\/\d+$/.test(currentPath) ||
      currentPath === "/bookings/history"
    ) {
      setActiveLinkLocal("bookings");
    } else if (currentPath === "/wallet") {
      setActiveLinkLocal("wallet");
    } else if (currentPath === "/profile" || currentPath === "/settings") {
      setActiveLinkLocal("profile");
    } else if (currentPath === "/support") {
      setActiveLinkLocal("support");
    } else {
      setActiveLinkLocal("overview");
    }
    
    // Set the active link in the parent component
    setActiveLink(activeLinkLocal);
  }, [setActiveLink, activeLinkLocal]);

  return (
    <div className="h-full px-[30px] bg-[#06080A] flex flex-col justify-between">
      <div>
        <div className="py-6">
          <img src={logo} alt="logo" className="w-44" />
        </div>
        <div className="flex flex-col mt-7 gap-2">
          <a
            href="/overview"
            onClick={() => setActiveLink("overview")}
            className={`w-full lg:p-3 p-1 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'overview' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
          >
            <GridViewIcon className="lg:text-[14px] text-[12px]" />
            <span className="ml-2 lg:text-[14px] text-[12px] font-jakarta">Overview</span>
          </a>
          <a
            href="/portfolio"
            onClick={() => setActiveLink("portfolio")}
            className={`w-full lg:p-3 p-1 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'portfolio' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
          >
            <WorkOutlineIcon className="lg:text-[14px] text-[12px]" />
            <span className="ml-2 lg:text-[14px] text-[12px] font-jakarta">Portfolio</span>
          </a>
          <a
            href="/properties"
            onClick={() => setActiveLink("properties")}
            className={`w-full lg:p-3 p-1 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'properties' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
          >
            <ApartmentIcon className="lg:text-[14px] text-[12px]" />
            <span className="ml-2 lg:text-[14px] text-[12px] font-jakarta">Properties</span>
          </a>
          <a
            href="/bookings"
            onClick={() => setActiveLink("bookings")}
            className={`w-full lg:p-3 p-1 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'bookings' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
          >
            <BookOnlineOutlinedIcon className="lg:text-[14px] text-[12px]" />
            <span className="ml-2 lg:text-[14px] text-[12px] font-jakarta">Bookings</span>
          </a>
          <a
            href="/wallet"
            onClick={() => setActiveLink("wallet")}
            className={`w-full lg:p-3 p-1 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'wallet' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
          >
            <AccountBalanceWalletOutlinedIcon className="lg:text-[14px] text-[12px]" />
            <span className="ml-2 lg:text-[14px] text-[12px] font-jakarta">Wallet</span>
          </a>
          <a
            href="/profile"
            onClick={() => setActiveLink("profile")}
            className={`w-full lg:p-3 p-1 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'profile' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
          >
            <PersonIcon className="lg:text-[14px] text-[12px]" />
            <span className="ml-2 lg:text-[14px] text-[12px] font-jakarta">Profile</span>
          </a>
        </div>
      </div>
      <div className="mb-4">
        <a
          href="/support"
          onClick={() => setActiveLink("support")}
          className={`w-full lg:p-3 p-1 rounded-[10px] text-[14px] flex items-center ${activeLinkLocal === 'support' ? 'bg-[#DEA838] text-[black]' : 'hover:bg-[#1E1E1E] hover:text-[#FCFCFC]'}`}
        >
          <HelpOutlineOutlinedIcon className="lg:text-[14px] text-[12px]" />
          <span className="ml-2 lg:text-[14px] text-[12px] font-jakarta">Support</span>
        </a>
      </div>
    </div>
  );
};

export default SideBar;
