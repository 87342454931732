import React, { useState } from "react";
import Wrapper from "../../components/Wrapper";
import TopBar from "../../components/topNav";
import axios from "../../utils/index";
import {
  setApplicationLoan,
  setStatus,
} from "../../services/applicationLoanSlice";
import {
  Typography,
  Button,
  Divider,
  TextField,
  Box,
  InputLabel,
  ListItemIcon,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Popover,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PortfolioOverview from "./PorfolioOverview";
import Cookies from "js-cookie";

const Loan = () => {
  // const { data: loanData, status: loanDataStatus } = useSelector(
  //   (state: any) => state.loan
  // );
  const { status: loanDataStatus } = useSelector((state: any) => state.loan);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [loanHistory, setLoanHistory] = useState([]);
  const [amount, setAmount] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    getLoanHistory();
  };

  const user = Cookies.get("userData")
  const currentUser = user && JSON.parse(user)
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    // console.log(newValue);
    setAmount(newValue);
  };
  const getLoanHistory = async () => {
    setLoading(true);
    try {
      
      const response = await axios.get("https://maihomminvest.com/api/user/portfolio/loans/history");
      // console.log(response.data.data);
      setLoanHistory(response.data.data.data);
    } catch (err: any) {
      console.log(err);
      toast.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const createApplicationLoan = async () => {
    dispatch(setStatus("loading"));
    let newForm = new FormData();
    newForm.append('amount', `${amount}`)
    try {
      const response = await axios.post("https://maihomminvest.com/api/user/portfolio/loans/apply", newForm, {headers: {Authorization: `Bearer${currentUser?.token}`, accept: "application/json"}});
      dispatch(setApplicationLoan(response.data));
      dispatch(setStatus("idle"));
    } catch (err: any) {
      dispatch(setStatus("error"));
      console.log(err);
      const error_message = err.response.data.message;
      toast.error(error_message);
      return err.message;
    }
  };

  const handleApplicationLoan = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createApplicationLoan();
  };

  const open = Boolean(anchorEl);
  const id = open ? "loan-history-popover" : undefined;

  return (
    <Wrapper>
      {/* <PortfolioOverview investments={investments}/> */}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: "100px" }}
      >
        <Box>
          <Typography variant="h5">Apply for Loan</Typography>
        </Box>
        <Box ml={2}>
          <Button
            variant="contained"
            endIcon={<NavigateNextIcon />}
            onClick={handleClick}
            style={{
              backgroundColor: "#272B30",
              color: " #B6B7B9",
              border: "2px solid #272B30",
              borderRadius: "10px",
            }}
          >
            See Loan History
          </Button>
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List>
          {loading ? (
            <CircularProgress size={24} style={{ margin: "20px" }} />
          ) : !loading && loanHistory.length < 1 ? (
            <p
              style={{
                margin: "20px 0",
                textAlign: "center",
                fontSize: "14px",
                padding: "20px",
                color: "red",
              }}
            >
              No Loan record found
            </p>
          ) : (
            loanHistory.map((loan: any, idx) => (
              <ListItem key={idx}>
                <ListItemIcon>
                  <span
                    style={{
                      display: "block",
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor: "yellow",
                    }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="h4">{loan.amount}</Typography>
                  <Typography variant="body1">
                    Loan Date: {loan.created_at}
                  </Typography>
                  <Typography variant="body1">
                    Repayment Date: 23 Aug 2021
                  </Typography>
                </ListItemText>
                <Box ml={2}>
                  <Button variant="contained" color="primary">
                    {loan.status}
                  </Button>
                </Box>
              </ListItem>
            ))
          )}

          <Divider />
        </List>
      </Popover>

      <Box style={{ marginTop: "50px" }}>
        <InputLabel shrink htmlFor="loan-amount">
          Amount to Loan
        </InputLabel>

        <form onSubmit={handleApplicationLoan}>
          <TextField
            placeholder="Enter the amount you want to loan"
            value={amount !== undefined ? amount : ""}
            onChange={handleAmountChange}
            fullWidth
            margin="normal"
            type="number"
            InputProps={{ disableUnderline: true }}
          />
          <Typography variant="body2" color="textSecondary">
            Max. amount: You can only loan up to 50% of your investments.
          </Typography>
          <Button
            type="submit"
            disabled={loanDataStatus === "loading"}
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "50px" }}
          >
            {loanDataStatus === "loading" ? (
              <CircularProgress size={24} />
            ) : (
              "Apply"
            )}
          </Button>
        </form>
      </Box>
    </Wrapper>
  );
};
export default Loan;
