import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface DropdownProps {
  options: string[];
  label: string;
  selected: string;
  onChange: (value: string) => void;
  icon?: React.ReactNode; // Optional icon prop
}

const Dropdown = ({ options, label, selected, onChange, icon }: DropdownProps) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => setOpen(!open);

  const handleSelect = (option: string) => {
    onChange(option);
    setOpen(false);
  };

  return (
    <Box className="relative">
      <div
        onClick={handleToggle}
        className="flex items-center cursor-pointer border py-1 px-3 font-jakarta rounded-md border-[#6F767E] text-[14px] bg-[#1E1E1E]"
      >
        {icon && <span className="mr-2">{icon}</span>}
        <span className="flex-grow font-jakarta">{selected || label}</span>
        <IconButton size="small">
          <ExpandMoreIcon />
        </IconButton>
      </div>
      {open && (
        <ul className="absolute mt-1 w-[130%] font-jakarta border border-gray-400 rounded-md bg-black shadow-lg max-h-60 overflow-auto">
          {options.map((option) => (
            <li
              key={option}
              onClick={() => handleSelect(option)}
              className="p-2 font-jakarta cursor-pointer hover:bg-gray-200"
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </Box>
  );
};

export default Dropdown;
