import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";
import theme from "./theme";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";
import RouterConfig from "./routes/RouterConfig";
import { Provider, useSelector } from "react-redux";
import "./index.css";
import { store } from "./services/store";

function App() {
 
  return (
    
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ToastContainer />
          <CssBaseline />
          <BrowserRouter>
            <RouterConfig />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
  
  );
}

export default App;
