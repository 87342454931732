// src/components/DatePicker.tsx

import React, { useState, useRef, useEffect } from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, addMonths, subMonths } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { FaCalendarAlt } from 'react-icons/fa';

interface DatePickerProps {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({ selectedDate, onDateChange }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [tempDate, setTempDate] = useState<Date | null>(null);
  const [hasSelectedDate, setHasSelectedDate] = useState(false);
  const calendarRef = useRef<HTMLDivElement>(null);

  // Initialize the tempDate with the current selectedDate or current date
  useEffect(() => {
    if (!tempDate && selectedDate) {
      setTempDate(selectedDate);
    }
  }, [selectedDate, tempDate]);

  const handleDateClick = (date: Date) => {
    setTempDate(date);
  };

  const handlePrevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const startOfMonthDate = startOfMonth(currentMonth);
  const endOfMonthDate = endOfMonth(currentMonth);
  const daysInMonth = eachDayOfInterval({ start: startOfMonthDate, end: endOfMonthDate });
  const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  const handleSet = () => {
    if (tempDate) {
      onDateChange(tempDate);
      setHasSelectedDate(true);  // Mark date as set
      setIsCalendarVisible(false);
    }
  };

  const handleCancel = () => {
    setTempDate(null);
    setIsCalendarVisible(false);
  };

  // Close the calendar if clicked outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
        setIsCalendarVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block w-full">
      <div
        className={`flex flex-row gap-2 border p-4 rounded-[10px] items-center cursor-pointer ${hasSelectedDate ? 'border-[#DEA838]' : 'border-[#515151]'}`}
        onClick={() => setIsCalendarVisible(!isCalendarVisible)}
      >
        <button className="flex items-center justify-center p-2 rounded-full">
          <FaCalendarAlt className="text-gray-200 text-[20px]" />
        </button>
        <div>
          <p className='text-[14px]'>Check In</p>
          <span className="mt-2 text-white">
            {selectedDate ? format(selectedDate, 'MMMM d, yyyy') : 'Add Date'}
          </span>
        </div>
      </div>

      {isCalendarVisible && (
        <div
          ref={calendarRef}
          className="absolute w-[200%] bg-[#33383F] border border-gray-300 rounded-lg shadow-md mt-2 z-10"
        >
          <div className="flex items-center justify-between p-4">
            <button onClick={handlePrevMonth} className="text-white hover:text-gray-900">‹</button>
            <span className="text-lg font-semibold">
              {format(currentMonth, 'MMMM yyyy', { locale: enUS })}
            </span>
            <button onClick={handleNextMonth} className="text-white hover:text-gray-900">›</button>
          </div>
          <div className="grid grid-cols-7 text-center">
            {daysOfWeek.map((day) => (
              <div key={day} className="py-2 font-medium text-white">{day}</div>
            ))}
          </div>
          <div className="grid grid-cols-7 text-center">
            {eachDayOfInterval({ start: startOfMonthDate, end: endOfMonthDate }).map((date) => (
              <button
                key={date.toString()}
                onClick={() => handleDateClick(date)}
                className={`py-2 ${tempDate && format(date, 'd') === format(tempDate, 'd') ? 'bg-white text-black rounded-[50%]' : 'text-white'} hover:bg-gray-200 text-black rounded`}
              >
                {format(date, 'd')}
              </button>
            ))}
          </div>
          <div className="flex justify-between gap-3 p-2">
            <button
              onClick={handleCancel}
              className="px-4 py-2 bg-transparent text-[#DEA838] border border-[#DEA838]  w-[50%] rounded"
            >
              Cancel
            </button>
            <button
              onClick={handleSet}
              className="px-4 py-2 bg-[#DEA838] text-black w-[50%] rounded"
            >
              Set
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
