import React, { useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { FaCalendarAlt } from 'react-icons/fa';

interface ModalContentProps {
    close: () => void;
}

const ModalContent: React.FC<ModalContentProps> = ({ close }) => {
    const [currentStep, setCurrentStep] = useState(1); // Track the current step
    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    const handleProceedToPayment = () => {
        setCurrentStep(2); // Move to payment instructions
    };

    const handleNext = () => {
        setCurrentStep(3); // Move to upload payment proof
    };

    const handleSubmit = () => {
        setCurrentStep(4); // Move to success message
    };

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    return (
        <>
            {currentStep === 1 && (
                <div className="w-[30%] bg-[#1E1E1E] rounded-[10px] border border-[#6F767E] p-7">
                    <div className='flex justify-between bg-[#33383F] items-center p-5 rounded-[10px]'>
                        <div className='flex gap-1 items-center'>
                            <FaCalendarAlt className="text-gray-200 text-[20px]" />
                            <div>
                                <p className='font-jakarta'>Check in</p>
                                <p className='font-jakarta'>July 20</p>
                            </div>
                        </div>
                        <div className='border-l border-gray-500 h-9 mx-4' />
                        <div className='flex gap-1 items-center'>
                            <FaCalendarAlt className="text-gray-200 text-[20px]" />
                            <div>
                                <p className='font-jakarta'>Check out</p>
                                <p className='font-jakarta'>July 25</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className='text-center text-[25px] font-jakarta py-5'>Price Summary</p>
                        <div className='flex px-1 justify-between mb-5'>
                            <p className='text-[#9A9FA5] font-jakarta'>£145 x 6 nights</p>
                            <p className='text-white font-jakarta'>£870</p>
                        </div>
                        <div className='flex px-1 justify-between mb-5'>
                            <p className='text-[#9A9FA5] font-jakarta'>Service</p>
                            <p className='text-white font-jakarta'>£00.00</p>
                        </div>
                        <div className='flex justify-between bg-[#33383F] py-4 px-1 rounded-[10px]'>
                            <p className='flex gap-1 font-jakarta'>Total <p className='text-[#9A9FA5]'>GBP</p></p>
                            <p className='font-jakarta'>£00.00</p>
                        </div>
                    </div>
                    <div className='w-full my-[30px]'>
                        <button
                            onClick={handleProceedToPayment}
                            className="bg-[#DEA838] font-jakarta text-black p-4 w-full rounded"
                        >
                            Proceed to payment
                        </button>
                    </div>
                </div>
            )}

            {currentStep === 2 && (
                <div className="w-[30%] bg-[#1E1E1E] rounded-[10px] border border-[#6F767E] p-7">
                    <div className='flex flex-row-reverse justify-between'>
                        <MdOutlineClose onClick={close} className='flex justify-end cursor-pointer' />
                    </div>
                    <p className="text-center text-white font-jakarta text-[20px] mt-5 pb-[10px]">
                        Please pay using any of the following
                    </p>
                    <hr className="border-t-[1px] border-[#33383F] pb-[10px]" />
                    <div className='flex flex-col gap-5'>
                        <div className='flex flex-row justify-between'>
                            <p className='text-[13px] font-jakarta text-[#92989e]'>Account number</p>
                            <p className='text-[14px] font-jakarta'>Maihomm management limited</p>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <p className='text-[13px] font-jakarta text-[#92989e]'>Bank Name</p>
                            <p className='text-[14px] font-jakarta'>Zenith Bank</p>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <p className='text-[13px] font-jakarta text-[#92989e]'>Naira Account Number</p>
                            <p className='text-[14px] font-jakarta'>1226902217</p>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <p className='text-[13px] font-jakarta text-[#92989e]'>GBP Account Number</p>
                            <p className='text-[14px] font-jakarta'>1226902217</p>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <p className='text-[13px] font-jakarta text-[#92989e]'>USD Account Number</p>
                            <p className='text-[14px] font-jakarta'>1226902217</p>
                        </div>
                        <p className='text-start text-[#DEA838] text-[14px]'>Or</p>
                        <div className='flex flex-col gap-5'>
                            <div className='flex flex-row justify-between'>
                                <p className='text-[13px] font-jakarta text-[#92989e]'>Bank Name</p>
                                <p className='text-[14px] font-jakarta'>1226902217</p>
                            </div>
                            <div className='flex flex-row justify-between'>
                                <p className='text-[13px] font-jakarta text-[#92989e]'>GBP Account Number</p>
                                <p className='text-[14px] font-jakarta'>78740715</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center items-center w-full mt-5'>
                        <button
                            className='bg-[#DEA838] px-[35px] font-jakarta rounded-[10px] w-full py-[8px] font-[700] text-[14px] text-black'
                            onClick={handleNext}
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}

            {currentStep === 3 && (
                <div className="w-[30%] bg-[#1E1E1E] rounded-[10px] border border-[#6F767E] p-7">
                    <div className='flex flex-row-reverse justify-between'>
                        <MdOutlineClose onClick={close} className='flex justify-end cursor-pointer' />
                    </div>
                    <p className='text-[20px] text-start pb-[10px] font-jakarta'>Upload Payment Proof</p>
                    <hr className="border-t-[1px] border-[#33383F] pb-[10px]" />
                    <p className='text-start py-[4px] text-[14px] font-jakarta'>Select File(s)</p>
                    <label className="flex flex-col gap-3 py-4 items-center justify-center w-full border-2 border-[#33383F] bg-[#1E1E1E] rounded-lg">
                        <div className="flex flex-col items-center justify-center">
                            <div className='flex flex-row gap-5 items-center border-[#33383F] bg-[#1E1E1E]'>
                                <p className="text-[16px] text-white mb-2">
                                    {file ? (
                                        <div className="mt-4">
                                            {file.type.includes('image/') ? (
                                                <img
                                                    src={preview}
                                                    alt="Uploaded File"
                                                    className="max-w-full h-[10vh] rounded-lg"
                                                />
                                            ) : (
                                                <p className="text-white font-jakarta">File type not supported for preview.</p>
                                            )}
                                        </div>
                                    ) : (
                                        <p className='font-jakarta'>No file chosen</p>
                                    )}
                                </p>
                                <p className="rounded-[5px] font-jakarta text-[14px] border border-[#8c8b8a] py-[6px] px-[35px] cursor-pointer">
                                    Browse
                                </p>
                            </div>
                        </div>
                        <input
                            type="file"
                            className="hidden"
                            onChange={handleChanges}
                        />
                    </label>
                    <div className='flex justify-center items-center w-full mt-5'>
                        <button
                            className='bg-[#DEA838] px-[35px] font-jakarta rounded-[10px] w-full py-[8px] font-[700] text-[14px] text-black'
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            )}

            {currentStep === 4 && (
                <div className="w-[30%] bg-[#1E1E1E] rounded-[10px] border border-[#6F767E] p-7">
                     <div className="flex flex-col text-center font-jakarta align-middle items-center justify-center">
                <img src="/assets/images/success.svg" className="h-[70px] w-[70px] mb-[10px]" />

                <p className="text-[30px] font-[600] font-jakarta w-[60%]">Bookings</p>
                <p className="text-[30px] font-[600] font-jakarta w-[60%]">Successfully</p>
                <p className="text-[#9A9FA5] font-jakarta text-[14px] w-[80%]">
                Your card has been added successfully, we may charge a small amount to verify the card
                </p>
                <button className="my-[30px] font-jakarta py-[12px] w-full font-[700] rounded-[10px] px-[20px] bg-[#DEA838] text-black"
                    onClick={() => navigate("/overview")}
                >
                    Done
                </button>
            </div>
                </div>
            )}
        </>
    );
};

export default ModalContent;
