import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { authImage, logo } from "../../assets";
import { PiUserSquareFill } from "react-icons/pi";
import '../../App.css';
import UserSignUp from './UserSignUp';
import UserSignup from './UserSignUp2';
const Register: React.FC = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const handleSignUp = () => {
    if (!selectedOption) {
      setErrorMessage('Please select an option to continue.');
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
    } else {
      setErrorMessage(null);
      setFormSubmitted(true);
    }
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: `url(${authImage})` }}>
      <div className="absolute inset-0 bg-black opacity-90"></div>
      <div className="relative flex flex-col md:flex-row w-full max-w-6xl p-4 bg-transparent rounded-lg shadow-lg">
      <div className="flex flex-col justify-center w-full md:w-1/2 text-white p-4">
          <div className="flex justify-center md:absolute md:top-5 md:justify-start mb-10">
            <img src={logo} alt="logo" className="h-14 w-56 mb-4 md:mb-0" />
          </div>
          <h1 className="text-[24px] md:text-[42px] font-[600] w-full md:w-[90%] font-jakarta leading-[30px] md:leading-[45px] mb-4">Are my funds and asset safe at Maihomm?</h1>
          <p className="text-[14px] md:text-[16px] font-[400] w-full md:w-[83%] font-jakarta tracking-wider mb-8">All clients funds and co-owned properties are held in Trust by Maihomm Trustees, a UK registered trust and regulated by the HMRC.</p>
        </div>
        <div className="flex items-center justify-center w-full md:w-2/3 ml-auto">
          {!formSubmitted ? (
            <div className="w-full">
              <form className="w-full py-[50px] px-[100px] border border-[#6F767E] rounded-[30px] shadow-lg" style={{ background: "linear-gradient(135deg, #1B1818 0%, #1B1818 100%)" }}>
                <div className='flex flex-col items-center w-full justify-center'>
                  <p className='text-[20px] md:text-[36px] font-jakarta font-[600]'>Welcome to Maihomm!</p>
                  <p className='text-[#9A9FA5] font-[400] mb-4 font-jakarta text-[12px] md:text-[14px] text-center'>You can choose to continue as a guest to access only the booking feature, or log in/sign up for full access.</p>
                </div>
                <div className='flex justify-center gap-5'>   
                  <div
                    onClick={() => setSelectedOption('coowner')}
                    className={`w-full flex flex-col gap-3 items-center p-[30px] justify-center text-center border rounded-[10px] cursor-pointer ${selectedOption === 'coowner' ? 'border-yellow-500' : 'border-[#6F767E]'}`}
                  >
                    <img src='/assets/images/Vector.svg' className='text-center h-[32px] w-[32px]' />
                    <p className={`text-[16px] font-[700] font-jakarta ${selectedOption === 'coowner' ? 'text-yellow-500' : ''}`}>Co-owner Sign up</p>
                    <p className={`text-[12px] font-[400] font-jakarta ${selectedOption === 'coowner' ? 'text-yellow-500' : ''}`}>I would like to co-own a property</p>
                  </div>
                  <div
                    onClick={() => setSelectedOption('guest')}
                    className={`w-full flex flex-col gap-3 items-center p-[30px] font-jakarta justify-center text-center border rounded-[10px] cursor-pointer ${selectedOption === 'guest' ? 'border-yellow-500' : 'border-[#6F767E]'}`}
                  >
                    <PiUserSquareFill className={`${selectedOption === 'guest' ? 'text-yellow-500' : ''} h-[32px] w-[32px]`}/>

                    <p className={`text-[16px] font-[700] font-jakarta ${selectedOption === 'guest' ? 'text-yellow-500' : ''}`}>Guest Sign up</p>
                    <p className={`text-[12px] font-[400] font-jakarta ${selectedOption === 'guest' ? 'text-yellow-500' : ''}`}>I want to book an apartment</p>
                  </div>
                </div>
                {errorMessage && (
                  <div className="text-red-500 font-jakarta text-center mt-4">
                    {errorMessage}
                  </div>
                )}
                <div className='pt-[30px]'>
                  <button type="button" onClick={handleSignUp} className='w-full font-jakarta bg-[#DEA838] border border-[#dea838] rounded-[10px] text-black font-[700] flex justify-center py-[12px]'>Sign Up</button>
                </div>
                <div className='flex justify-center py-[30px]'>
                  <p className='text-[#9A9FA5] text-[14px] font-jakarta'>Don’t have an account?<span className='text-[#dea838] text-[14px] cursor-pointer' onClick={() => navigate("/auth/login")}> Log in</span></p>
                </div>
              </form>
            </div>
          ) : (
            <div className="w-full custom-scroll-content font-jakarta p-4 border border-[#6F767E] rounded-[30px] shadow-lg"  style={{ overflowY: 'auto', WebkitOverflowScrolling: 'touch', background: "linear-gradient(135deg, #1B1818 0%, #1B1818 100%)" }}>
              <div className="flex flex-col w-full px-[60px] py-[30px]  justify-center text-white">
               
                <div className="">
                  {selectedOption === 'guest' ? (
                    <>
                   <UserSignUp />
                    </>
                  ) : (
                    <>
                  
                    <UserSignup />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;

export {}; // Add this line to make the file a module
