import { useState, useEffect } from "react";

import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  InputLabel,
  Modal,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import PaymentsIcon from "@mui/icons-material/Payments";
import Wrapper from "../../components/Wrapper";
import { reward } from "../../assets";
import TopBar from "../../components/topNav";
import { userRequest } from "../../requestMethod";
import { formatPrice } from "../../utils/helpers";
import DividerLine from "../../utils/DividerLine";
import Cookies from "js-cookie";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Rewards = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [userReward, setUserReward] = useState<any>(null);
  const [userReferralLink, setUserReferralLink] = useState("");
  const [hasCopied, setHasCopied] = useState(false);

  useEffect(() => {
    const res = Cookies.get("userData") || "";
    if (res) {
      let userData = JSON.parse(res);
      // console.log(window.location.origin+`/auth/register?user=${userData.first_name.toLowerCase()+userData.last_name.toLowerCase()}&id=${userData.id}`)
      setUserReferralLink(
        window.location.origin +
          `/auth/register?user=${
            userData.first_name.toLowerCase() + userData.last_name.toLowerCase()
          }&id=${userData.id}`
      );
    }
  }, []);

  useEffect(() => {
    const getUserRewards = async () => {
      try {
        const res = await userRequest("/user/rewards");
        setUserReward(res.data.data);
        // console.log(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getUserRewards();
  }, []);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleCopyReferralLink = () => {
    navigator.clipboard.writeText(userReferralLink);
    setHasCopied(true);
    setTimeout(() => {
      setHasCopied(false);
    }, 3000);
  };

  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Card style={{ padding: "20px", height: "100%" }}>
            <CardContent>
              <Typography variant="h6">Total rewards Earned</Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 16,
                }}
              >
                <Typography variant="body1" fontSize={35} fontWeight={600}>
                  {formatPrice(0)}
                </Typography>
              </div>
            </CardContent>

            <Card variant="outlined" style={{ marginTop: 16 }}>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PeopleIcon style={{ marginRight: 8 }} />
                    <Typography variant="subtitle1">Referral</Typography>
                  </div>
                  <Typography variant="h5">{userReward?.referrals}</Typography>
                </div>
              </CardContent>
            </Card>

            <Card variant="outlined" style={{ marginTop: 16 }}>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PaymentsIcon style={{ marginRight: 8 }} />
                    <Typography variant="subtitle1">Bonus Rewards</Typography>
                  </div>
                  <Typography variant="body1" fontSize={23} fontWeight={600}>
                    {formatPrice(0)}
                  </Typography>
                </div>
              </CardContent>
            </Card>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: 16, backgroundColor: "#fff" }}
            >
              Redeem All
            </Button>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          md={5}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Card
            variant="outlined"
            style={{ marginBottom: 16 }}
            onClick={handleModalOpen}
          >
            <CardContent>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LocalActivityIcon style={{ marginRight: 8 }} />
                  <Typography variant="subtitle1">Habitable days</Typography>
                </div>
                {userReward && (
                  <Typography variant="h5">
                    {userReward?.habitable_days} days
                  </Typography>
                )}
              </div>
            </CardContent>
          </Card>

          <Card variant="outlined" sx={{ flex: 1 }}>
            <CardContent>
              <Typography variant="h6">Refer and Earn</Typography>
              <Typography
                variant="body1"
                sx={{ mt: 0.8, color: "#B6B7B9", fontSize: "1rem" }}
              >
                Get your unique referral link to refer others to use Maihomm and
                earn rewards.
              </Typography>
              <DividerLine sx={{ mt: 2.4, mb: 2.4 }} />
              <InputLabel style={{ marginTop: 8 }}>Share your link</InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                value={userReferralLink}
                style={{ marginTop: 16 }}
              />

              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: 16 }}
                onClick={handleCopyReferralLink}
              >
                <Typography variant="subtitle2" sx={{mt: .2, mr: .5}}>{hasCopied ? "Copied" : "Copy"}</Typography>
                {hasCopied && <CheckCircleOutlineIcon/> }
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Modal open={modalOpen} onClose={handleModalClose}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 4,
              outline: "none",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "400px",
              maxWidth: "90%",
            }}
          >
            <img
              src={reward}
              alt="Cashback"
              style={{ width: "100px", marginBottom: "16px" }}
            />
            <Typography variant="subtitle1">Cashback</Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{ width: "300px", marginBottom: "16px" }}
            >
              Get cashback rewards for habitable days that have not been used.
            </Typography>
            <Button variant="contained" color="primary" fullWidth>
              Claim rewards
            </Button>
          </Card>
        </Modal>
      </Grid>
    </Wrapper>
  );
};

export default Rewards;
