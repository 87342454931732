import { useEffect, useState } from "react";
import { formatPrice } from "../../utils/helpers";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { LuMoreVertical } from "react-icons/lu";
import { Link } from "react-router-dom";
import { IoMdArrowUp } from "react-icons/io";

interface InvestmentProps {
  investments: any; // Allows flexibility, though more specific types are preferred if possible
}

const PendingInvestments: React.FC<InvestmentProps> = ({ investments }: any) => {
  const [investment, setInvestments] = useState<any[]>([]);
  const [popupVisible, setPopupVisible] = useState<null | number>(null);

  useEffect(() => {
    if (investments) {
      // Filter investments with status 'Pending'
      const pendingInvestments = investments.investments?.filter((inv: any) => inv?.status === "Pending");
      setInvestments(pendingInvestments);
    }
  }, [investments]);

  const togglePopup = (index: number) => {
    if (popupVisible === index) {
      setPopupVisible(null); // Hide the popup if it's already open
    } else {
      setPopupVisible(index); // Show the popup for the clicked item
    }
  };

  return (
    <div className="py-[30px]">
      <div className="lg:w-[90%] w-full bg-black shadow-md rounded-lg overflow-x-auto">
        <table className="lg:w-full w-[250%] border-collapse">
          {/* Table Header */}
          <thead>
            <tr className="border-b border-[#9A9FA5] w-full">
              {["PROPERTY", "LOCATION", "INVESTMENT AMOUNT", "EXPECTED RENT", ""].map((header, index) => (
                <th key={index} className="p-3 font-[500] text-[14px] font-jakarta text-[#9A9FA5] text-left">
                  {header}
                </th>
              ))}
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {investment?.map((inv: any, idx: number) => (
              <tr key={idx}>
                <td className="p-3 flex items-center gap-2">
                  <div className="w-16 h-16 overflow-hidden rounded-md">
                    <img
                      src={inv.property.default_image?.url || ""}
                      alt={inv.property.name || ""}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <p className="text-[12px] w-[50px] font-jakarta">{inv.property.name || ""}</p>
                </td>
                <td className="p-3 text-[12px] font-jakarta">{inv.property.location.address || ""}</td>
                <td className="p-3 text-[12px] font-jakarta">{formatPrice(inv.investment_cost) || ""}</td>
                <td className="p-3">
                  <p className="mr-2 bg-transparent rounded-md text-[12px] flex font-jakarta">
                    <span className="bg-gray-600 p-2 rounded-[5px]">
                      {formatPrice(inv.projected_annual_net_rental_income) || ""}
                    </span>
                    <span className="flex text-[green] items-center">
                      <IoMdArrowUp className="text-[green]" />
                      58.3
                    </span>
                  </p>
                </td>
                <td className="p-3 relative">
                  <button onClick={() => togglePopup(idx)} className="py-1 px-3">
                    <LuMoreVertical />
                  </button>
                  {popupVisible === idx && (
                    <div className="absolute top-14 right-4 bg-[#1E1E1E] border border-[#6F767E] shadow-md rounded-md py-2 px-4 z-10 min-w-[150px]">
                      <Link to={`/sell/${inv?.property?.id}`}>
                        <div className="block text-left p-2 w-full text-[12px] cursor-pointer font-jakarta">View Property</div>
                      </Link>
                      <hr className="border-t-[1px] border-[#33383F]" />
                      {/* <Link to={`/sell/${inv.property_id}`}>
                        <div className="block text-left p-2 w-full text-[12px] font-jakarta">Sell Property</div>
                      </Link> */}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PendingInvestments;
