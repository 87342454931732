import { useState } from "react";
import { Box, Modal, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";

const PhotoSlider = (props) => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);
console.log(props, "image")
  const handleOpen = (i) => {
    setSlideNumber(i);
    setOpen(true);
  };

  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber =
        slideNumber === 0 ? props?.images?.length - 1 : slideNumber - 1;
    } else {
      newSlideNumber =
        slideNumber === props?.images?.length - 1 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  };
  const handleClose = () => setOpen(false);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          {open && (
            <Box
              sx={{
                position: "sticky",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.613)",
                zIndex: 999,
                display: "flex",
                alignItems: "center",
              }}
            >
              <CloseIcon
                onClick={() => setOpen(false)}
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  fontSize: "30px",
                  color: "lightgray",
                  cursor: "pointer",
                }}
              />
              <KeyboardArrowLeftIcon
                onClick={() => handleMove("l")}
                style={{
                  margin: "20px",
                  fontSize: "50px",
                  color: "lightgray",
                  cursor: "pointer",
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={props?.images[slideNumber]?.url}
                  alt=""
                  style={{
                    width: "80%",
                    height: "80vh",
                  }}
                />
              </Box>
              <KeyboardArrowRightIcon
                onClick={() => handleMove("r")}
                style={{
                  margin: "20px",
                  fontSize: "50px",
                  color: "lightgray",
                  cursor: "pointer",
                }}
              />
            </Box>
          )}
        </div>
      </Modal>

      <Box>
        <Grid container spacing={2}>
          <Grid item xs={8} sx={{ minHeight: "100%" }}>
            <img
              onClick={() => handleOpen(0)}
              src={props?.images[slideNumber]?.url}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
                borderRadius: 10,
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              position: "relative",
            }}
          >
            {props.images.length >= 2 && (
              <img
                onClick={() => handleOpen(1)}
                src={props?.images[slideNumber]?.url}
                alt=""
                style={{
                  width: "100%",
                  height: "50%",
                  cursor: "pointer",
                  borderRadius: 10,
                }}
              />
            )}
            {props.images.length >= 3 && (
              <img
                onClick={() => handleOpen(2)}
                src={props?.images[slideNumber]?.url}
                alt=""
                style={{
                  width: "100%",
                  height: "50%",
                  cursor: "pointer",
                  borderRadius: 10,
                }}
              />
            )}
            {props.images.length >= 4 && (
              <Box
                onClick={() => handleOpen(2)}
                sx={{
                  position: "absolute",
                  bottom: "5px",
                  right: "5px",
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  backgroundColor: "#1e1e1e9b",
                  px: 2,
                  py: 1,
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                <InsertPhotoOutlinedIcon style={{ color: "#DEA838" }} />
                <Typography variant="subtitle2">View all photos</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PhotoSlider;
