import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { logo, authImage } from "../../assets";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { publicRequest } from "../../requestMethod";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const StyledInput = styled.input`
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const EmailVerification: React.FC = () => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [errs, setErrs] = useState("");
  const [timeLeft, setTimeLeft] = useState(10 * 60); // 10 minutes in seconds
  const userData = useSelector((state: any) => state.login.userData);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  const navigate = useNavigate();
  const handleVerifyEmail = async () => {
    if (!otp) {
      setErrs("You need to input the OTP from your mail.");
      setTimeout(() => {
        setErrs("");
      }, 2000);
      return;
    }
  
    setLoading(true);
    setErrs(""); // Clear error if OTP is provided
    try {
      const response = await publicRequest.post(
        `/verify-email-otp/${userData.data.id}`,
        { otp },
        {
          headers: {
            Authorization: `Bearer ${userData.data.token}`, // Add the token here
          },
        }
      );
  
      // console.log(response.data); // Handle success response
      toast.success("Email Verification Successful");
  
      // Wait 2 seconds before navigating
      setTimeout(() => {
        // Check the user's verification status and route accordingly
        if (userData.data.verification.id_card === false) {
          navigate("/auth/kyc");
        } else {
          navigate("/overview");
        }
      }, 2000); // 2 seconds delay
    } catch (error) {
      console.error(error); // Handle error response
      toast.error("Email Verification Failed");
      setTimeout(() => {
        setErrs("");
      }, 2000);
    } finally {
      setLoading(false);
    }
  };
  


  const handleResendOtp = async () => {
    setLoadings(true);
    try {
      const response = await publicRequest.post(
        `/resend-email-otp/${userData.data.id}`
      );
      console.log(response.data); // Handle success response
      toast.success("OTP resent successfully");
      setTimeLeft(10 * 60); // Reset timer for 10 minutes
    } catch (error) {
      console.error(error); // Handle error response
      toast.error("Failed to resend OTP");
    } finally {
      setLoadings(false);
    }
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    if (/^\d*$/.test(input) && input.length <= 6) {
      setOtp(input);
    }
  };

  const textFieldStyle = `
    text-[14px] mb-2 py-[15px] px-3 rounded-lg border border-[#33383F] outline-none bg-[#1E1E1E] w-full
  `;

  const focusedStyle = `
    text-white
  `;

  const errorStyle = `
    border-red-500
  `;

  const buttonStyle = `
    bg-[#DEA838] hover:bg-[#DEA838] text-black font-bold py-2 px-4 rounded w-full
  `;

  return (
    <div
      className="relative flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${authImage})` }}
    >
      <div className="absolute inset-0 bg-black opacity-90"></div>
      <div className="relative flex flex-col md:flex-row w-full max-w-6xl p-4 bg-transparent rounded-lg shadow-lg">
        <div className="flex flex-col justify-center w-full md:w-1/2 text-white p-4">
          <div className="flex justify-center absolute top-0 md:justify-start mb-10">
            <Link to={"/"}>
              <img src={logo} alt="logo" className="h-14 w-56 mb-4 md:mb-0" />
            </Link>
          </div>
          <h1 className="text-[24px] md:text-[42px] font-[600] w-full md:w-[90%] font-jakarta leading-[30px] md:leading-[45px] mb-4">
            Are my funds and assets safe at Maihomm?
          </h1>
          <p className="text-[14px] md:text-[16px] font-[400] w-full md:w-[83%] font-jakarta tracking-wider mb-8">
            All clients' funds and co-owned properties are held in Trust by
            Maihomm Trustees, a UK-registered trust and regulated by the HMRC.
          </p>
        </div>
        <div
          className="flex items-center pl-2 md:pl-[45px] py-4 md:py-[60px] rounded-[30px] border border-[#6F767E] justify-center w-full md:w-2/3 p-4"
          style={{ background: "linear-gradient(135deg, #1B1818 0%, #1B1818 100%)" }}
        >
          <div className="w-[79%]">
            <>
              <p className="text-[20px] md:text-[26px] font-[600]">Verify your email</p>
              <p className="text-[#9A9FA5] font-[400] mb-4 text-[12px] md:text-[14px]">
                We just sent you a verification code. Check your inbox to get it.
              </p>
              {timeLeft > 0 ? (
                <>
                  <div className="form-control mb-4">
                    <StyledInput
                      type="text"
                      name="otp"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={handleOtpChange}
                      className={`${textFieldStyle} ${errs ? errorStyle : "border-[#1B1818]"} ${otp && focusedStyle}`}
                    />
                    {errs && <p className="text-red-500 text-sm">{errs}</p>}
                  </div>
                  <button onClick={handleVerifyEmail} className={buttonStyle}>
                    {loading ? <CircularProgress size={24} color="inherit" /> : "Verify email"}
                  </button>
                  <div className="flex items-center justify-between mt-5 mb-[100px]">
                    <p className="text-[#9A9FA5] text-[14px]">
                      OTP expires in <span className="text-white">{formatTime(timeLeft)}</span> mins
                    </p>
                    <button
                      onClick={handleResendOtp}
                      disabled={loadings}
                      className={`${
                        loadings
                          ? "cursor-not-allowed"
                          : "border border-[#DEA838] rounded-[5px] text-[#DEA838] p-[7px] text-[14px]"
                      }`}
                    >
                      {loadings ? <CircularProgress size={24} color="inherit" /> : "Resend OTP"}
                    </button>
                  </div>
                </>
              ) : (
                <p className="text-red-500 text-[16px] font-[600]">The OTP has expired.</p>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
