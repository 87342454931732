import AdminWrapper from "../../components/AdminWrapper";
import { GrPowerForceShutdown } from "react-icons/gr";
import { useState, useEffect } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { publicRequest, userRequest } from "../../requestMethod";
interface FirstCardProps {
    data: any;  // Accept any data structure
  }
  const FirstCard: React.FC<FirstCardProps> = ({ data }) => {
    const initialCurrency = localStorage.getItem("selectedCurrency") || "£";
    const [currency, setCurrency] = useState<string>(initialCurrency);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    useEffect(() => {
        localStorage.setItem("selectedCurrency", currency);
    }, [currency]);

    const handleCurrencyChange = (selectedCurrency: string) => {
        setCurrency(selectedCurrency);
        setIsDropdownOpen(false);
    };
  

 
    return (

        <div className="flex flex-col lg:w-[90%] w-full lg:flex-row gap-4">
            <div className="w-full flex flex-col gap-4 lg:w-1/2 bg-[#1E1E1E] rounded-[10px] px-4 py-2 justify-center">
                <div className="flex justify-between items-center">
                    <div className="flex justify-between gap-2 items-center">
                        <p className="text-[#9A9FA5] text-[14px] font-jakarta">Completed payments</p>
                        <GrPowerForceShutdown className="text-[#9A9FA5]" />
                    </div>

                    <div>
                        <div className="relative inline-block text-left">
                            <div>
                                <button
                                    type="button"
                                    className="border border-[#6F767E] text-white px-[10px] py-[7px] flex justify-between gap-3 items-center rounded"
                                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                >
                                    {currency}
                                    <MdOutlineKeyboardArrowDown
                                        className={`transform ${isDropdownOpen ? "rotate-180" : ""}`}
                                    />
                                </button>
                            </div>

                            {isDropdownOpen && (
                                <div className="absolute right-0 mt-2 w-20 z-10 rounded-md shadow-lg bg-[black] ring-1 ring-black ring-opacity-5">
                                    <div className="py-1">
                                        <div
                                            className="block px-4 py-2 text-white font-jakarta cursor-pointer"
                                            onClick={() => handleCurrencyChange("£")}
                                        >
                                            £
                                        </div>
                                        <div
                                            className="block px-4 py-2 font-jakarta text-white cursor-pointer"
                                            onClick={() => handleCurrencyChange("$")}
                                        >
                                            $
                                        </div>
                                        <div
                                            className="block px-4 py-2 font-jakarta text-white cursor-pointer"
                                            onClick={() => handleCurrencyChange("€")}
                                        >
                                            €
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex justify-between">
                    <div className="flex justify-between items-center">
                        <p className="text-[30px] font-jakarta">{currency} {data?.wallet_balance}</p>
                    </div>

                    <div>
                        <div className="relative inline-block text-left">
                            <div>
                                <button
                                    type="button"
                                    className="border border-[#33383F] text-[#9A9FA5] px-[20px] py-[10px] rounded"
                                >
                                    <IoIosArrowForward />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col gap-6 lg:w-1/2 bg-[#1E1E1E] rounded-[10px] px-4 py-2 justify-center">
                <div className="flex justify-between">
                    <div className="flex justify-start gap-2 items-center">
                        <p className="text-[#9A9FA5] text-[14px] font-jakarta">Active Investment</p>
                        <GrPowerForceShutdown className="text-[#9A9FA5]" />
                    </div>


                </div>

                <div className="flex justify-between">
                    <div className="flex justify-between items-center">
                        <p className="text-[30px] font-jakarta">{data?.active_investment}</p>
                    </div>

                    <div>
                        <div className="relative inline-block text-left">
                            <div>
                                <button
                                    type="button"
                                    className="border border-[#33383F] text-[#9A9FA5] px-[20px] py-[10px] rounded"
                                >
                                    <IoIosArrowForward />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FirstCard;
