import React from "react";
import FirstCard from "./FirstCard";
import FutureProperty from "./InvestmentOpp";

interface InvestmentProps {
  investments: any; // Allows flexibility, though more specific types are preferred if possible
}

const PortfolioOverview: React.FC<InvestmentProps> = ({ investments }) => {
  return (
    <div className="">
      <FirstCard investments={investments} />
      <FutureProperty investments={investments} />
    </div>
  );
};

export default PortfolioOverview;
