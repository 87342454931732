import Wrapper from '../../components/Wrapper'

const Home = () => {
  return (
    <Wrapper>
        <div>Home Content Goes Here</div>
    </Wrapper>
  )
}

export default Home