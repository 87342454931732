import React, { useState } from 'react';

interface ProgressBarProps {
  value: number;
  maxValue?: number;
  onValueChange?: (value: number) => void; // Optional callback for value changes
}

const NumProgressBar: React.FC<ProgressBarProps> = ({ value, maxValue = 45, onValueChange }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [isDragging, setIsDragging] = useState(false);

  const percentage = (currentValue / maxValue) * 100;

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging) {
      const rect = event.currentTarget.getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const newPercentage = Math.min(Math.max(offsetX / rect.width, 0), 1); // Clamp between 0 and 1
      const newValue = Math.round(newPercentage * maxValue);
      setCurrentValue(newValue);
      if (onValueChange) onValueChange(newValue); // Trigger the callback if provided
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div
      className="lg:w-[40%] w-full mt-4"
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp} // Stop dragging if the mouse leaves the component
    >
      <div className="relative w-full h-1 bg-[#33383F] rounded-full">
        <div
          className="absolute top-[-3rem] left-0 text-[14px] rounded-[10px] text-black bg-white py-1 px-3"
          style={{ left: `${percentage}%` }}
        >
          {currentValue}
        </div>
        <div
          className="h-1 bg-[#FCFCFC] rounded-full"
          style={{ width: `${percentage}%` }}
        ></div>
        <div
          className="absolute top-[-0.5rem] h-5 w-5 bg-[#DEA838] border border-white rounded-full cursor-pointer"
          style={{ left: `${percentage}%`, transform: 'translateX(-50%)' }}
          onMouseDown={handleMouseDown}
        ></div>
      </div>
    </div>
  );
};

export default NumProgressBar;
