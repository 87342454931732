import React, { useState, useEffect } from 'react';
import { CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { publicRequest } from "../../requestMethod";
import { authImage, logo } from "../../assets";
import { toast } from "react-toastify";
import { BiError } from "react-icons/bi";
import { RiEyeCloseLine, RiEyeLine } from "react-icons/ri";
import { loginUserFailure, loginUserSuccess } from '../../services/loginSlice';

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.login.userData);



  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      dispatch(loginUserSuccess({ userData }));
    }
  }, [dispatch]);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email')?.toString() || '';
    const password = formData.get('password')?.toString() || '';
  
    setEmailError(!email);
    setPasswordError(!password);
  
    if (!email || !password) {
      setEmailError(!email);
      setPasswordError(!password);
  
      setTimeout(() => {
        setEmailError(false);
        setPasswordError(false);
      }, 2000);
  
      return;
    }
  
    try {
      setLoading(true);
      const response = await publicRequest.post("/login", {
        email,
        password,
      });
      const userData = response.data;
      dispatch(loginUserSuccess({ userData }));
      toast.success("Login Successful");
  console.log(userData?.data?.user_type, "data")
      // Check for email verification
      if (userData.data.email_verified === "no") {
        // Resend email verification OTP
        try {
         
          await publicRequest.post(`/resend-email-otp/${userData.data.id}`);
          toast.success("OTP has been resent. Please check your email.");
        } catch (otpError) {
          toast.error("Failed to resend OTP. Please try again.");
        }
        navigate("/email/verification");
      }
      // Check for ID card verification
      else if (userData.data.verification.id_card === false && userData.data.role === "Guest") {
        navigate("/auth/kyc");
      }
      // Check for 2FA
      else if (userData.data.settings.two_factor_auth === 1) {
        navigate("/auth/twofactor");
      }
      // If everything is verified, proceed to the overview page
      else {
       
        dispatch(loginUserSuccess({ userData }));
        toast.success("Login Successful");
        setTimeout(() => {
          if(userData?.data?.user_type === "Guest"){
            navigate("/bookings");

          } else {
            
            navigate("/overview");
          }
        }, 2000);
      }
    } catch (error) {
      let errorMessage = "Unknown error";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setMessages(errorMessage);
      setTimeout(() => {
        setMessages("");
      }, 4000);
      dispatch(loginUserFailure(errorMessage));
    } finally {
      setLoading(false);
    }
  };
  

  const textFieldStyle = {
    fontSize: "12px",
    backgroundColor: "#1E1E1E",
    borderRadius: "10px",
    marginTop: "5px",
    border: emailError || messages ? "1px solid #FF7070" : "2px solid #33383F",
    transition: "border-color 0.3s ease-out",
  };
  return (
    <div className="relative flex items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: `url(${authImage})` }}>
      <div className="absolute inset-0 bg-black opacity-90"></div>
      <div className="relative flex flex-col md:flex-row w-full max-w-6xl p-4 bg-transparent rounded-lg shadow-lg">
        <div className="flex flex-col justify-center w-full md:w-1/2 text-white p-4">
          <div className="flex justify-center md:absolute md:top-5 md:justify-start mb-10">
            <img src={logo} alt="logo" className="h-14 w-56 mb-4 md:mb-0" />
          </div>
          <h1 className="text-[24px] md:text-[42px] font-[600] w-full md:w-[90%] font-jakarta leading-[30px] md:leading-[45px] mb-4">Are my funds and asset safe at Maihomm?</h1>
          <p className="text-[14px] md:text-[16px] font-[400] w-full md:w-[83%] font-jakarta tracking-wider mb-8">All clients funds and co-owned properties are held in Trust by Maihomm Trustees, a UK registered trust and regulated by the HMRC.</p>
        </div>
        <div className="flex items-center justify-center w-full md:w-2/3 p-4">
          <form onSubmit={handleLogin} className="w-full pl-4 pr-4 md:pr-0 md:pl-[65px] py-4 md:py-[50px] rounded-[30px] border border-[#6F767E] shadow-lg" style={{ background: "linear-gradient(135deg, #1B1818 0%, #1B1818 100%)" }}>
            <div className='w-full md:w-[75%]'>
              <div className='flex flex-col md:flex-row justify-center'>
                {emailError || passwordError ? <BiError className="text-[#FF7070] font-jakarta text-sm mb-2 mr-2" /> : null}
                {emailError && <p className="text-[#FF7070] text-sm font-jakarta mb-2">Email is required</p>}
                {passwordError && <p className="text-[#FF7070] font-jakarta text-sm mb-2">Password is required</p>}
                <p className='text-[#FF7070] font-jakarta'>{message}</p>
                {
                  messages && (
                    <p className='text-[#FF7070] font-jakarta flex bg-[#FF7070] bg-opacity-30'><BiError className="text-[#FF7070] text-sm mb-2 mr-2" />{messages}</p>
                  )
                }
              </div>
              <h2 className="text-[20px] md:text-[36px] font-jakarta font-[600]">Log in to your account</h2>
              <p className="text-[#9A9FA5] font-[400] font-jakarta mb-4 text-[12px] md:text-[14px]">Welcome back! Sign into your account to continue</p>
              <div className="flex items-center justify-center py-[10px] px-[15px] rounded-[12px] mb-6 border cursor-pointer border-[#33383F]">
                <img src="/assets/images/Google.svg" alt="Google Login" className="mr-2" />
                <p className='text-[12px] font-jakarta md:text-[14px]'>Log in with Google</p>
              </div>
              <div className="flex items-center my-6 w-full">
                <div className="flex-grow border-t font-jakarta border-[#6F767E]"></div>
                <span className="mx-4 text-[14px] font-jakarta text-[#6F767E]">or log in with</span>
                <div className="flex-grow border-t font-jakarta border-[#6F767E]"></div>
            </div>
              <div className="mb-6">
                <label className="block text-[12px] md:text-[14px] font-jakarta font-[500] text-white">Email</label>
                <input
                  type="email"
                  placeholder='Enter your email address'
                  name="email"
                  className={`text-white bg-[#1E1E1E] rounded-[10px] font-jakarta outline-none mt-1 px-4 py-[12px] w-full ${emailError ? 'border-[#FF7070]' : 'border-[#33383F]'} ${messages ? "text-[#ff7070]" : ""}`}
                  style={textFieldStyle}
                />
              </div>
              <div className="relative mb-2">
                <label className="block text-[12px] font-jakarta md:text-[14px] font-[500] text-white">Password</label>
                <div style={textFieldStyle} className={`text-white flex bg-[#1E1E1E] rounded-[10px] ${passwordError ? 'border-[#FF7070] w-full' : 'border-[#33383F]'} ${messages ? "text-[#ff7070]" : ""}`}>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    className={`text-white bg-[#1E1E1E] font-jakarta rounded-[10px] outline-none mt-1 px-4 py-[12px] w-full`}
                  />
                  <button
                    type="button"
                    className="text-[#6F767E] font-jakarta text-[20px] pr-3"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <RiEyeCloseLine /> : <RiEyeLine />}
                  </button>
                </div>
              </div>
              <div className="flex justify-end w-full py-2 items-center text-[#dea838]">
                <Link to={"/auth/resetpassword"}>Forgot Password</Link>
              </div>
              <button
                type="submit"
                className={`w-full bg-[#DEA838] text-black py-3 px-4 rounded-[12px] font-jakarta ${loading ? "bg-[#DEA838]" : "bg-[#DEA838]"} ${loading ? "cursor-not-allowed" : "cursor-pointer"}`}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Log In"}
              </button>
              <p className="text-[#9A9FA5] font-jakarta text-[12px] md:text-[14px] text-center mt-4">Don't have an account? <a href="/auth/register" className="text-[#DEA838]">Sign up</a></p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
