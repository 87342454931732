import { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import SearchIcon from "@mui/icons-material/Search";
import Bproperties from "./Bproperties";
import { toast } from "react-toastify";
import Dropdown from "./Dropdown";
import FilterListIcon from "@mui/icons-material/FilterList";
import { LiaTimesSolid } from "react-icons/lia";
import axios from "axios"; // Assuming axios is used
import { useSelector } from "react-redux";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  content: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, content }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-[#2c2c2c] p-4 w-[30%] h-[30%] rounded-lg">
        <div className="flex justify-end cursor-pointer" onClick={onClose}>
          <LiaTimesSolid />
        </div>
        <p className="text-white font-jakarta">{content}</p>
      </div>
    </div>
  );
};

const Booking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState<any[]>([]);
  const [triggerReload, setTriggerReload] = useState(false);
  const [bookingsSearchWord, setBookingsSearchWord] = useState("");

  const [location, setLocation] = useState<string>("");
  const [propertyType, setPropertyType] = useState<string>("");
  const [priceRange, setPriceRange] = useState<string>("");
  const [amenities, setAmenities] = useState<string>("");
  const [filters, setFilters] = useState<string>("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const locationOptions = ["New York", "Los Angeles", "Chicago"];
  const propertyTypeOptions = ["Apartment", "House", "Condo"];
  const priceRangeOptions = ["Under $1000", "$1000-$2000", "Above $2000"];
  const amenitiesOptions = ["Pool", "Gym", "Parking"];
  const filtersOptions = ["Pet Friendly", "Smoking Allowed", "Wheelchair Accessible"];

  const [filteredProperties, setFilteredProperties] = useState<any[]>([]);
  const userData = useSelector((state: any) => state.login.userData);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const getProperties = async () => {
      try {
        setIsLoading(true);
        
        const res = await fetch(`${baseUrl}/user/bookings/history`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userData?.data?.token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!res.ok) {
          throw new Error('Error fetching properties');
        }
  
        const data = await res.json();
        console.log(data?.data?.data, "base")
        setProperties(data?.data?.data);
  
        // Ensure data is an array
      
      } catch (err) {
        toast.error("Error occurred fetching properties", {
          hideProgressBar: false,
          autoClose: 1000,
        });
      } finally {
        setIsLoading(false);
      }
    };
  
    getProperties();
  }, [triggerReload]);
  


 

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      setTriggerReload(!triggerReload);
    }
    setBookingsSearchWord(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
      const searchWord = (event.target as HTMLInputElement).value;
      const getSearchedProperties = async () => {
        try {
          const res = await axios.get(`https://api.maihomm.com/api/user/bookings/history?search=${searchWord}`, {
            headers: {
              Authorization: `Bearer ${userData?.data?.token}`,
            },
          });
          
          setProperties(res.data?.data);
          // Ensure data is an array
          if (Array.isArray(res.data?.data)) {
          } else {
            console.error("Unexpected data format:", res.data);
          }
        } catch (err) {
          toast.error("Error occurred fetching properties", {
            hideProgressBar: true,
            autoClose: 1000,
          });
        }
      };
      getSearchedProperties();
    }
  };

  const handleModalOpen = (content: string) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };


  return (
    <Wrapper>
      <div className="flex justify-between items-center mb-5">
        <div className="flex-grow max-w-[300px] relative">
          <input
            type="text"
            placeholder="Search..."
            value={bookingsSearchWord}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className="w-full px-3 py-2 border bg-transparent border-[#33383F] rounded-lg"
          />
          <div className="absolute top-0 right-0 mt-2 mr-3">
            <SearchIcon />
          </div>
        </div>
        <div className="flex gap-4">
          <button
            onClick={() => handleModalOpen("Habitable days: 2")}
            className="btn btn-outline-primary font-jakarta px-5 py-2 bg-[#1E1E1E] rounded-[10px] border border-[#6F767E]"
          >
            Habitable days: 2
          </button>
          <button
            onClick={() => handleModalOpen("Promo code: XYZ123")}
            className="btn btn-outline-primary font-jakarta px-5 py-2 bg-[#1E1E1E] rounded-[10px] border border-[#6F767E]"
          >
            Promo
          </button>
        </div>
        <a href="/bookings/history">
          <button className="btn btn-outline-primary font-jakarta px-3 py-2 bg-[#1E1E1E] rounded-[10px] border border-[#6F767E]">
            Booking History
          </button>
        </a>
      </div>

      {/* Dropdowns for filters */}
      <div className="mb-5 flex gap-4">
        <Dropdown
          label="Location"
          options={locationOptions}
          selected={location || "Location"}
          onChange={setLocation}
        />
        <Dropdown
          label="Type of Property"
          options={propertyTypeOptions}
          selected={propertyType || "Type of Property"}
          onChange={setPropertyType}
        />
        <Dropdown
          label="Price Range"
          options={priceRangeOptions}
          selected={priceRange || "Price Range"}
          onChange={setPriceRange}
        />
        <Dropdown
          label="Amenities"
          options={amenitiesOptions}
          selected={amenities || "Amenities"}
          onChange={setAmenities}
        />
        <Dropdown
          label="Filters"
          options={filtersOptions}
          selected={filters || "Filters"}
          onChange={setFilters}
          icon={<FilterListIcon />}
        />
      </div>

      <Bproperties isLoading={isLoading} properties={properties} />

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={handleModalClose} content={modalContent} />
    </Wrapper>
  );
};

export default Booking;
