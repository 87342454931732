import { useState } from "react";
import { Link } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import WifiIcon from "@mui/icons-material/Wifi";
import { CircularProgress } from "@mui/material";
import { formatPrice } from "../../utils/helpers";

const Bproperties = (props: any) => {
  const [visibleProperties, setVisibleProperties] = useState(6);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleSeeMore = () => {
    setIsLoadingMore(true);
    setTimeout(() => {
      setVisibleProperties((prevVisible) => prevVisible + 6);
      setIsLoadingMore(false);
    }, 2000); // 2 seconds delay
  };

  console.log(props.properties, "base");
  
  return !props.isLoading ? (
    props.properties.length >= 1 ? (
      <div className="flex flex-wrap mb-6">
        {props.properties.slice(0, visibleProperties).map((datas: any) => (
          <div className="w-full md:w-1/3 p-2 font-jakarta" key={datas?.id}>
            <div className="bg-[#1E1E1E] border border-[#33383F] rounded-lg shadow-lg flex flex-col h-full">
              <div
                className="bg-cover bg-center h-64"
                style={{
                  backgroundImage: `url(${
                    datas?.property?.default_image?.url || "" // Correctly accessing the image URL
                  })`,
                }}
                title="property image"
              />
              <div className="flex-1 flex flex-col p-4">
                <div className="flex-1">
                  <h2 className="text-lg font-semibold mb-2">
                    <Link
                      to={`/bookings/${datas?.property?.id}`}
                      className="text-white font-jakarta no-underline"
                    >
                      {datas?.property?.name}
                    </Link>
                  </h2>
                  <p className="text-[#9A9FA5] font-jakarta">
                    Available Date: {datas?.check_in} - {datas?.check_out}
                  </p>
                </div>
                <div className="mt-2">
                  <div className="flex space-x-4">
                    {/* Display Free Wifi if it's available */}
                    {datas?.property?.features?.wifi && (
                      <div className="flex items-center">
                        <WifiIcon style={{ color: "#B6B7B9", marginRight: 5 }} />
                        <p className="text-xs font-jakarta font-medium text-[#9A9FA5]">
                          Free wifi
                        </p>
                      </div>
                    )}
                    {/* Display Bathroom information */}
                    {datas?.property?.features?.bathrooms > 0 && (
                      <div className="flex items-center">
                        <LunchDiningIcon
                          style={{ color: "#B6B7B9", marginRight: 5 }}
                        />
                        <p className="text-xs font-jakarta font-medium text-[#9A9FA5]">
                          {datas?.property?.features?.bathrooms} Bathroom(s)
                        </p>
                      </div>
                    )}
                    {/* Display Breakfast included info */}
                    <div className="flex items-center">
                      <LunchDiningIcon
                        style={{ color: "#B6B7B9", marginRight: 5 }}
                      />
                      <p className="text-xs font-jakarta font-medium text-[#9A9FA5]">
                        Breakfast included
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-0">
                <hr className="border-t border-[#33383F]" />
                <div className="flex justify-between items-center pb-3 mt-2 px-4">
                  <div>
                    <p className="font-jakarta">
                      {formatPrice(datas?.total_price)}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <StarIcon style={{ color: "#DEA838", width: 12 }} />
                    <p className="text-xs font-jakarta">{datas?.rating}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {visibleProperties < props.properties.length && (
          <div className="w-full text-center mt-4">
            <button
              className="bg-[#DEA838] text-black font-jakarta px-4 py-2 rounded-lg"
              onClick={handleSeeMore}
              disabled={isLoadingMore}
            >
              {isLoadingMore ? "Loading..." : "See More"}
            </button>
          </div>
        )}
      </div>
    ) : (
      <></>
    )
  ) : (
    <CircularProgress size={24} />
  );
};

export default Bproperties;
