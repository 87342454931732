import React from 'react';

interface ProgressBarProps {
  year: number;
}

const YearProgressBar: React.FC<ProgressBarProps> = ({ year }) => {
  const currentYear = new Date().getFullYear();
  const maxYear = currentYear + 4;

  // Calculate the percentage based on the given year and the range from currentYear to maxYear
  const percentage = ((year - currentYear) / (maxYear - currentYear)) * 100;

  return (
    <div className="lg:w-[40%] w-full mt-4">
      <div className="relative w-full h-1 bg-[#33383F] rounded-full">
        <div
          className="absolute top-[-3rem] left-0 text-[14px] rounded-[10px] text-black bg-white py-1 px-3"
          style={{ left: `${percentage}%` }}
        >
          {year}
        </div>
        <div
          className="h-1 bg-[#FCFCFC] rounded-full"
          style={{ width: `${percentage}%` }}
        ></div>
        <div
          className="absolute top-[-0.5rem] h-5 w-5 bg-[#DEA838] border border-white rounded-full"
          style={{ left: `${percentage}%`, transform: 'translateX(-50%)' }}
        ></div>
      </div>
    </div>
  );
};

export default YearProgressBar;
