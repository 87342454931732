import { useEffect, useState } from "react";
import { formatPrice } from "../../utils/helpers";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { LuMoreVertical } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import { IoMdArrowUp } from "react-icons/io";
import ModalContent from "../SellDetails/ModalContent";


interface InvestmentProps {
  investments: any; // Allows flexibility, though more specific types are preferred if possible
}
const ActiveInvestments: React.FC<InvestmentProps> = ({ investments }: any) => {
  const [investment, setInvestments] = useState<any[]>([]);
  const [popupVisible, setPopupVisible] = useState<null | number>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const togglePopup = (index: number) => {
    if (popupVisible === index) {
      setPopupVisible(null); // Hide the popup if it's already open
    } else {
      setPopupVisible(index); // Show the popup for the clicked item
    }
  };
  
    console.log(investment, "active")

    useEffect(() => {
      if (investments) {
        // Filter investments with status 'Pending'
        const pendingInvestments = investments.investments?.filter((inv: any) => inv?.status === "Active");
        setInvestments(pendingInvestments);
      }
    }, [investments]);
  return (
    <div className="py-[30px]">
      <div className="lg:w-[90%] w-full bg-black shadow-md rounded-lg overflow-x-auto">
        <table className="lg:w-full  w-[250%] border-collapse">
          {/* Table Header */}
          <thead>
            <tr className="border-b border-[#9A9FA5] w-full">
              {["PROPERTY", "LOCATION", "INVESTMENT AMOUNT", "EXPECTED RENT", ""].map((header, index) => (
                <th key={index} className="p-3 font-[500] text-[14px]  font-jakarta text-[#9A9FA5] text-left">
                  {header}
                </th>
              ))}
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
          {investment?.map((investment: any, idx: number) => (
              <tr key={idx} className="">
                <td className="p-3 flex items-center gap-2">
                  <div className="w-16 h-16 overflow-hidden rounded-md">
                    <img
                      src={investment.property.default_image?.url || ""}
                      alt={investment.property.name || ""}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <p className="text-[12px] w-[50px] font-jakarta">{investment.property.name || ""}</p>
                </td>
                <td className="p-3 text-[12px] font-jakarta">{investment.property.location.address || ""}</td>
                <td className="p-3 text-[12px] font-jakarta">{formatPrice(investment.investment_cost) || ""}</td>
                <td className="p-3 ">
                  <p className="mr-2  bg-transparent rounded-md text-[12px] flex font-jakarta">
                    <span className="bg-gray-600 p-2 rounded-[5px] ">
                    {formatPrice(investment.projected_annual_net_rental_income) || ""}
                    </span>
                    <span className="flex text-[green] items-center">
                      <IoMdArrowUp className="text-[green]" />
                      58.3
                    </span>
                  </p>
                </td>
                <td className="p-3 relative">
                  <button onClick={() => togglePopup(idx)} className="py-1 px-3">
                    <LuMoreVertical />
                  </button>
                  {popupVisible === idx && (
                    <div className="absolute top-14 right-4 bg-[#1E1E1E] border border-[#6F767E] shadow-md rounded-md py-2 px-4 z-10 min-w-[150px]">
                      <Link to={`/sell/${investment?.property?.id}`}>
                        <div className="block text-left p-2 w-full text-[12px] cursor-pointer font-jakarta">View Property</div>
                      </Link>
                      <hr className="border-t-[1px] border-[#33383F]" />
                        <div onClick={openModal} className="block text-left p-2 w-full cursor-pointer text-[12px] font-jakarta">Sell Property</div>
                    </div>
                  )}

{isModalOpen && (
                  <div
                    className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50"
                  // onClick={closeModal}
                  >
                    <div
                      className="bg-transparent"
                      onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
                    >
                      <ModalContent property={investments}
                        close={closeModal}
                      />
                      {/* <button
                        onClick={closeModal}
                        className="mt-4 bg-red-500 text-white p-2 rounded"
                      >
                        Close
                      </button> */}
                    </div>
                  </div>
                )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActiveInvestments;
