import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import { authImage, logo } from "../../assets";
import 'tailwindcss/tailwind.css';
import { AiOutlineDown } from "react-icons/ai";
import { BsUpload } from "react-icons/bs";
import { FaCheck } from 'react-icons/fa';
import { CircularProgress } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";

const validationSchema = Yup.object().shape({
  country: Yup.string().required("Please enter your nationality"),
  id_type: Yup.string().oneOf(["ID Card", "Driver License"], "Please select a document").required("Please select a document"),
  id_card: Yup.mixed().required("Please upload the correct id"),
  id_number: Yup.string().required("Please enter your NIN"),
});

const textFieldStyle = "text-[14px] text-[white] mb-4 py-3 px-5 bg-[#1E1E1E] border border-[#33383F] rounded-lg flex items-center";
interface KycsProps {
  Close: () => void;
}

const Kycs: React.FC<KycsProps> = ({ Close }) => {
  const [checked, setChecked] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [idType, setIdType] = useState("Select Document");
  const [showErrors, setShowErrors] = useState(false);
  const [country, setCountry] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCountry(event.target.value);
  };


  console.log(country, "my countryyyy")

  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | undefined>(undefined);

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);

      // Create a preview URL using FileReader
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  console.log(file?.name, "my file");
  console.log(file, "my");

  const handleIdTypeChange = (type: string) => {
    setIdType(type);
    setDropdownOpen(false);
  };

  const navigate = useNavigate();



  // useEffect(() => {
  //   let user = Cookies.get("userData") || "";

  //   console.log(user, "hdhdhdhhhd")
  //   if (user) {
  //     try {
  //       let currentUser = JSON.parse(user);
  //       console.debug(currentUser.verification.is_verified);
  //       if (currentUser.verification.is_verified) {
  //         navigate("/overview");
  //         window.location.reload();
  //       }
  //     } catch (error) {
  //       console.error("Failed to parse user data:", error);
  //     }
  //   }
  // }, [navigate]);

 

 

  console.log(idType, "my idd")

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ country: "", idType: "", file: "", checkbox: '' });

  const handleSubmits = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const newErrors = { country: '', idType: '', file: '', checkbox: '' };

    if (!country) {
      newErrors.country = 'Country is required';
    }
    if (!idType) {
      newErrors.idType = 'ID Type is required';
    }
    if (!file?.name) {
      newErrors.file = 'File is required';
    }
    if (!checked) {
      newErrors.checkbox = 'You must agree to the terms';
    }
    if (newErrors.country || newErrors.idType || newErrors.file) {
      setErrors(newErrors);
      setLoading(false);
      setTimeout(() => {
        setErrors({ country: '', idType: '', file: '', checkbox: '' });
      }, 2000);
      return;
    }

    setErrors({ country: '', idType: '', file: '', checkbox: '' });

    try {
      await userRequest.post('/user/profile/kyc-verification', {
        id_type: idType,
        id_card: file?.name,
        nin: country,
      });

      toast.success('ID sent for verification', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });

      navigate('/profile');
      window.location.reload();
    } catch (err: any) {
      console.log(err);
      toast.error('ID verification failed', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    }

    setLoading(false);
  };


  return (
   
        
        <div className="flex items-center justify-center h-[50%] w-full p-4">
          <div className="w-full p-4 md:p-8 py-[30px] rounded-[30px] custom-scroll-content font-jakarta border border-[#6F767E] shadow-lg" style={{ overflowY: 'auto', WebkitOverflowScrolling: 'touch', background: "linear-gradient(135deg, #1B1818 0%, #1B1818 100%)" }}>
              <div className="flex justify-end items-end">
                <MdOutlineClose onClick={Close} className="cursor-pointer"/>
              </div>

            <form className="max-w-lg w-full  " onSubmit={handleSubmits} >
              <p className="text-[20px] md:text-[30px] font-jakarta font-[600]">Upload a proof of your Identity</p>
              <p className="text-[#9A9FA5] font-[400] font-jakarta mb-4 text-[12px] md:text-[14px]">Let’s get  you started. Create an account to begin</p>
              <div className="flex w-full justify-between gap-5 my-[30px]">
                <p className="bg-[#33383F] w-[90%] py-[7px] rounded-[8px] text-[14px] font-jakarta cursor-pointer text-center border border-[#DEA838] text-[#DEA838]">Manual</p>
                <p className="bg-[#33383F] w-[90%] py-[7px] rounded-[8px] text-[14px] font-jakarta cursor-pointer text-center border border-[#6F767E]">Third-party verification</p>
              </div>
              <div className="mb-4 text-start">
                <div className="w-full">
                  <label htmlFor="country" className="text-[14px] font-[500] font-jakarta">Nationality</label>
                  <div className={`${errors.country ? "border border-[red]" : ""} w-full border border-[#33383F] rounded-lg mt-4`}>
                    <input
                      name="country"
                      type="text"
                      placeholder="United Kingdom"
                      className={`p-2 rounded-[10px] text-white bg-[#1E1E1E] font-jakarta placeholder-[#33383F] outline-none w-full`}
                      value={country}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.country && <div className="text-red-500 text-xs mt-1">country need</div>}
                </div>
                <p className="text-[14px] font-[500] mb-3 font-jakarta">Document</p>
                <div className="relative mb-4">
                  <div
                    className={`${textFieldStyle} w-full cursor-pointer flex justify-between items-center ${errors.idType ? "border border-[red]" : ""}`}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                  >
                    {idType || "Select ID Type"}
                    <AiOutlineDown />
                  </div>
                  {dropdownOpen && (
                    <div className="absolute left-0 w-full mt-0 bg-[#33383F] p-[20px] rounded-[10px] z-10">
                      <p
                        className="py-[10px] text-[14px] font-jakarta cursor-pointer"
                        onClick={() => handleIdTypeChange("ID Card")}
                      >
                        ID Card
                      </p>
                      <p
                        className="py-[10px] text-[14px] font-jakarta cursor-pointer"
                        onClick={() => handleIdTypeChange("Driver License")}
                      >
                        Driver License
                      </p>
                    </div>
                  )}
                  {errors.idType && <div className="text-red-500 text-xs mt-1">Select an ID type</div>}
                </div>
                <p className="text-[14px] font-[500] mb-3 font-jakarta">Upload Document</p>
                <div className=" mb-4">
                  <label className="flex flex-col gap-3 py-7 items-center justify-center w-full border-2 border-[#33383F] bg-[#1E1E1E] rounded-lg">
                    <div className="flex flex-col items-center justify-center">
                      <BsUpload className="w-8 h-8 text-white" />
                      <p className="text-[16px] text-white mb-2">
                        Upload your document
                      </p>
                      <p className="text-[14px] text-[#9A9FA5] text-center px-[14%] mb-4">Upload the front side of your document.
                        Supports: JPG, PNG,PDF</p>

                      <p className="text-[#DEA838] rounded-[5px] text-[14px] border border-[#DEA838] py-[4px] px-[15px] cursor-pointer">Choose a file</p>
                      <input
                        id="id_card"
                        name="id_card"
                        type="file"
                        accept="application/pdf, image/png, image/jpeg"
                        className="hidden"
                        onChange={handleChanges}
                      />
                      {file && (
                        <div className="mt-4">
                          {file.type.includes('image/') ? (
                            <img
                              src={preview}
                              alt="Uploaded File"
                              className="max-w-full h-auto rounded-lg"
                            />
                          ) : (
                            <p className="text-white">File type not supported for preview.</p>
                          )}
                        </div>
                      )}
                    </div>
                  </label>
                  {errors.file && <div className="text-red-500 text-xs mt-1">file needed</div>}
                </div>

                <div className="flex mt-4">
                  <div className={`checkbox-container ${showErrors && !checked ? "border border-[red]" : ""}`}>
                    <div className="checkbox" onClick={() => setChecked(!checked)}>
                      {checked && (
                        <FaCheck className="checkbox-checkmark" />
                      )}
                    </div>
                  </div>
                  <p className="text-[12px] text-[#D5D5D5] font-jakarta ml-4">
                    I acknowledge that I have read, understood and agree with the Privacy statement and collection notice below and the lorem ipsum
                  </p>
                </div>
              </div>
              <button
                type="submit"
                className={`w-full text-[14px] py-[15px] px-4 rounded-lg mb-[10%] bg-[#DEA838] hover:bg-[#DEA838] text-[#1E1E1E] font-[700] font-jakarta flex justify-center items-center`}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Verify"
                )}
              </button>
            </form>

          </div>
        </div>
       
  );
};

export default Kycs;
