import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import { useState } from 'react';
import { Button, TextField, Grid, CircularProgress, Typography, FormControlLabel, Checkbox, InputLabel, Link } from '@mui/material';
import { authImage } from '../../assets';
import { logo } from '../../assets';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must have at least 2 characters')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must have at least 6 characters')
    .required('Password is required'),
  passwordConfirmed: Yup.string().oneOf(
    [Yup.ref('password')],
    'Password must match'
  ),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, 'Invalid phone number')
    .required('Phone number is required'),

});

const textFieldStyle = {
  fontSize: '12px',
  marginBottom: '15px',
  paddingLeft: '20px',
  outline: 'none',
  backgroundColor: '#1E1E1E',
  borderRadius: '10px',
}


const Account: React.FC = () => {
  // const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()



  return (
    <Grid container>
      <Grid item xs={12} md={5}>
        <div style={{ position: 'relative', height: '100vh' }}>
          <div style={{ position: 'absolute', top: '50%', left: '40%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
            <Typography variant="h4" style={{ textAlign: 'center', color: '#ffffff' }}>
              Are my funds and assets safe at Maihomm?
            </Typography>
            <p style={{ textAlign: 'center', color: '#ffffff' }}>
              All clients funds and co-owned properties are held in Trust by Maihomm Trustees, a UK registered trust and regulated by the HMRC.
            </p>
          </div>
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 0 }}>
            <img src={authImage} alt="" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(6, 8, 10, 0.8)' }}></div>
          </div>
          <img src={logo} alt="" style={{ position: 'absolute', top: '20%', left: '10%', zIndex: 2 }} />
        </div>
      </Grid>

      <Grid item xs={12} md={7} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Formik
          initialValues={{ name: '', email: '', password: '', passwordConfirmed: '' }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            // setIsLoading(true);

            // Handle form submission logic here
            toast.success('Account created successfully', {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 3000,
              hideProgressBar: true,
            });
            // Navigate to the profile page
            navigate('/profile');
            // setIsLoading(false);
            setSubmitting(false);
          }}
        >
          {({ values, errors, isSubmitting }) => (
            <Form style={{ width: '70%' }} >
              <div style={{ marginBottom: '50px' }}>
                <Typography>Create your account</Typography>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="form-control">
                    <InputLabel htmlFor="email">First Name</InputLabel>

                    <Field as={TextField} type="text" name="name" placeholder='First name' variant="standard" size="medium"
                      style={textFieldStyle}
                      InputProps={{
                        disableUnderline: true,

                      }} fullWidth
                    />
                    <ErrorMessage name="name" component="div" />
                  </div>

                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className="form-control">
                    <InputLabel htmlFor="email">Last Name</InputLabel>

                    <Field as={TextField} type="text" name="name" placeholder='First name' variant="standard" size="medium"
                      style={textFieldStyle}
                      InputProps={{
                        disableUnderline: true,


                      }} fullWidth
                    />
                    <ErrorMessage name="name" component="div" />
                  </div>

                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className="form-control">
                    <InputLabel htmlFor="email">Email</InputLabel>

                    <Field as={TextField} type="email" name="email" id="email" variant="standard" size="medium"
                      style={textFieldStyle}
                      InputProps={{ disableUnderline: true, }} fullWidth


                    />
                    <ErrorMessage name="email" component="div" />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className="form-control">
                    <InputLabel htmlFor="number">Phone Number</InputLabel>

                    <Field as={TextField} type="number" name="phoneNumber" placeholder='Phone number' variant="standard" size="medium"
                      style={textFieldStyle}
                      InputProps={{
                        disableUnderline: true,

                      }} fullWidth
                    />
                    <ErrorMessage name="name" component="div" />
                  </div>

                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="form-control">
                    <InputLabel htmlFor="email">Password</InputLabel>

                    <Field as={TextField} type="password" name="password" id="password"
                      variant="standard" size="medium"
                      style={textFieldStyle}
                      InputProps={{ disableUnderline: true, }} fullWidth

                    />
                    <ErrorMessage name="password" component="div" />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="form-control">
                    <InputLabel htmlFor="email">Confirm Password</InputLabel>

                    <Field as={TextField} type="password" name="passwordConfirmed" id="passwordConfirmed"
                      variant="standard" size="medium"
                      style={textFieldStyle}
                      InputProps={{ disableUnderline: true, }} fullWidth

                    />
                    <ErrorMessage name="passwordConfirmed" component="div" />
                  </div>
                </Grid>
              </Grid>
              <div>
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="I agree to Maihomm's Terms of Use & Privacy Policy"
                />

              </div>

              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  fullWidth
                >
                  {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Register'}
                </Button>
                <p style={{ textAlign: 'start', marginTop: '20px' }}>
                  Already have an account? <Link href='/login' > log in</Link>

                </p>
              </div>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default Account;
