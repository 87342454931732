import { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
interface FirstCardProps {
    data: any;  // Accept any data structure
    myproperty: any;  // Accept any data structure
  }
  const SecondCard: React.FC<FirstCardProps> = ({ data, myproperty }) => {
    const navigate = useNavigate();
    const handleViewAllClick = () => {
        navigate("/bookings");
      };
      const handleViewAllClick1 = () => {
        navigate("/properties");
      };
    return (
        <div className="lg:w-[90%] w-full mt-[30px] gap-4">
            <div className="flex lg:flex-row flex-col lg:justify-between gap-4">
                <div className="border border-[#353945] lg:w-1/2 p-4 rounded-[10px]">
                    <div className="w-full flex justify-between items-center">
                        <div className="flex justify-between items-center">
                            <p className="text-[14px] font-jakarta"> My Properties</p>
                        </div>

                        <div>
                            <div onClick={handleViewAllClick1} className="inline-block text-left cursor-pointer">
                                <div className="flex items-center">
                                    <p className="text-[#DEA838] text-[14px] font-jakarta">View All</p>
                                    <p className="text-[#DEA838] text-[14px] px-[20px] py-[10px] rounded">
                                        <IoIosArrowForward />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="border-t-2 border-[#323030] mt-4" />
                    {
                        myproperty ? (
                            <div className="flex gap-4 mt-4">
                                <div>
                                    <img src={data[0]?.default_image?.url} className="h-[90px] w-[90px]" alt="No properties" />
                                </div>
                                <div className="flex flex-col">
                                    <p className="capitalize text-[14px] font-jakarta">{myproperty[0]?.name}</p>
                                    <p className="text-[#B6B7B9] text-[12px] font-jakarta">{myproperty[0]?.location.address}</p>
                                    <p className=" text-[14px] font-jakarta mt-4">{myproperty[0]?.costings.price}</p>
                                </div>

                            </div>
                        ) : (

                            <div className="flex flex-col justify-center items-center mt-4">
                                <img src="/assets/images/empty.svg" className="h-16 w-16" alt="No properties" />
                                <p className="text-[#B6B7B9] font-jakarta">No properties yet</p>
                            </div>
                        )
                    }
                </div>
                <div className="border border-[#353945] lg:w-1/2 p-4 rounded-[10px]">
                    <div className="w-full flex justify-between items-center">
                        <div className="flex justify-between items-center">
                            <p className="text-[14px] font-jakarta"> My Bookings</p>
                        </div>

                        <div>
                            <div onClick={handleViewAllClick} className="inline-block text-left cursor-pointer">
                                <div className="flex items-center">
                                    <p className="text-[#DEA838] text-[14px] font-jakarta">View All</p>
                                    <p className="text-[#DEA838] px-[20px] py-[10px] rounded">
                                        <IoIosArrowForward />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="border-t-2 border-[#323030] mt-4" />
                    {
                        data ? (

                            <div className="flex gap-4 mt-4">
                            <div>
                                <img src={data[0]?.property?.default_image?.url} className="h-[90px] w-[90px]" alt="No properties" />
                            </div>
                            <div className="flex flex-col">
                                <p className="text-[14px] font-jakarta">{data[0]?.property?.name}</p>
                                <p className="text-[#B6B7B9] text-[12px] font-jakarta">{data[0]?.property?.location.address}</p>
                                <p className=" text-[14px] font-jakarta mt-4">{data[0]?.total_price}</p>
                            </div>

                        </div>
                        ) : (

                            <div className="flex flex-col justify-center items-center mt-4">
                                <img src="/assets/images/empty.svg" className="h-16 w-16" alt="No properties" />
                                <p className="text-[#B6B7B9] font-jakarta">No bookings yet</p>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default SecondCard;
