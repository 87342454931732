import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the types for the state and payload
interface UserData {
  id: string;
  name: string;
  email: string;
}

interface LoginState {
  userData: UserData | null;
  error: string | null;
}

// Rehydrate initial state from localStorage
const storedUserData = sessionStorage.getItem('userData');
const initialState: LoginState = {
  userData: storedUserData ? JSON.parse(storedUserData) : null,
  error: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginUserSuccess: (state, action: PayloadAction<{ userData: UserData }>) => {
      state.userData = action.payload.userData;
      // Persist userData in localStorage
      sessionStorage.setItem('userData', JSON.stringify(action.payload.userData));
      state.error = null;
      console.log('Login successful:', state.userData);
    },
    loginUserFailure: (state, action: PayloadAction<string>) => {
      state.userData = null;
      state.error = action.payload;
    },
    logoutUser: (state) => {
      state.userData = null;
      state.error = null;
      // Remove userData from localStorage
      sessionStorage.removeItem('userData');
    },
  },
});

export const { loginUserSuccess, loginUserFailure, logoutUser } = loginSlice.actions;

export default loginSlice.reducer;
