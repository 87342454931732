import { Route, Routes } from "react-router-dom";
import {
  Account,
  IncomeHistory,
  Loan,
  Kyc,
  SkipKyc,
  Login,
  TwoFactorAuth,
  Portfolio,
  Profile,
  Properties,
  PropertyDetails,
  Register,
  Settings,
  Cart,
  Booking,
  BookingDetails,
  BookingHistory,
  Rewards,
  Overview,
  Wallet,
  Support,
  ForgotPassword,
  Payment
} from "../pages";
import DataDeletion from "../pages/DataDeletion";
import SellDetails from "../pages/SellDetails";
import Verification from "../pages/Authentication/Verification";
import EmailVerification from "../pages/Authentication/EmailVerify";

const RouterConfig = () => {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/auth/kyc" element={<Kyc/>}></Route>
        <Route path="/auth/skipkyc" element={<SkipKyc/>}></Route>
        {/* <Route path="/auth/verification" element={<Verification/>}></Route> */}
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/twofactor" element={<TwoFactorAuth />} />
        <Route path="/auth/register" element={<Register />} />
        <Route path="/email/verification" element={<EmailVerification />} />
        <Route path="/auth/resetpassword" element={<ForgotPassword />} />
        <Route path="/properties" element={<Properties />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/account" element={<Account />} />
        <Route path="/properties/:id" element={<PropertyDetails />} />
        <Route path="/sell/:id" element={<SellDetails />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/support" element={<Support />} />
        <Route path="/portfolio/loan" element={<Loan />} />
        <Route path="/portfolio/income-history" element={<IncomeHistory />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/bookings" element={<Booking />} />
        <Route path="/bookings/:id" element={<BookingDetails />} />
        <Route path="/bookings/history" element={<BookingHistory />} />
        <Route path="/rewards" element={<Rewards />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/request-data-deletion" element={<DataDeletion />} />
      </Routes>
    </>
  );
};

export default RouterConfig;
