import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../services/loginSlice";
import cartReducer from "./cartReducer";
import applicationLoanReducer from "./applicationLoanSlice";

// Create the Redux store with reducers
export const store = configureStore({
  reducer: {
    login: loginReducer,
    cart: cartReducer,
    loan: applicationLoanReducer,
    // Add more reducers here if needed
  },
});

// Define RootState type for use with selectors and state
export type RootState = ReturnType<typeof store.getState>;

// Define AppDispatch type for use with dispatch
export type AppDispatch = typeof store.dispatch;
