// components/ToggleSwitch.js
import React from 'react';

interface ToggleSwitchProps {
  isActive: boolean;
  onToggle: () => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isActive, onToggle }) => {
  return (
    <div
      onClick={onToggle}
      className={`relative inline-flex items-center cursor-pointer ${
        isActive ? 'bg-[#2A85FF]' : 'bg-gray-300'
      } w-[52px] h-6 rounded-full transition-colors duration-300 ease-in-out`}
    >
      <span
        className={`${
          isActive ? 'translate-x-6 bg-black bg-opacity-70' : 'bg-[gray] translate-x-1'
        } inline-block w-6 h-5 transform rounded-full transition-transform duration-300 ease-in-out`}
      ></span>
    </div>
  );
};

export default ToggleSwitch;
