import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
          main: '#3f51b5',
        },
        secondary: {
          main: '#f50057',
        },
        background: {
          default: '#000000',
          paper: '#1E1E1E',
        },
        error: {
            main: red.A400,
          },
      },
      typography: {
        body1: {
          fontFamily: [
            '"Work Sans"',
            'sans-serif',
          ].join(','),
          fontSize: 16
        },
        h2: {
          fontFamily: [
            '"Vollkorn"',
            'serif',
          ].join(','),
          fontSize: 36,
          fontWeight: 'medium'
        },
      },
       components: { 
        MuiTextField: {
          defaultProps: {
            inputProps: {
              disableUnderline: true,
            }
          },

          styleOverrides: {
            root: {
            },

          }
        },

        MuiFormHelperText: {
          styleOverrides: {
            root: {
              color: 'red', // Specify the desired color here
            },
          },
        },    
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: '#DEA838',
              textTransform: 'none',
              color: '#000000',
              height: '40px',
              borderRadius: 10,
              ':hover': {
                backgroundColor: '#DEA838',
              },
            }),
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'primary' && {
              color: '#DEA838',
              textTransform: 'none',
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
              color: '#ffffff',
              borderColor: '#ffffff',
              textTransform: 'none',
              // fontSize: 10,
              fontWeight: 'normal',
              borderRadius: 10,
              height: '40px',
              ':hover': {
                borderColor: '#DEA838',
                
              },
            }),
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: '#DEA838',
          textDecoration: 'none',
          
        },
      },
    },
    
  },
});

export default theme;

