import React, { useState, useEffect } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../services/loginSlice';

const ProfileNav = () => {
    const [activeLink, setActiveLink] = useState('profile');

    useEffect(() => {
        const currentPath = window.location.pathname;
        if (currentPath === '/settings') {
            setActiveLink('settings');
        } else if (currentPath === '/profile') {
            setActiveLink('profile');
        }
    }, []);

    const dispatch = useDispatch();

    const handleLogout = () => {
      // Dispatch the logout action to clear user data
      dispatch(logoutUser());
      // Optionally, navigate to the login page or homepage after logout
      window.location.href = '/'; // Or use a router hook like useNavigate if you're using React Router
    };

    return (
        <div className="flex lg:flex-col flex-row fixed gap-4 bg-black lg:h-0 h-[5vh] lg:md:w-5/12 w-full z-10">
            <a
                href="/profile"
                onClick={() => setActiveLink('profile')}
                className={` lg:w-2/5 w-full lg:p-3 rounded-[10px] text-[14px] flex items-center hover:bg-[#1E1E1E] hover:text-[#FCFCFC] hover:rounded-[10px] ${activeLink === 'profile' ? 'bg-[#1E1E1E] text-white' : ''}`}
            >
                <button className="flex items-center">
                    <PersonIcon className='text-[14px]'/>
                </button>
                <span className="ml-2 font-jakarta">Profile</span>
            </a>

            <a
                href="/settings"
                onClick={() => setActiveLink('settings')}
                className={` lg:w-2/5 w-full lg:p-3 rounded-[10px] text-[14px] flex items-center hover:bg-[#1E1E1E] hover:text-[#FCFCFC] hover:rounded-[10px] ${activeLink === 'settings' ? 'bg-[#1E1E1E] text-white' : ''}`}
            >
                <button className="flex items-center">
                    <SettingsIcon className='text-[14px]'/>
                </button>
                <span className="ml-2 font-jakarta">Settings</span>
            </a>

            <p
                onClick={handleLogout}
                className=" lg:w-2/5 w-full lg:p-3 rounded-[10px] text-[14px] flex items-center hover:bg-[#1E1E1E] hover:text-[#FCFCFC] hover:rounded-[10px] cursor-pointer"
            >
                <button className="flex items-center">
                    <LogoutIcon className='text-[14px]'/>
                </button>
                <span className="ml-2 font-jakarta">Log Out</span>
            </p>
        </div>
    );
}

export default ProfileNav;
