import { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import Cookies from "js-cookie";
import TopBar from "../../components/topNav";
import Paper from "@mui/material/Paper";
import { Typography, Box } from "@mui/material";
import DividerLine from "../../utils/DividerLine";
import Dialogue from "./Dialogue";
import DialogueUpload from "./DialogueUpload";

const Payment = () => {
  const [slots, setSlots] = useState<any>({});
  
  useEffect(() => {
    const res = Cookies.get("slots");
    if (res) {
      let objSlots = (JSON.parse(res));
      let arrSlots = Object.entries(objSlots);
      setSlots(arrSlots);
    }
  }, [setSlots]);

  return (
    <Wrapper>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          Payment Information
        </Typography>
        <DividerLine sx={{ mt: 1 }} />
        <Dialogue />
        <DividerLine />

        <Box>
          <ul>
            <li>
              {" "}
              <Typography variant="subtitle2" color="#c7c7c7">
                Confirm that all the details are correct, and submit the
                payment.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle2" color="#c7c7c7">
                Take a screenshot of the payment confirmation page or save the
                transfer receipt as evidence of payment.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle2" color="#c7c7c7">
                Return to the platform and upload the screenshot or transfer
                receipt as evidence of payment.
              </Typography>
            </li>
          </ul>
        </Box>
        <DividerLine />
        <Typography variant="subtitle2" color="#c7c7c7" my={2}>
          <span style={{ color: "#DEA838", fontWeight: 600 }}>Note:</span>{" "}
          Please ensure that the payment amount is accurate and that the
          recipient's account details are entered correctly. Maihomm Management
          Limited will not be held responsible for any errors or delays caused
          by incorrect information. If you have any questions or concerns,
          please contact our support team.
        </Typography>
        <DialogueUpload slots={slots}/>
      </Paper>
    </Wrapper>
  );
};

export default Payment;
