import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Typography } from "@mui/material";


type BankInfoProps = {
    name: string;
    value: string;
    hasNumber?: boolean;
}

const BankInfo = (props: BankInfoProps) => {
    return <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
        <Typography variant="subtitle2" color="#fff">{props.name}:</Typography>
        {props.hasNumber !== true && <Typography variant="subtitle2">{props.value}</Typography>}
        {props.hasNumber && <Typography variant="subtitle2" color="#DEA838">{props.value}</Typography>}
    </Box>
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Dialogue() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{ margin: "10px 0"}}
        onClick={handleClickOpen}
      >
        Click for Bank Details
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        
      >
        <DialogTitle sx={{fontSize: 20, fontWeight: 300}}>{"Please pay using any of the following"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                <BankInfo name="Account Name" value="Maihomm Management Limited" />
                <BankInfo name="Bank Name" value="Zenith Bank" />
                <BankInfo name="Naira Account Number" value="1226902217" hasNumber={true}/>
                <BankInfo name="GBP Account Number" value="5061261467" hasNumber={true}/>
                <BankInfo name="USD Account Number" value="5073868630" hasNumber={true}/>
                
                <Typography variant="body1" sx={{margin: '5px 0'}}>Or</Typography>

                <BankInfo name="Bank Name" value="Wise" />
                <BankInfo name="GBP Account Number" value="78740715" hasNumber={true}/>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
