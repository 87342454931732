import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Grid, Box, Button, Typography, Link } from "@mui/material";
import SideInfo from "./SideInfo";

const SkipKyc = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const user = Cookies.get("userData") || "";
    if (JSON.parse(user).verification.is_verified) {
      navigate("/overview");
      window.location.reload();
    }
  }, [navigate]);
  return (
    <Grid container>
      <SideInfo />
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: { xs: "40px", md: 0 },
          paddingBottom: { xs: "40px", md: 0 },
        }}
      >
        <Box sx={{ maxWidth: "600px", width: "85%" }}>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: "1.25rem" }}
            gutterBottom
          >
            Identity Verification
          </Typography>
          <Typography variant="body1" sx={{ color: "#B6B7B9", mb: 5 }}>
            You will be required to upload some document such as a passport
            photograph or a selfie, NIN, A valid means of identification in
            order to properly verify your identity.
          </Typography>
          <Link href="/auth/kyc">
            <Button variant="contained" sx={{ mb: 4 }} fullWidth>
              Continue
            </Button>
          </Link>
          <Link href="/overview">
            <Button fullWidth>Skip</Button>
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SkipKyc;
