import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Select,
  MenuItem,
  Typography,
  Divider,
  InputAdornment,
  TextField,
  Rating,
  CircularProgress,
} from "@mui/material";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { SelectChangeEvent } from "@mui/material/Select";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Ratings = (props: any) => {
  const [value, setValue] = useState<number | null>(2);
  const [reviewFilter, setReviewFilter] = useState("10");
  const [reviews, setReviews] = useState<any[]>([]);
  const [reviewToPost, setReviewToPost] = useState("");
  const [triggerReload, setTriggerReload] = useState(false);
  const [visibleReviews, setVisibleReviews] = useState<number>(3);
  const [loading, setLoading] = useState<boolean>(false);
  const userData = useSelector((state: any) => state.login.userData);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const getReviews = async () => {
      try {
        let reviewsArr = [];
        let rating = 0;
        const res = await fetch(`${baseUrl}/user/reviews/${props.propertyId}/list`, {
          headers: {
            Authorization: `Bearer ${userData?.data?.token}`, // Adding token to request
          },
        });
        const data = await res.json();
        reviewsArr = data?.data?.data;
        props.setPropertyReviewsLength(reviewsArr.length);
        setReviews(reviewsArr.reverse());
        setReviewFilter("10");

        for (let element of reviewsArr) {
          rating += Number(element.rating);
        }
        let averageRatings = Number.isNaN(rating / reviewsArr.length)
          ? 0
          : Number(rating / reviewsArr.length).toFixed(1);
        props.setPropertyRatingsAverage(averageRatings);
      } catch (err) {
        console.log(err);
      }
    };

    getReviews();
  }, [props.propertyId, triggerReload, props]);

  const handleChangeSelect = (event: SelectChangeEvent) => {
    if (Number(reviewFilter) === Number(event.target.value)) {
      return;
    } else {
      let reviewArr = reviews;
      setReviews(reviewArr.reverse());
      setReviewFilter(event.target.value as string);
    }
  };

  const handlePostReview = async () => {
    try {
      if (reviewToPost !== "") {
        const response = await fetch(`${baseUrl}/user/reviews/${props.propertyId}/send`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.data?.token}`,
          },
          body: JSON.stringify({
            comment: reviewToPost,
            rating: value,
          }),
        });

        if (response.ok) {
          setTriggerReload(!triggerReload);
          setReviewToPost("");
        } else {
          throw new Error("Failed to post review");
        }
      }
    } catch (err) {
      toast.error("Error occurred while uploading review", {
        hideProgressBar: true,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const loadMoreReviews = () => {
    setLoading(true);
    setTimeout(() => {
      setVisibleReviews((prev) => prev + 3);
      setLoading(false);
    }, 1000);
  };

  return (
    <Box sx={{ my: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" component={"div"}>
            Add a review
          </Typography>
          <Typography sx={{ color: "#B6B7B9", fontSize: 14, fontWeight: 400 }}>
            Be the first to review{" "}
            <span style={{ color: "#FCFCFC", fontWeight: 500 }}>
              Spectacular views of Queenstown
            </span>{" "}
          </Typography>
        </Box>
        <Box>
          <Rating
            name="review"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
        </Box>
      </Box>
      <Box>
        <TextField
          placeholder="Share your thoughts"
          fullWidth
          value={reviewToPost}
          onChange={(e: any) => setReviewToPost(e.target.value)}
          sx={{
            borderRadius: "10px",
            border: "solid 2px #5b5b5b",
            fontSize: "14px",
            px: 3,
            py: 2,
          }}
          variant="standard"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  size="small"
                  variant="contained"
                  sx={{ borderRadius: "16px", fontSize: 14, fontWeight: 700 }}
                  onClick={handlePostReview}
                >
                  Post it! <TrendingFlatIcon style={{ marginLeft: 10 }} />
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 2,
        }}
      >
        <Typography variant="h6" component={"div"}>
          {reviews.length} comments
        </Typography>

        <Select
          labelId="demo-simple-select-disabled-label"
          value={reviewFilter}
          onChange={handleChangeSelect}
          id="demo-simple-select-disabled"
          sx={{ height: 48, width: 160 }}
        >
          <MenuItem value={10}>Newest</MenuItem>
          <MenuItem value={20}>Oldest</MenuItem>
        </Select>
      </Box>
      {reviews.slice(0, visibleReviews).map((review, index) => (
        <div key={index}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ mr: 2 }}>
                <img src="/assets/images/avatar_placeholder.svg" alt="user" />
              </Box>
              <Box>
                <Typography
                  component={"div"}
                  fontSize={14}
                  fontWeight={500}
                  color="#FCFCFC"
                  lineHeight={"24px"}
                >
                  {review?.user}
                </Typography>
                <Typography
                  component={"div"}
                  fontSize={14}
                  fontWeight={400}
                  color="#B6B7B9"
                  lineHeight={"24px"}
                  sx={{ my: 2 }}
                >
                  {review?.comment}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component={"div"}
                    fontSize={14}
                    fontWeight={400}
                    color="#B6B7B9"
                  >
                    {Date.now() - Date.parse(review?.created_at) < 60000 &&
                      Math.floor(
                        (Date.now() - Date.parse(review?.created_at)) / 1000
                      ) + " secs "}
                    {Date.now() - Date.parse(review?.created_at) >= 60000 &&
                      Date.now() - Date.parse(review?.created_at) < 3600000 &&
                      Math.floor(
                        (Date.now() - Date.parse(review?.created_at)) / 60000
                      ) + " mins "}
                    {Date.now() - Date.parse(review?.created_at) >= 3600000 &&
                      Date.now() - Date.parse(review?.created_at) <
                        3600000 * 24 &&
                      Math.floor(
                        (Date.now() - Date.parse(review?.created_at)) / 3600000
                      ) + " hrs "}
                    {Date.now() - Date.parse(review?.created_at) >=
                      3600000 * 24 &&
                      Math.floor(
                        (Date.now() - Date.parse(review?.created_at)) /
                          (3600000 * 24)
                      ) + " days "}
                    ago
                  </Typography>
                  <Button
                    size="small"
                    variant="text"
                    sx={{ mx: 1, color: "#FCFCFC" }}
                  >
                    Like
                  </Button>
                  <Button size="small" variant="text" sx={{ color: "#FCFCFC" }}>
                    Reply
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box>
              {review?.rating && (
                <Rating name="read-only" value={review?.rating} readOnly />
              )}
            </Box>
          </Box>
          <Divider sx={{ my: 1 }} />
        </div>
      ))}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <CircularProgress />
        </Box>
      )}
      {visibleReviews < reviews.length && !loading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <Button
            variant="contained"
            onClick={loadMoreReviews}
            sx={{ borderRadius: "16px", fontSize: 14, fontWeight: 700 }}
          >
            View More
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Ratings;
