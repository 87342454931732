import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import ProfileNav from "./ProfileNav";
import Wrapper from "../../components/Wrapper";
import { FaChevronDown, FaEye, FaEyeSlash } from "react-icons/fa"; // Importing the dropdown icon
import ToggleSwitch from "./Toggle";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../services/loginSlice";

interface UserSettings {
  two_factor_auth: number;
  hide_balance: number;
  receive_email_notifications: number;
  receive_text_notifications: number;
}

const Settings = () => {
  const [currency, setCurrency] = useState("gbp");
  const [language, setLanguage] = useState("eng");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [toggleStateTwoFactorAuth, setToggleStateTwoFactorAuth] = useState(false);
  const [toggleStateHideBalance, setToggleStateHideBalance] = useState(false);
  const [toggleStateReceiveEmailNotifications, setToggleStateReceiveEmailNotifications] = useState(false);
  const [toggleStateReceiveTextNotifications, setToggleStateReceiveTextNotifications] = useState(false);
  const [userSettings, setUserSettings] = useState<UserSettings | null>(null);
  const [isCurrencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
  const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState(false); // New state for language dropdown
  const userData = useSelector((state: any) => state.login.userData);
  useEffect(() => {
    const res = Cookies.get("userSettings") || "";
    try {
      const storedSettings = res ? JSON.parse(res) : null;
      if (storedSettings) {
        setUserSettings(storedSettings);
        setToggleStateTwoFactorAuth(storedSettings.two_factor_auth === 1);
        setToggleStateHideBalance(storedSettings.hide_balance === 1);
        setToggleStateReceiveEmailNotifications(storedSettings.receive_email_notifications === 1);
        setToggleStateReceiveTextNotifications(storedSettings.receive_text_notifications === 1);
      }
    } catch (err) {
      console.error("Error parsing user settings from cookies", err);
    }
  }, []);


  const handleCurrencySelect = (selectedCurrency: string) => {
    setCurrency(selectedCurrency);
    setCurrencyDropdownOpen(false);
  };

  const handleLanguageSelect = (selectedLanguage: string) => { // New function for language selection
    setLanguage(selectedLanguage);
    setLanguageDropdownOpen(false);
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleToggle = async (
    toggler: React.Dispatch<React.SetStateAction<boolean>>,
    toggleState: boolean,
    toggleName: keyof UserSettings
  ) => {
    toggler(!toggleState);
    try {
      const response = await fetch(`${baseUrl}/user/profile/settings/field-update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${userData?.data?.token}`,
        },
        body: JSON.stringify({
          field: toggleName,
          enabled: !toggleState ? 1 : 0,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update settings");
      }

      const settings = { ...userSettings, [toggleName]: !toggleState ? 1 : 0 };
      Cookies.set("userSettings", JSON.stringify(settings));
    } catch (err) {
      console.log(err);
    }
  };

  const handlePasswordChange = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const currentPassword = (event.currentTarget.elements.namedItem("currentPassword") as HTMLInputElement).value;
    const newPassword = (event.currentTarget.elements.namedItem("newPassword") as HTMLInputElement).value;
    const newPasswordConfirmed = (event.currentTarget.elements.namedItem("newPasswordConfirmed") as HTMLInputElement).value;


    
    if (newPassword !== newPasswordConfirmed) {
      toast.error("Passwords must match");
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/user/profile/settings/change-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${userData?.data?.token}`,
        },
        body: JSON.stringify({
          current_password: currentPassword,
          new_password: newPassword,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to change password");
      }

      toast.success("Password updated successfully");
      dispatch(logoutUser());
     
    } catch (err: any) {
      toast.error(err.message);
    }
  };


  return (
    <Wrapper

    >
      <div className="flex flex-col items-start w-full h-full">
        <ProfileNav />
        <div className="flex flex-col right-[6%] absolute w-full md:w-[60%]">
          <h5 className="text-[20px] font-semibold mb-6 font-jakarta">General</h5>

          {/* <div className="flex flex-col mb-4">
            <label htmlFor="currency" className="text-white text-[14px] font-jakarta mb-1">Currency</label>
            <small className="text-gray-400 mb-2 text-[12px] font-jakarta">Choose your preferred currency</small>
            <div className="relative">
              <button
                onClick={() => setCurrencyDropdownOpen(!isCurrencyDropdownOpen)}
                className="flex items-center justify-between bg-[#1E1E1E] text-white border border-[#B6B7B9] rounded p-3 text-[14px] w-full"
              >
                <span className="font-jakarta">
                  {currency === "usd" && "$ Dollar (USD)"}
                  {currency === "gbp" && "£ Pounds (GBP)"}
                  {currency === "ngn" && "₦ Naira (NGN)"}
                </span>
                <FaChevronDown className="ml-2" />
              </button>
              {isCurrencyDropdownOpen && (
                <ul className="absolute mt-1 w-full bg-[#1E1E1E] z-10 rounded shadow-lg">
                  <li
                    onClick={() => handleCurrencySelect("usd")}
                    className="p-2 hover:bg-[#B6B7B9] font-jakarta text-[14px] cursor-pointer"
                  >
                    $ Dollar (USD)
                  </li>
                  <li
                    onClick={() => handleCurrencySelect("gbp")}
                    className="p-2 font-jakarta hover:bg-[#B6B7B9] text-[14px] cursor-pointer"
                  >
                    £ Pounds (GBP)
                  </li>
                  <li
                    onClick={() => handleCurrencySelect("ngn")}
                    className="p-2 font-jakarta hover:bg-[#B6B7B9] text-[14px] cursor-pointer"
                  >
                    ₦ Naira (NGN)
                  </li>
                </ul>
              )}
            </div>
          </div> */}

          <div className="flex flex-col mb-4">
            <label htmlFor="language" className="text-white mb-1 font-jakarta">Language</label>
            <small className="text-gray-400 mb-2 font-jakarta">Choose your preferred language</small>
            <div className="relative">
              <button
                onClick={() => setLanguageDropdownOpen(!isLanguageDropdownOpen)}
                className="flex items-center justify-between bg-[#1E1E1E] text-white  rounded p-3 text-[14px] w-full"
              >
                <span className="font-jakarta">
                  {language === "eng" && "English"}
                  {language === "spa" && "Spanish"}
                  {language === "fre" && "French"}
                </span>
                <FaChevronDown className="ml-2" />
              </button>
              {isLanguageDropdownOpen && (
                <ul className="absolute mt-1 w-full bg-[#1E1E1E] z-10 rounded shadow-lg">
                  <li
                    onClick={() => handleLanguageSelect("eng")}
                    className="p-2 hover:bg-[#B6B7B9] font-jakarta text-[14px] cursor-pointer"
                  >
                    English
                  </li>
                  <li
                    onClick={() => handleLanguageSelect("spa")}
                    className="p-2 hover:bg-[#B6B7B9] font-jakarta text-[14px] cursor-pointer"
                  >
                    Spanish
                  </li>
                  <li
                    onClick={() => handleLanguageSelect("fre")}
                    className="p-2 hover:bg-[#B6B7B9] font-jakarta text-[14px] cursor-pointer"
                  >
                    French
                  </li>
                </ul>
              )}
            </div>
          </div>

          <hr className="my-8 border-[#B6B7B9]" />

          <h5 className="text-[20px] font-semibold mb-6 font-jakarta">Security</h5>
          <div className="flex flex-col mb-6">
            <h6 className="mb-1 text-[14px] font-jakarta">Password</h6>
            <small className="text-gray-400 mb-2 text-[12px] font-jakarta">Choose your preferred currency and language</small>
            <form onSubmit={handlePasswordChange} className="flex flex-col space-y-4">
              <div>
                <label htmlFor="currentPassword" className="text-[14px] font-jakarta">Old password</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="currentPassword"
                    className="w-full bg-[#1E1E1E] mt-3 text-white  rounded p-3"
                    required
                  />
                  <span
                    className="absolute right-3 top-7 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>

              <div className="flex space-x-4">
                <div className="w-1/2">
                  <label htmlFor="newPassword" className="text-[14px] font-jakarta">New Password</label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="newPassword"
                      className="w-full bg-[#1E1E1E] mt-3 text-white  font-jakarta rounded p-3"
                      required
                    />
                    <span
                      className="absolute right-3 top-7 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
                <div className="w-1/2">
                  <label htmlFor="newPasswordConfirmed" className="text-[14px] font-jakarta">Confirm New Password</label>
                  <div className="relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      name="newPasswordConfirmed"
                      className="w-full bg-[#1E1E1E] mt-3 font-jakarta text-white rounded p-3"
                      required
                    />
                    <span
                      className="absolute right-3 top-7 cursor-pointer"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="w-[40%] bg-[#1E1E1E] text-[14px] font-jakarta text-white rounded p-3 mt-3"
              >
                Update Password
              </button>
            </form>
          </div>

          <h5 className="text-[14px] font-semibold font-jakarta">2 Factor Authentication</h5>
          <small className="text-gray-400 text-[12px] font-jakarta">Choose your preferred currency and language</small>

          <div className="flex items-center justify-between my-4">
            <div>
              <p className="text-[12px] font-jakarta">Email</p>
            </div>
            <ToggleSwitch
              isActive={toggleStateTwoFactorAuth}
              onToggle={() => handleToggle(setToggleStateTwoFactorAuth, toggleStateTwoFactorAuth, "two_factor_auth")}
            />
          </div>



          <hr className="my-8 border-gray-600" />

          <h5 className="text-[16px] font-semibold font-jakarta">Privacy</h5>

          <div className="flex items-center justify-between mt-5">
            <div>
              <p className="text-[14px] font-jakarta">Hide balance</p>
              <small className="text-[#B6B7B9] text-[12px] font-jakarta">Choose your preferred currency and language</small>

            </div>
            <ToggleSwitch
              isActive={toggleStateHideBalance}
              onToggle={() => handleToggle(setToggleStateHideBalance, toggleStateHideBalance, "hide_balance")}
            />
          </div>

          <hr className="my-8 border-gray-600" />


          <h5 className="text-[16px] font-semibold font-jakarta mb-6">Notifications</h5>
          <div className="flex items-center justify-between mb-6">
            <div>
              <h6 className="mb-1 text-[14px] font-jakarta">Text Message</h6>
            </div>
            <ToggleSwitch
              isActive={toggleStateReceiveEmailNotifications}
              onToggle={() =>
                handleToggle(setToggleStateReceiveEmailNotifications, toggleStateReceiveEmailNotifications, "receive_email_notifications")
              }
            />
          </div>

          <div className="flex items-center justify-between mb-6">
            <div>
              <h6 className="mb-1 text-[14px] font-jakarta">E-mail</h6>
            </div>
            <ToggleSwitch
              isActive={toggleStateReceiveTextNotifications}
              onToggle={() =>
                handleToggle(setToggleStateReceiveTextNotifications, toggleStateReceiveTextNotifications, "receive_text_notifications")
              }
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Settings;
