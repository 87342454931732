import React, { useState } from 'react';
import { GrPowerForceShutdown } from 'react-icons/gr';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

interface WalletBalanceProps {
  currency: string;
  reward_balance: string;
  wallet_balance: string;
  locked_balance: string;
  total_credit: string;
  total_debit: string;
}

const WalletBalance: React.FC<WalletBalanceProps> = ({
  currency,
  reward_balance,
  wallet_balance,
  locked_balance,
  total_credit,
  total_debit,
}) => {
  const initialCurrency =
    localStorage.getItem('selectedCurrency') || currency; // Default to prop currency if localStorage doesn't have it
  const [selectedCurrency, setSelectedCurrency] = useState<string>(
    initialCurrency
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [bankName, setBankName] = useState<string>('');
  const [accountName, setAccountName] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');

  const handleCurrencyChange = (selectedCurrency: string) => {
    setSelectedCurrency(selectedCurrency);
    setIsDropdownOpen(false);
  };

  const handleModalSubmit = () => {
    console.log({ bankName, accountName, accountNumber });
    // Add logic to handle the withdraw submission here
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    setBankName('');
    setAccountName('');
    setAccountNumber('');
    setIsModalOpen(false);
  };

  return (
    <div className="w-full flex flex-col gap-10 lg:w-full bg-[#1E1E1E] rounded-[10px] p-4">
      <div className="flex justify-between">
        <div className="flex justify-between gap-2 items-center">
          <p className="text-[#9A9FA5] text-[14px] font-jakarta">Wallet Balance</p>
          <GrPowerForceShutdown className="text-[#9A9FA5]" />
        </div>

        <div>
          <div className="relative inline-block text-left">
            <div>
              <button
                type="button"
                className="border border-[#6F767E] font-jakarta text-white px-[10px] py-[7px] flex justify-between gap-3 items-center rounded"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {selectedCurrency}
                <MdOutlineKeyboardArrowDown
                  className={`transform ${isDropdownOpen ? 'rotate-180' : ''}`}
                />
              </button>
            </div>

            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-20 z-10 rounded-md shadow-lg bg-[black] ring-1 ring-black ring-opacity-5">
                <div className="py-1">
                  <div
                    className="block px-4 py-2 text-white font-jakarta cursor-pointer"
                    onClick={() => handleCurrencyChange('£')}
                  >
                    £
                  </div>
                  <div
                    className="block px-4 py-2 text-white font-jakarta cursor-pointer"
                    onClick={() => handleCurrencyChange('$')}
                  >
                    $
                  </div>
                  <div
                    className="block px-4 py-2 text-white font-jakarta cursor-pointer"
                    onClick={() => handleCurrencyChange('€')}
                  >
                    €
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="flex justify-between items-center">
          <p className="text-[20px] font-jakarta">
            {selectedCurrency} {wallet_balance}{' '}
          </p>
        </div>

        <div>
          <button
            type="button"
            className="border text-[14px] font-jakarta border-[#33383F] text-[#9A9FA5] px-[20px] py-[10px] rounded"
            onClick={() => setIsModalOpen(true)}
          >
            Withdraw
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-[#1E1E1E] p-6 rounded-lg w-[50%] max-w-md">
            <h2 className="text-white font-jakarta font-bold text-[20px] mb-4">ROI Withdrawal</h2>
            <div className="mb-4">
              <label className="block mb-1">Bank Name</label>
              <input
                type="text"
                className="w-full p-2 rounded bg-[#33383F] outline-none text-white"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className=" font-jakarta mb-1">Account Name</label>
              <input
                type="text"
                className="w-full p-2 rounded bg-[#33383F] outline-none text-white"
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block font-jakarta mb-1">Account Number</label>
              <input
                type="text"
                className="w-full p-2 rounded bg-[#33383F] outline-none text-white"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </div>
            <div className="flex justify-between">
            <button
                className="bg-[#B6B7B9] text-white font-jakarta px-4 py-2 rounded"
                onClick={handleModalCancel}
              >
                Cancel
              </button>
              <button
                className="font-jakarta bg-[#DEA838] font-jakarta text-black px-4 py-2 rounded"
                onClick={handleModalSubmit}
              >
                Submit
              </button>
             
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletBalance;
