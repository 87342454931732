import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import Wrapper from "../../components/Wrapper";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";

// Styled components
const ImgContainer = styled.div`
  width: 100%;
  height: 500px;
  overflow: hidden;
`;

const Img = styled.img`
  border-radius: 10px;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
`;

interface Costings {
  price: string;
}
interface DefaultImage {
  url: string; // Define url as a string
}
interface Booking {
  default_image: { url: string };
  property: {
    name: string;
    location: { country: string };
    costings: Costings;
    default_image?: DefaultImage;
  };
  city: string;
  dates: string;
  check_in: string;
  check_out: string;
  amount: string;
  handleViewDetailsClick: () => void;
}

const Index = () => {
  const [bookingHis, setBookingHis] = useState<Booking[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userData = useSelector((state: any) => state.login.userData);

  useEffect(() => {
    async function fetchHistoryBookings() {
      try {
        const response = await fetch(
          "https://api.maihomm.com/api/user/bookings/history",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${userData?.data?.token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setBookingHis(data.data?.data);
        } else {
          console.error(`Error: ${response.status} - ${response.statusText}`);
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error("Error fetching booking history:", error.message);
        } else {
          console.error("Unexpected error", error);
        }
      }
    }

    fetchHistoryBookings();
  }, [userData]);

  const handleViewDetailsClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDownloadPDF = () => {
    const input = document.getElementById("modalContent");
    if (!input) {
      console.error("Element not found");
      return;
    }

    input.scrollTop = 0;

    html2canvas(input, {
      scrollX: 0,
      scrollY: 0,
      windowWidth: input.scrollWidth,
      windowHeight: input.scrollHeight,
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      const scaledHeight = imgHeight / 1.5; // Adjusted scale for better visibility
      const scaledWidth = imgWidth / 1.5; // Adjusted scale for better visibility

      pdf.addImage(imgData, "PNG", 0, position, scaledWidth, scaledHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - scaledHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, scaledWidth, scaledHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("download.pdf");
    });
  };

  return (
    <Wrapper>
      <Link to="/bookings">
        <button className="flex font-jakarta items-center text-primary-500 rounded px-3 py-1 text-[14px] gap-3 font-medium hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-500">
          <MdKeyboardArrowLeft />
          Booking History
        </button>
      </Link>

      {bookingHis.map((booking, index) => (
        <div
          key={index}
          className="flex justify-between w-[70%] items-center my-[30px] border border-[#9A9FA5] p-5 rounded-[10px]"
        >
          <div className="flex gap-3">
            <div>
              <img src={booking?.property?.default_image?.url} alt="detailimg" className="h-[100px] w-[150px]"/>
            </div>
            <div>
              <p className="text-[14px] font-jakarta">
              {booking.property.name}
              </p>
              <p className="text-[12px] font-jakarta text-[#9A9FA5]">
                {booking.property.location.country}
              </p>
              <p className="text-[12px] font-jakarta text-[#9A9FA5]">
                {booking.check_in + "-" + booking.check_out}
              </p>
              <p className="text-[14px] font-jakarta">
                {booking.property.costings.price}
              </p>
            </div>
          </div>
          <div>
            <p
              className="bg-[#1E1E1E] border font-jakarta border-[#6F767E] rounded-[10px] p-3 cursor-pointer"
              onClick={handleViewDetailsClick}
            >
              View detail
            </p>
          </div>
        </div>
      ))}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-100">
        <div
          id="modalContent"
          className="bg-[#1E1E1E] border custom-scroll-content h-[70vh] border-[#6F767E] p-6 rounded-lg shadow-lg w-[30%]"
        >
          <div className="flex w-full justify-center items-center">
            <img
              src="/assets/images/logo.png"
              alt="logo"
              className="cursor-pointer h-[30px] w-[20%]"
              onClick={handleCloseModal}
            />
          </div>
          <p className="text-[13px] text-center font-jakarta mb-4 text-[#9A9FA5]">
            Lagos: 4A Opeyemisi Bamidele Crescent, off Freedom way, Lekki
            phase 1, Lagos, Nigeria. || London: 128 City Road, London, United
            Kingdom, EC1V 2NX
          </p>
          <p className="text-[13px] text-center font-jakarta mb-4 text-[#9A9FA5]">
            info@maihomm.com || support@maihomm.com
          </p>
          <p className="mb-4 text-center text-[16px] font-jakarta">
            Monthly ROI Payment Receipt
          </p>
          <div className="flex justify-center gap-3 font-jakarta py-[15px]">
            <div>
              <p className="text-[#9A9FA5] text-[13px] font-jakarta">
                DATE OF ISSUE
              </p>
              <p className="text-[13px] font-jakarta">01/04/2023</p>
            </div>
            <div>
              <p className="text-[#9A9FA5] font-jakarta text-[13px]">
                RECEIPT NUMBER
              </p>
              <p className="text-[13px] font-jakarta">01042023</p>
            </div>
          </div>
          <div className="w-full bg-[#232323] p-4 my-[10px]">
            <p className="text-center text-[14px] font-jakarta pb-[15px]">
              INVESTOR INFORMATION
            </p>
            <div className="flex justify-between">
              <div>
                <p className="text-[13px] text-[#9A9FA5] font-jakarta pb-[15px]">
                  Investor Name
                </p>
                <p className="text-[13px] text-[#9A9FA5] font-jakarta">
                  Contact Information
                </p>
              </div>
              <div>
                <p className="text-[13px] text-end pb-[15px] font-jakarta">
                  Joe Wilson
                </p>
                <p className="text-[13px] text-end font-jakarta">
                  Joewilson@gmail.com
                </p>
              </div>
            </div>
          </div>
          <div className="w-full bg-[#232323] p-4 my-[10px]">
            <p className="text-center text-[14px] font-jakarta pb-[15px]">
              PAYMENT DETAILS
            </p>
            <div className="flex justify-between">
              <div>
                <p className="text-[13px] text-[#9A9FA5] font-jakarta">
                  Payment Date
                </p>
                <p className="text-[13px] text-[#9A9FA5] font-jakarta py-[15px]">
                  Payment Period
                </p>
                <p className="text-[13px] text-[#9A9FA5] font-jakarta">
                  Payment Amount
                </p>
              </div>
              <div>
                <p className="text-[13px] text-end font-jakarta">
                  01/02/2023
                </p>
                <p className="text-[13px] text-end font-jakarta py-[15px]">
                  ROI for May
                </p>
                <p className="text-[13px] text-end font-jakarta">
                  £ 1,399.00
                </p>
              </div>
            </div>
          </div>
          <div className="w-full bg-[#232323] p-4 my-[10px]">
            <p className="text-center text-[14px] font-jakarta pb-[15px]">
              INVESTMENT DETAILS
            </p>
            <div className="flex justify-between">
              <div>
                <p className="text-[13px] text-[#9A9FA5] font-jakarta pb-[15px]">
                  Investment Amount
                </p>
                <p className="text-[13px] text-[#9A9FA5] font-jakarta">
                  Package Plan
                </p>
              </div>
              <div>
                <p className="text-[13px] text-end pb-[15px] font-jakarta">
                  £ 10,000.00
                </p>
                <p className="text-[13px] text-end font-jakarta">
                  Dynamic Plan
                </p>
              </div>
            </div>
          </div>
          <p className="text-center text-[#9A9FA5] text-[12px] font-jakarta py-[15px]">
            Please keep this receipt for your records. If you have any
            questions or concerns, please feel free to reach out to us.
          </p>
          <div className="w-full flex justify-center pt-[15px]">
            <img src="/assets/images/signature.png" alt="signature" />
          </div>
          <p className="text-center font-jakarta text-[#9A9FA5] text-[12px] py-[15px]">
            John Smith
          </p>
          <p className="text-center font-jakarta text-[#9A9FA5] text-[12px]">
            Finance Director
          </p>
          <div className="flex justify-center mt-4">
            <button
              onClick={handleDownloadPDF}
              className="bg-[#DEA838] text-white py-2 px-4 rounded hover:bg-[#DEA838] focus:outline-none"
            >
              Download
            </button>
          </div>
        </div>
      </div>
      )}
    </Wrapper>
  );
};

export default Index;
