import React, { useEffect, useState } from 'react';
import { FaRegHandshake, FaRegHeart } from 'react-icons/fa';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';



interface InvestmentProps {
  investments: any;
}

const FutureProperty: React.FC<InvestmentProps> = ({ investments }) => {
  const futureinvest = investments.investment_opportunity || [];
  console.log(investments, "oppoet")
  const userData = useSelector((state: any) => state.login.userData);
  const [wishlist, setWishlist] = useState<Array<{ id: string }>>([]);
  const [cartlist, setCartlist] = useState<Array<{ id: string }>>([]);
  
  // Fetch base URL from env variables
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleFavoriteClick = async (id: string) => {
    try {
      const response = await fetch(`${baseUrl}/user/properties/save`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.data.token}`,
        },
        body: JSON.stringify({ property_id: id }),
      });
      const result = await response.json();
      toast.success(result.message);
    } catch (error) {
      console.error('Error making favorite request:', error);
    }
  };
  const handleCartClick = async (id: string) => {
    try {
      const response = await fetch(`${baseUrl}/user/carts/items/add/${id}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.data.token}`,
        },
       
      });
      const result = await response.json();
      toast.success(result.message);
    } catch (error) {
      console.error('Error making favorite request:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!userData || !userData.data.token) return;

      try {
        const response = await fetch(`${baseUrl}/user/properties/saved/properties`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData.data.token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        setWishlist(result?.data?.saved_properties || []);
      } catch (error) {
        console.error("Error fetching user overview:", error);
      }
    };

    fetchData();
  }, [baseUrl, userData]);

  useEffect(() => {
    const fetchCartData = async () => {
      if (!userData || !userData.data.token) return;

      try {
        const response = await fetch(`${baseUrl}/user/carts/items`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData.data.token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        console.log(result, "cart list")
        setCartlist(result?.data || []);
      } catch (error) {
        console.error("Error fetching user overview:", error);
      }
    };

    fetchCartData();
  }, [baseUrl, userData]);

  return (
    <div className='lg:w-[90%] w-full pb-[30px]'>
      <div className="w-full flex justify-between my-5">
        <p className='text-[14px] font-jakarta'>Investment Opportunities</p>
        <div className='text-[#DEA838] flex items-center gap-1'>
          <p className='text-[14px] font-jakarta'>View All</p>
          <MdOutlineKeyboardArrowRight />
        </div>
      </div>
      <div className="flex lg:flex-row flex-col lg:items-center lg:gap-0 gap-3 lg:space-x-4">
      <div className="flex lg:flex-row flex-col lg:gap-0 gap-4 lg:space-x-4">
        {futureinvest?.slice(0, 2).map((property: any, index: any) => {
          const isFavorite = wishlist.some(item => item.id === property.id);
          const isCart = cartlist.some(item => item.id === property.id);

          return (
            <div key={index} className="flex flex-col lg:w-1/2 w-full bg-[#1E1E1E]">
              <img src={property.default_image?.url || "/assets/images/default-image.svg"} alt="Property" className="w-full h-full object-cover mb-2" />
              <div className='p-4'>
                <p className='text-[16px] font-jakarta leading-[30px]'>{property.name || "Default Title"}</p>
                <p className='text-[13px] font-jakarta leading-[30px] text-gray-400'>{property.location.address || "Default Address"}</p>
              </div>
              <div className='p-4'>
                <div className='flex justify-between'>
                  <p className='text-[16px] font-jakarta'>{property.costings.per_Slot || "£0.00 per slot"}</p>
                  <div className='flex gap-2 items-center'>
                    <FaRegHeart
                      onClick={() => handleFavoriteClick(property.id)}
                      className={isFavorite ? 'text-yellow-500' : 'text-gray-400'}
                    />
                    <FaRegHandshake   onClick={() => handleCartClick(property.id)}  className={isCart ? 'text-yellow-500' : 'text-gray-400'}/>
                  </div>
                </div>
                <hr className="border-t-2 border-[#B6B7B9] mt-2" />
                <div className='flex justify-between my-4'>
                  <div className='flex gap-2 items-center'>
                    <img src="/assets/images/Bed.svg" alt='bed' className="h-[20px] w-[20px]" />
                    <p className='font-jakarta text-[13px] text-gray-400'>{property.features.bedrooms || "0"} Bedrooms</p>
                  </div>
                  <div className='flex gap-2 items-center'>
                    <img src="/assets/images/bathtub.svg" alt='bath' className="h-[20px] w-[20px]" />
                    <p className='font-jakarta text-[13px] text-gray-400'>{property.features.bathrooms || "0"} Bathrooms</p>
                  </div>
                  <div className='flex gap-2 items-center'>
                    <img src="/assets/images/dashboard.svg" alt='size' className="h-[20px] w-[20px]" />
                    <p className='font-jakarta text-[13px] text-gray-400'>{property.features.sqft || "0 Sq.Ft"}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      </div>
    </div>
  );
};

export default FutureProperty;
