import { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import { GrPowerForceShutdown } from "react-icons/gr";
import { MdOutlineArrowBackIos, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import styled from "@emotion/styled";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { LiaTimesSolid } from "react-icons/lia";

type IncomeRowProps = {
  index: number;
};

const IncomeRow = styled.div<IncomeRowProps>`
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.index % 2 === 0 && "#292929"};
`;

const IncomeHistory = () => {
  const [currency, setCurrency] = useState<string>(localStorage.getItem("selectedCurrency") || "£");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null); // For storing the clicked item details
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("selectedCurrency", currency);
  }, [currency]);

  const handleCurrencyChange = (selectedCurrency: string) => {
    setCurrency(selectedCurrency);
    setIsDropdownOpen(false);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  const handleImageClick = (item: any) => {
    console.log(item, "selected")
    setSelectedItem(item); // Set the clicked item's data
    toggleModal(); // Open the modal
  };

  // Data array for the table
  const tableData = [
    {
      month: "January",
      property: "Unit 3 work Waringhton",
      reference: "123444",
      earning: "200,000",
      date: "01/12/2024",
      download: "/path/to/proof.png",
    },
    {
      month: "January",
      property: "Unit 31 work Waringhton",
      reference: "4523444",
      earning: "200,000",
      date: "01/12/2024",
      download: "/path/to/proof.png",
    },
    {
      month: "January",
      property: "Unit 1 work Waringhton",
      reference: "786644",
      earning: "200,000",
      date: "01/12/2024",
      download: "/path/to/proof.png",
    },
    {
      month: "January",
      property: "Unit 345 work Waringhton",
      reference: "123444",
      earning: "200,000",
      date: "01/12/2024",
      download: "/path/to/proof.png",
    },
    // Add more rows here as needed
  ];
  const downloadSelectedPDF = () => {
    if (!selectedItem) return; // Ensure there's a selected item
  
    const pdf = new jsPDF();
    pdf.setFontSize(16);
    pdf.text("Monthly ROI Payment Receipt", 10, 20);
  
    // Add receipt details to the PDF
    pdf.setFontSize(12);
    pdf.text(`Date of Issue: ${selectedItem.date}`, 10, 40);
    pdf.text(`Receipt Number: 01042023`, 10, 50); // Static or dynamic as required
    pdf.text(`Property: ${selectedItem.property}`, 10, 60);
    pdf.text(`Reference: ${selectedItem.reference}`, 10, 70);
    pdf.text(`Earning: ${selectedItem.earning}`, 10, 80);
    pdf.text(`Month: ${selectedItem.month}`, 10, 90);
  
    // Footer or additional info
    pdf.setFontSize(10);
    pdf.text(
      "Lagos: 4A Opeyemisi Bamidele Crescent, Lekki phase 1, Lagos, Nigeria.",
      10,
      110
    );
    pdf.text(
      "London: 128 City Road, London, United Kingdom, EC1V 2NX.",
      10,
      120
    );
    pdf.text("info@maihomm.com || support@maihomm.com", 10, 130);
  
    // Save the PDF
    pdf.save(`Receipt_${selectedItem.reference}.pdf`);
  };
  return (
    <Wrapper>
      <div className="w-full flex flex-col gap-10 lg:w-[46%] bg-transparent rounded-[10px] p-4">
        <div className="flex justify-between">
          <div className="flex justify-between gap-2 cursor-pointer items-center" onClick={handleBackClick}>
          <MdOutlineArrowBackIos className="text-[#9A9FA5] cursor-pointer"  />
            <p className="text-white text-[14px] font-jakarta">ROI pay-in</p>
          </div>

          <div>
            <div className="relative inline-block text-left">
              <div>
                <button
                  type="button"
                  className="border border-[#6F767E] text-white font-jakarta px-[10px] py-[7px] flex justify-between gap-3 items-center rounded"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  {currency}
                  <MdOutlineKeyboardArrowDown
                    className={`transform ${isDropdownOpen ? "rotate-180" : ""}`}
                  />
                </button>
              </div>

              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-20 z-10 rounded-md shadow-lg bg-[black] ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    <div
                      className="block px-4 py-2 text-white font-jakarta cursor-pointer"
                      onClick={() => handleCurrencyChange("£")}
                    >
                      £
                    </div>
                    <div
                      className="block px-4 py-2 text-white font-jakarta cursor-pointer"
                      onClick={() => handleCurrencyChange("$")}
                    >
                      $
                    </div>
                    <div
                      className="block px-4 py-2 text-white font-jakarta cursor-pointer"
                      onClick={() => handleCurrencyChange("€")}
                    >
                      €
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col gap-12 lg:w-[46%] border border-[#1E1E1E] bg-transparent rounded-[10px] p-4">
        <div className="flex flex-col h-full items-start justify-start">
          <div className="flex gap-2 items-center">
            <p className="text-[#9A9FA5] cursor-pointer font-jakarta">All earnings</p>
            <GrPowerForceShutdown className="text-[#9A9FA5]" />
          </div>
          <div className="flex justify-between items-center">
            <p className="text-[20px] font-jakarta">{currency} 0</p>
          </div>
        </div>
      </div>

      <div className="mt-4 flex flex-col gap-4">
        <div className="flex justify-between items-center mb-4">
          <div className="relative flex items-center">
            <input
              type="text"
              placeholder="Search..."
              className="border border-[#6F767E] rounded-[7px] font-jakarta text-white px-3 py-2 rounded-l-md bg-transparent placeholder-[#e9e9ea]"
            />
            <FiSearch className="absolute right-2 text-[#e9e9ea]" />
          </div>
          <div className="flex gap-2">
           
            <button
              className="border border-[#6F767E] flex items-center gap-2 rounded-[7px] text-white px-4 py-2 bg-[#292929]"
              onClick={toggleModal}
            >
              {/* <HiOutlineDocumentDownload /> */}
              Download
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="text-white bg-[#33383F]">
                <th className="py-2 px-4 text-start text-[14px] font-jakarta">MONTH</th>
                <th className="py-2 px-4 text-start text-[14px] font-jakarta">PROPERTY</th>
                <th className="py-2 px-4 text-start text-[14px] font-jakarta">REFERENCE</th>
                <th className="py-2 px-4 text-start text-[14px] font-jakarta">EARNING</th>
                <th className="py-2 px-4 text-start text-[14px] font-jakarta">DATE</th>
                <th className="py-2 px-4 text-start text-[14px] font-jakarta">DOWNLOAD</th>
              </tr>
            </thead>
            <tbody className="text-gray-300">
              {tableData.map((row, index) => (
                <tr key={index} className="cursor-pointer">
                  <td className="py-4 px-4 border-b border-[#33383F] font-jakarta">{row.month}</td>
                  <td className="py-4 px-4 border-b border-[#33383F] font-jakarta">{row.property}</td>
                  <td className="py-4 px-4 border-b border-[#33383F] font-jakarta">{row.reference}</td>
                  <td className="py-4 px-4 border-b border-[#33383F] font-jakarta">{row.earning}</td>
                  <td className="py-4 px-4 border-b border-[#33383F] font-jakarta">{row.date}</td>
                  {/* <td className="py-4 px-4 border-b border-[#33383F] font-jakarta">
                    <img
                      src="/path/to/proof.png"
                      alt="proof"
                      className="cursor-pointer w-[20px]"
                      onClick={() => handleImageClick(row)}
                    />
                    
                  </td> */}
                  <td className="py-4 px-4 border-b border-[#33383F] font-jakarta text-start text-[12px]">
                    <p   onClick={() => handleImageClick(row)} className="text-[#DEA838] font-jakarta hover:underline">proof.png</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex  items-center justify-center bg-black bg-opacity-50 z-100">
          <div className="bg-[#1E1E1E] border custom-scroll-content h-[70vh] border-[#6F767E] p-6 rounded-lg shadow-lg w-[30%]">
            <div className="flex justify-start w-full">
            <LiaTimesSolid onClick={handleCloseModal}/>

            </div>
           <div className="flex w-full justify-center items-center">
            <img src="/assets/images/logo.png" alt="logo" className="cursor-pointer h-[30px] w-[20%]" onClick={handleCloseModal}/>

           </div>
            <p className="text-[12px] text-center mb-4 text-[#9A9FA5] font-jakarta">Lagos: 4A Opeyemisi Bamidele Crescent, off Freedom way, Lekki phase 1, Lagos, Nigeria. ||  London: 128 City Road, London, United Kingdom, EC1V 2NX</p>
            <p className="text-[12px] text-center mb-4 text-[#9A9FA5] font-jakarta">info@maihomm.com || support@maihomm.com</p>
            <p className="mb-4 text-center text-[14px] font-jakarta">Monthly ROI Payment Receipt</p>
            <div className="flex justify-center gap-3 py-[13px]">
              <div>
                <p className="text-[#9A9FA5] text-[13px] font-jakarta">DATE OF ISSUE</p>
                <p className="text-[12px] font-jakarta">{selectedItem.date}</p>
              </div>
              <div>
                <p className="text-[#9A9FA5] text-[12px] font-jakarta">RECEIPT NUMBER</p>
                <p className="text-[12px] font-jakarta">01042023</p>
              </div>
            </div>
            <div className="w-full bg-[#232323] p-4 my-[10px]">
              <p className="text-center text-[12px] pb-[15px] font-jakarta">INVESTOR INFORMATION</p>
              <div className="flex justify-between">
                <div>
                  <p className="text-[12px] text-[#9A9FA5] font-jakarta pb-[15px]">Investor Name</p>
                  <p className="text-[12px] text-[#9A9FA5] font-jakarta">Contact Information</p>
                </div>
                <div>
                  <p className="text-[12px] text-end pb-[15px] font-jakarta">Joe Wilson</p>
                  <p className="text-[12px] text-end font-jakarta">Joewilson@gmail.com</p>
                </div>
              </div>
            </div>
            <div className="w-full bg-[#232323] p-4 my-[10px]">
              <p className="text-center text-[13px] font-jakarta pb-[13px]">PAYMENT DETAILS</p>
              <div className="flex justify-between">
                <div>
                  <p className="text-[12px] font-jakarta text-[#9A9FA5]">Payment Date</p>
                  <p className="text-[12px] font-jakarta text-[#9A9FA5] py-[15px]">Payment Period</p>
                  <p className="text-[12px] font-jakarta text-[#9A9FA5]">Payment Amount</p>
                </div>
                <div>
                  <p className="text-[12px] font-jakarta text-end">01/02/2023</p>
                  <p className="text-[12px] font-jakarta text-end py-[15px]">ROI for May</p>
                  <p className="text-[12px] font-jakarta text-end"> £ 1,399.00</p>
                </div>
              </div>
            </div>
            <div className="w-full bg-[#232323] p-4 my-[10px]">
              <p className="text-center text-[12px] font-jakarta pb-[15px]">INVESTMENT DETAILS</p>
              <div className="flex justify-between">
              <div>
                  <p className="text-[12px] font-jakarta text-[#9A9FA5] pb-[15px]">Property Name</p>
                  <p className="text-[12px] font-jakarta text-[#9A9FA5]">Investment Amount</p>
                </div>
               
                <div>
                  <p className="text-[12px] font-jakarta text-end pb-[15px]">{selectedItem.property}</p>
                  <p className="text-[12px] font-jakarta text-end">{selectedItem.reference}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-center items-center">
            <button
               onClick={downloadSelectedPDF}
            
              className="text-[#DEA838] font-jakarta text-[14px]  px-4 py-2 rounded-[7px]"
            >
              Download PDF
            </button>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default IncomeHistory;
