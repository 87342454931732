import React, { useEffect } from "react"
import { GrPowerForceShutdown } from "react-icons/gr";
import { FaRegStar } from "react-icons/fa6";


interface RewardBalanceProps {
  currency: string;
  reward_balance: string;
  locked_balance: string;
  total_credit: string;
  total_debit: string;
}

const RewardBalance: React.FC<RewardBalanceProps> = ({ currency, reward_balance, locked_balance, total_credit, total_debit }) => {
console.log(locked_balance, "base")
  return (
    <div className="w-full flex flex-col gap-12 lg:w-full bg-[#1E1E1E] rounded-[10px] px-4 py-7">
                <div className="flex justify-between">
                    <div className="flex justify-start gap-2 items-center">
                        <p className="text-[#9A9FA5] text-[14px] font-jakarta">Reward Balance</p>
                        <GrPowerForceShutdown className="text-[#9A9FA5]" />
                    </div>
                    <div>
                    <FaRegStar className="text-[25px]"/>

                    </div>

                </div>

                <div className="flex justify-between">
                    <div className="flex justify-between items-center">
                        <p className="text-[20px] font-jakarta">{currency} {reward_balance}</p>
                    </div>

                    <div>
                        <div className="relative inline-block text-left">
                            <div>
                                {/* <button
                                    type="button"
                                    className="border border-[#33383F] text-[#9A9FA5] px-[20px] py-[10px] rounded"
                                >
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  );
};


export default RewardBalance;
