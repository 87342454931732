import React, { useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { BsUpload } from 'react-icons/bs';
import { createTrue } from 'typescript';
import { useNavigate } from 'react-router-dom';

interface ModalContentProps {
    property: any;
    close: () => void;
    
}


interface NewModalProps {
    propertySelected: any;
    close: () => void;
    slotSelected: any;
    
}
const NewModal: React.FC<NewModalProps> = ({ close, propertySelected, slotSelected }) => {
    const [slots, setSlots] = useState(slotSelected);
    const [amount, setAmount] = useState('');
    const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
    const [isSecondModalOpens, setIsSecondModalOpens] = useState(false);
    const pricePerSlot = propertySelected?.investment_cost;
    const maxSlots = propertySelected?.slots || 0; // maximum available slots

    console.log(propertySelected, "new modal property selected");
    console.log(slotSelected, "new modal");

    const increaseSlots = () => {
        if (slots < maxSlots) { // Check if slots are less than maxSlots
            setSlots(slots + 1);
        }
    };

    const decreaseSlots = () => {
        if (slots > 1) {
            setSlots(slots - 1);
        }
    };

    const handleSubmit = () => {
        setIsSecondModalOpen(true);
    };

    const handleSubmits = () => {
        setIsSecondModalOpens(true);
    };

    const navigate = useNavigate();
    const totalPrice = pricePerSlot * slots;
    const totalPriceWithAdditional = totalPrice + 100;

    return (
        <div className="flex items-center justify-center h-screen">
        {isSecondModalOpen ? (
            <>
                {isSecondModalOpens ? (
                    <div className="w-[100%] bg-[#1E1E1E] rounded-[10px] border border-[#6F767E] p-7">
                        <div className="flex flex-row-reverse justify-between">
                            <MdOutlineClose onClick={close} className="flex justify-end" />
                        </div>
                        <div className="flex flex-col text-center font-jakarta align-middle items-center justify-center">
                            <img src="/assets/images/success.svg" className="h-[70px] w-[70px] mb-[10px]" />
                            <p className="text-[20px] font-[600] font-jakarta w-[60%]">Slot sales in process</p>
                            <p className="text-[#9A9FA5] text-[14px] font-jakarta w-[80%]">
                                The process of selling your slots will take a month.
                            </p>
                            <button
                                className="my-[30px] font-jakarta py-[12px] w-full font-[700] rounded-[10px] px-[20px] bg-[#DEA838] text-black"
                                onClick={() => navigate("#")}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="w-[40%] bg-[#1E1E1E] rounded-[10px] justify-center border border-[#6F767E] p-7">
                        <div className="flex flex-row-reverse justify-between">
                            <MdOutlineClose onClick={close} className="flex justify-end" />
                        </div>
                        <p className="text-[25px] font-jakarta">Confirm Sale</p>
                        <p className="text-[#9A9FA5] text-[12px] text-center py-[10px] font-jakarta">
                            You have attempted to sell {slots} out of your {maxSlots} slots in the Unit 17, The works, Warrington.
                            The process of selling your slots will take a month. Do you want to continue or cancel your transactions?
                        </p>
                        <div className="flex justify-center items-center gap-3 w-full my-[10px]">
                            <button
                                onClick={close}
                                className="text-[#DEA838] border border-[#DEA838] font-jakarta bg-transparent p-2 w-[50%] rounded"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleSubmits}
                                className="bg-[#DEA838] font-jakarta text-black p-2 w-[50%] rounded"
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                )}
            </>
        ) : (
            <div className="w-[130%] bg-[#1E1E1E] rounded-[10px] border border-[#6F767E] p-7">
                <div className="flex flex-row-reverse justify-between">
                    <MdOutlineClose onClick={close} className="flex justify-end" />
                    <p className="font-jakarta">Sell Slot</p>
                </div>
                <div className="flex items-center gap-3 justify-start border my-[10px] rounded-[10px] border-[#33383F] p-5">
                    <div>
                        <img src={propertySelected?.property?.default_image?.url} alt="house" />
                    </div>
                    <div className="flex flex-col justify-start text-start">
                        <p className="text-[14px] font-jakarta">{propertySelected?.property?.name}</p>
                        <p className="text-[10px] font-jakarta text-[#9A9FA5]">{propertySelected?.property?.location?.address}</p>
                    </div>
                </div>
                <div className="flex justify-between my-[15px] items-center">
                    <p className="text-[14px] text-[#9A9FA5] font-jakarta">Price per slot</p>
                    <p className="text-[14px] font-jakarta">{pricePerSlot.toLocaleString()}</p>
                </div>
                <div className="flex justify-between mb-[15px] items-center">
                    <div className="flex flex-col justify-start text-start">
                        <p className="text-[14px] font-jakarta">Number of slots</p>
                        <p className="text-[10px] font-jakarta text-[#9A9FA5]">
                            You can buy at least 1 and at most {maxSlots} slots
                        </p>
                    </div>
                    <div className="flex gap-10 rounded-[10px] items-center border border-[#d1cfcf]">
                        <span className="text-[14px] font-jakarta px-[10px] font-semibold">{slots}</span>
                        <div className="border px-[5px] font-jakarta border-[#d1cfcf]">
                            <button
                                onClick={increaseSlots}
                                className="flex items-center justify-center"
                            >
                                <IoIosArrowUp />
                            </button>
                            <button
                                onClick={decreaseSlots}
                                className="flex items-center justify-center"
                            >
                                <IoIosArrowDown />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <p className="text-[14px] font-jakarta text-[#9A9FA5]">Total Price</p>
                    <p className="text-[14px] font-jakarta">{totalPrice.toLocaleString()}</p>
                </div>
                <div className="flex justify-end items-end w-full font-jakarta my-[10px]">
                    <button
                        onClick={handleSubmit}
                        className="bg-[#DEA838] text-black p-2 w-[50%] rounded"
                    >
                        Sell Slot
                    </button>
                </div>
            </div>
        )}
    </div>
    );
};


const ModalContent: React.FC<ModalContentProps> = ({ close, property }) => {
    
    const [slots, setSlots] = useState(1);
    const [amount, setAmount] = useState('');
    const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
    // First Dropdown
    const [isFirstDropdownOpen, setIsFirstDropdownOpen] = useState(false);
    const [firstSelectedOption, setFirstSelectedOption] = useState('Select property');
    const [selectedPropertySlots, setSelectedPropertySlots] = useState<number[]>([]); // Store slots of the selected property

    // Second Dropdown
    const [isSecondDropdownOpen, setIsSecondDropdownOpen] = useState(false);
    const [secondSelectedOption, setSecondSelectedOption] = useState('no of slot');
    const [selectedProperty, setSelectedProperty] = useState({})
    const pricePerSlot = 300000;
const [error, setError] = useState(false)
    const handleSubmit = () => {
        // Ensure both property and slot are selected before proceeding
        if (firstSelectedOption !== 'Select property' && secondSelectedOption !== 'no of slot') {
            console.log('Amount submitted:', amount);
            setIsSecondModalOpen(true);
        } else {
            setError(true)
            setTimeout(()=>{
                setError(false)
            },2000)
        }
    };

    // First Dropdown Handlers
    const toggleFirstDropdown = () => {
        setIsFirstDropdownOpen(!isFirstDropdownOpen);
    };

    const handleFirstOptionSelect = (option: any) => {
        setSelectedProperty(option)
        setFirstSelectedOption(option.property.name);
        setSelectedPropertySlots(Array.from({ length: option.slots }, (_, i) => i + 1)); // Set slots as an array of numbers
        setIsFirstDropdownOpen(false);
    };

    // Second Dropdown Handlers
    const toggleSecondDropdown = () => {
        setIsSecondDropdownOpen(!isSecondDropdownOpen);
    };

    const handleSecondOptionSelect = (option: number) => {
        setSecondSelectedOption(` ${option}`);
        setIsSecondDropdownOpen(false);
    };

    const totalPrice = pricePerSlot * slots;
    const totalPriceWithAdditional = totalPrice + 100;

    return (
        <>
        {!isSecondModalOpen ? (
            <div className="w-[150%] bg-[#1E1E1E] rounded-[10px] border border-[#6F767E] p-7">
                <div className='flex flex-row-reverse justify-between'>
                    <MdOutlineClose onClick={close} className='flex justify-end' />
                    <p className='font-jakarta'>Sell Slot</p>
                </div>
                
                {/* First Dropdown Component */}
                <div className="relative my-4 w-full">
                    <p className="text-[14px] text-start font-jakarta py-[10px]">Property</p>
                    <button
                        onClick={toggleFirstDropdown}
                        className={`bg-transparent border font-jakarta ${error ? "border-red-500":"border-[#33383F]"}  text-white p-0 w-full rounded flex justify-between items-center`}
                    >
                        {firstSelectedOption}
                        <span className="ml-2 text-[#6F767E] text-[30px] font-jakarta">&#9662;</span>
                    </button>
                    {isFirstDropdownOpen && (
                        <ul className="absolute font-jakarta bg-[#444] text-white w-full text-start rounded mt-2 z-10">
                            {property.investments.map((item: any, index: any) => (
                                <li
                                    key={index}
                                    onClick={() => handleFirstOptionSelect(item)}
                                    className="p-3 hover:bg-[#555] cursor-pointer"
                                >
                                    {item.property.name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                {/* Second Dropdown Component */}
                <div className="relative my-4 w-full">
                    <p className="text-[14px] text-start font-jakarta py-[10px]">Number of slot</p>
                    <button
                        onClick={toggleSecondDropdown}
                        className={`bg-transparent border font-jakarta ${error ? "border-red-500":"border-[#33383F]"} text-white p-0 w-full rounded flex justify-between items-center`}
                    >
                        {secondSelectedOption}
                        <span className="ml-2 text-[#6F767E] font-jakarta text-[30px]">&#9662;</span>
                    </button>
                    {isSecondDropdownOpen && (
                        <ul className="absolute bg-[#444] text-white w-full text-start rounded mt-2 z-10">
                            {selectedPropertySlots.map((slot) => (
                                <li
                                    key={slot}
                                    onClick={() => handleSecondOptionSelect(slot)}
                                    className="p-3 font-jakarta hover:bg-[#555] cursor-pointer"
                                >
                                    {slot}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className='flex w-full my-[10px]'>
                    <button
                        onClick={handleSubmit}
                        className="bg-[#DEA838] font-jakarta text-black p-2 w-full font-[700] rounded"
                    >
                        Submit
                    </button>
                </div>
            </div>
        ) : (
            <NewModal close={() => setIsSecondModalOpen(false)} slotSelected={secondSelectedOption} propertySelected={selectedProperty}/>
        )}
    </>
    );
};


export default ModalContent;
