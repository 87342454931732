import { useState, useEffect } from "react";
import Wrapper from "../../components/Wrapper";
import { CircularProgress } from "@mui/material";
import TopBar from "../../components/topNav";
import { toast } from "react-toastify";
import PortfolioOverview from "./PorfolioOverview";
import queryString from "query-string";
import PendingInvestments from "./PendingInvestments";
import ActiveInvestments from "./ActiveInvestments";
import { useSelector } from "react-redux";
import { LiaTimesSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import DeclineInvestments from "./DeclinedInvestment";

const Portfolio = () => {
  const navigate = useNavigate();
  let initVal = 'Pending';
  const queryParams = queryString.parse(window.location.search);
  const { show } = queryParams;
  if (show === "activeInvestments") {
    initVal = 'Active';
  }

  const [value, setValue] = useState(initVal);
  const [loading, setLoading] = useState(false);
  const [investments, setInvestMents] = useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false); // State to handle modal visibility

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  const handleCloseModal = () => setShowModal(false); // Function to close modal

  const handleUpgradeClick = () => {
    navigate('/auth/kyc'); // Route to /auth/kyc when clicked
  };

  const userData = useSelector((state: any) => state.login.userData);
  useEffect(() => {
    if (userData?.data?.user_type === "Guest") {
      setShowModal(true);
    }
  }, []);
    const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const getInvestmentHistory = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${baseUrl}/user/portfolio`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${userData?.data?.token}`,
          }
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to fetch investments');
        }

        const resData = await response.json();
        console.log(resData, "base");
        setInvestMents(resData.data);
      } catch (err: any) {
        console.error(err);
        toast.error(err.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    };

    getInvestmentHistory();
  }, [value]);

  return (
    <Wrapper>
       {!showModal && (
        <>
      <PortfolioOverview investments={investments}/>
      <div className="">
        <div className="bg-[#1E1E1E] lg:w-[63%] w-full rounded-3xl p-1">
          <div className="flex space-x-4" aria-label="Portfolio Tab">
            <div
              className={`cursor-pointer font-jakarta w-full px-4 py-2 ${value === 'Pending' ? 'bg-[#DEA838] text-black rounded-3xl' : 'text-white'
                }`}
              onClick={() => handleChange('Pending')}
              style={{ textTransform: 'none', fontSize: '14px', fontWeight: '400' }}
            >
              Pending Investments
            </div>
            <div
              className={`cursor-pointer font-jakarta w-full px-4 py-2 ${value === 'Active' ? 'bg-[#DEA838] text-black rounded-3xl' : 'text-white'
                }`}
              onClick={() => handleChange('Active')}
              style={{ textTransform: 'none', fontSize: '14px', fontWeight: '400' }}
            >
              Active Investments
            </div>
            <div
              className={`cursor-pointer font-jakarta w-full px-4 py-2 ${value === 'Decline' ? 'bg-[#DEA838] text-black rounded-3xl' : 'text-white'
                }`}
              onClick={() => handleChange('Decline')}
              style={{ textTransform: 'none', fontSize: '14px', fontWeight: '400' }}
            >
              Declined Investments
            </div>
          </div>
        </div>

        <div className="pt-6">
        {loading ? (
                <div className="flex justify-center">
                  <CircularProgress size={24} />
                </div>
              ) : investments ? (
                value === "Pending" ? (
                  <PendingInvestments investments={investments} />
                ) : value === "Active" ? (
                  <ActiveInvestments investments={investments} />
                ) : (
                  <DeclineInvestments investments={investments} />
                )
              ) : null}
        </div>
      </div>
        </>
       )}

      {/* Modal Logic */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-black p-6 rounded-lg w-[30%] shadow-lg">
            <div className="flex items-center cursor-pointer justify-end">
              <LiaTimesSolid onClick={handleCloseModal} />
            </div>
            <p className="font-semibold text-[40px] text-center font-jakarta leading-[40px] mb-4">
              Upgrade to a Co-Owner Account
            </p>
            <p className="text-center font-jakarta leading-[20px]">
              To access the Portfolio, you have to upgrade to a co-owner’s account by uploading your KYC verification documents
            </p>
            <p
              className="bg-[#DEA838] p-3 font-jakarta rounded-[5px] cursor-pointer text-center font-[700] text-[16px] text-black my-4"
              onClick={handleUpgradeClick} // Call the route function here
            >
              Upgrade
            </p>
            <p
              className="border border-[#DEA838] text-[#DEA838] text-[16px] font-jakarta text-center cursor-pointer rounded-[5px] p-3"
              onClick={handleCloseModal}
            >
              Cancel
            </p>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default Portfolio;
