import React, { useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { BsUpload } from 'react-icons/bs';
import { createTrue } from 'typescript';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface ModalContentProps {
    property: any;
    close: () => void;
}
const AnotherModal: React.FC<{ close: () => void, slots: any, property: any }> = ({ close, slots, property }) => {
    const navigate = useNavigate();
    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | undefined>(undefined);
const [loading, setLoading]= useState(false)
    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);

            // Create a preview URL using FileReader
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        }
    };
const [open, setOpen] = useState (false)
const userData = useSelector((state: any) => state.login.userData);
const baseUrl = process.env.REACT_APP_BASE_URL;
console.log(userData?.data?.token)
const handleSuccess = async () => {
    setLoading(true);
  
    const payload = {
      property_id: property.id,
      slots: slots,
      files: [file?.name],
    };
  
    try {
      const response = await fetch(`${baseUrl}/user/properties/payment/send`, {
        method: 'POST', // Changed to POST
        headers: {
          "Authorization": `Bearer ${userData?.data?.token}`,
          "Content-Type": "application/json", // Added Content-Type header
        },
        body: JSON.stringify(payload), // Included the payload as the request body
      });
  
      if (!response.ok) {
        throw new Error('Failed to toggle favorite');
      }
  
      const data = await response.json();
     if(data?.status === 200){
        setOpen(true);
     }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Ensure loading state is updated
    }
  
    
  };
  

    return (
        <div className="w-[30%] bg-[#1E1E1E] rounded-[10px] border border-[#6F767E] p-7">
            <div className='flex flex-row-reverse justify-between'>
                <MdOutlineClose onClick={close} className='flex justify-end' />
            </div>

            {
                open ? (
                    <div className="flex flex-col text-center font-jakarta align-middle items-center justify-center">
                    <img src="/assets/images/success.svg" className="h-[70px] w-[70px] mb-[10px]"/>

                    <p className="text-[30px] font-[600] font-jakarta w-[60%]">Slot Purchase</p>
                    <p className="text-[30px] font-[600] font-jakarta w-[60%]">in progress</p>
                    <p className="text-[#9A9FA5] font-jakarta w-[80%]">
                    We will validate your receipt and notify you on your purchase 
                    </p>
                    <button className="my-[30px] font-jakarta py-[12px] w-full font-[700] rounded-[10px] px-[20px] bg-[#DEA838] text-black"
                    onClick={() => navigate("/overview")}
                    >
                     Done
                    </button>
                  </div>
                ) : (
                    <>
            <p className='text-[20px] text-start pb-[10px] font-jakarta'>Upload Payment Proof</p>
            <hr className="border-t-[1px] border-[#33383F] pb-[10px]" />
            <p className='text-start py-[4px] text-[14px] font-jakarta'>Select File(s)</p>
            <label className="flex flex-col gap-3 py-4 items-center justify-center w-full border-2 border-[#33383F] bg-[#1E1E1E] rounded-lg">
                <div className="flex flex-col items-center justify-center">
                    <div className='flex flex-row gap-5 items-center border-[#33383F] bg-[#1E1E1E]'>
                        <p className="text-[16px] text-white mb-2">
                            {file ? (
                                <div className="mt-4">
                                    {file.type.includes('image/') ? (
                                        <img
                                            src={preview}
                                            alt="Uploaded File"
                                            className="max-w-full h-[10vh] rounded-lg"
                                        />
                                    ) : (
                                        <p className="text-white font-jakarta">File type not supported for preview.</p>
                                    )}
                                </div>
                            ) : (
                                <p className='font-jakarta'> No file chosen</p>
                            )

                            }

                        </p>


                        <p className=" rounded-[5px] text-[14px] border border-[#8c8b8a] py-[6px] px-[35px] flex items-center gap-1 cursor-pointer"> <img src='/assets/images/icon.svg' alt='icon' className="w-4 h-4 text-white" />Select File</p>
                        <input
                            id="id_card"
                            name="id_card"
                            type="file"
                            accept="application/pdf, image/png, image/jpeg"
                            className="hidden"
                            onChange={handleChanges}
                        />

                    </div>

                </div>
            </label>
            <div className='flex justify-end items-end mt-[10px]'>
                    <button
                        className='bg-[#DEA838] font-jakarta px-[35px] rounded-[10px] py-[8px] font-[700] text-[14px] text-black'
                        onClick={handleSuccess}
                    >
                        {loading ? "Submitting--":"Submit"}
                    </button>
                </div>
                    
                    </>
                )
            }

        </div>
    );
};

const ConfirmationModal: React.FC<{ close: () => void; openNextModal: () => void }> = ({ close, openNextModal }) => {
    return (
        <div className="w-[30%] bg-[#1E1E1E] rounded-[10px] border border-[#6F767E] p-7">
            
            <p className="text-center font-jakarta text-white text-[20px] mt-5 pb-[10px]">
                Please pay using any of the following
            </p>
            <hr className="border-t-[1px] border-[#33383F] pb-[10px]" />
            <div className='flex flex-col gap-5'>
                <div className='flex flex-row justify-between'>
                    <p className='text-[13px] font-jakarta text-[#92989e]'>Account number</p>
                    <p className='text-[14px] font-jakarta'>Maihomm management limited</p>
                </div>
                <div className='flex flex-row justify-between'>
                    <p className='text-[13px] font-jakarta text-[#92989e]'>Bank Name</p>
                    <p className='text-[14px] font-jakarta'>Zenith Bank</p>
                </div>
                <div className='flex flex-row justify-between'>
                    <p className='text-[13px] font-jakarta text-[#92989e]'>Naira Account Number</p>
                    <p className='text-[14px] font-jakarta'>1226902217</p>
                </div>
                <div className='flex flex-row justify-between'>
                    <p className='text-[13px] font-jakarta text-[#92989e]'>GBP Account Number</p>
                    <p className='text-[14px] font-jakarta'>1226902217</p>
                </div>
                <div className='flex flex-row justify-between'>
                    <p className='text-[13px] font-jakarta text-[#92989e]'>USD Account Number</p>
                    <p className='text-[14px] font-jakarta'>1226902217</p>
                </div>
                <p className='text-start text-[#DEA838] text-[14px]'>Or</p>
                <div className='flex flex-col gap-5'>
                    <div className='flex flex-row justify-between'>
                        <p className='text-[13px] font-jakarta text-[#92989e]'>Bank Name</p>
                        <p className='text-[14px] font-jakarta'>1226902217</p>
                    </div>
                </div>
                <div className='flex flex-row justify-between'>
                    <p className='text-[13px] font-jakarta text-[#92989e]'>GBP Account Number</p>
                    <p className='text-[14px] font-jakarta'>78740715</p>
                </div>
            </div>
            <div className='flex justify-center items-center w-full mt-5'>
                <button
                    className='bg-[#DEA838] font-jakarta px-[35px] rounded-[10px] w-full py-[8px] font-[700] text-[14px] text-black'
                    onClick={openNextModal}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

const NewModal: React.FC<{ close: () => void, slots: any, property: any }> = ({ close, slots, property }) => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isAnotherModalOpen, setIsAnotherModalOpen] = useState(false); // State for the third modal
    const [message, setMessage]= useState<string>("")
    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
    };

    const handleContinue = () => {
        if (selectedOption) {
            console.log('Selected Payment Option:', selectedOption);
            setIsConfirmationModalOpen(true);
        } else {
            setMessage('Please select a payment option.')
            setTimeout(()=>{
                setMessage("")
            },2000)
        }
    };

    return (
        <div className="w-[180%] bg-[#1E1E1E] rounded-[10px] border border-[#6F767E] p-7">
            <div className='flex flex-row-reverse justify-between'>
                <MdOutlineClose onClick={close} className='flex justify-end' />
                <p className='text-[20px] font-jakarta'>Payment Options</p>
            </div>
            <div>
                {/* <div
                    className={`flex gap-2 cursor-pointer py-[15px] ${selectedOption === 'Wallet' ? 'text-[#DEA838]' : ''}`}
                    onClick={() => handleOptionClick('Wallet')}
                >
                    <img src='/assets/images/duotone.svg' alt='kf' />
                    <p className='text-[14px] font-jakarta'>Pay with Wallet</p>
                </div>
                <hr className="border-t-[1px] border-[#33383F]" /> */}
                <div
                    className={`flex gap-2 cursor-pointer font-jakarta py-[15px] ${selectedOption === 'Transfer' ? 'text-[#DEA838]' : ''}`}
                    onClick={() => handleOptionClick('Transfer')}
                >
                    <img src='/assets/images/duotone.svg' alt='kf' />
                    <p className='text-[14px] font-jakarta'>Pay with Transfer</p>
                </div>
                {/* <hr className="border-t-[1px] border-[#33383F]" />
                <div
                    className={`flex gap-2 cursor-pointer font-jakarta py-[15px] ${selectedOption === 'Both' ? 'text-[#DEA838]' : ''}`}
                    onClick={() => handleOptionClick('Both')}
                >
                    <img src='/assets/images/duotone.svg' alt='kf' />
                    <p className='text-[14px] font-jakarta'>Pay with Both</p>
                </div> */}
                <p className='text-red-500 font-jakarta text-[12px]'>{message}</p>
                <div className='flex justify-end items-end'>
                    <button
                        className='bg-[#DEA838] font-jakarta px-[35px] rounded-[10px] py-[8px] font-[700] text-[14px] text-black'
                        onClick={handleContinue}
                    >
                        Continue
                    </button>
                </div>
            </div>
            {isConfirmationModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <ConfirmationModal
                        close={() => setIsConfirmationModalOpen(false)}
                        openNextModal={() => {
                            setIsConfirmationModalOpen(false);
                            setIsAnotherModalOpen(true);
                        }}
                    />
                </div>
            )}
            {isAnotherModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <AnotherModal close={() => setIsAnotherModalOpen(false)} slots={slots} property={property}/>
                </div>
            )}
        </div>
    );
};

const ModalContent: React.FC<ModalContentProps> = ({ close, property }) => {
    const [slots, setSlots] = useState(1);
    const [amount, setAmount] = useState('');
    const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
    const pricePerSlot = 300000;
    const increaseSlots = () => {
        setSlots(slots + 1);
    };

    const decreaseSlots = () => {
        if (slots > 1) {
            setSlots(slots - 1);
        }
    };

    const handleSubmit = () => {
        console.log('Amount submitted:', amount);
        setIsSecondModalOpen(true);
    };

    const totalPrice = property?.costings?.per_Slot * slots;
    const totalPriceWithAdditional = totalPrice + 100;

    return (
        <>
            {!isSecondModalOpen ? (
                <div className="w-[130%] bg-[#1E1E1E] rounded-[10px] border border-[#6F767E] p-7">
                    <div className='flex flex-row-reverse justify-between'>
                        <MdOutlineClose onClick={close} className='flex justify-end' />
                        <p className='font-jakarta'>Buy Slot</p>
                    </div>
                    <div className='flex items-center gap-3 justify-start border my-[10px] rounded-[10px] border-[#33383F] p-5'>
                        <div>
                            <img src={property?.default_image?.url} alt='house' className='h-20 w-20'  />
                        </div>
                        <div className='flex flex-col justify-start text-start'>
                            <p className='text-[14px] font-jakarta'>{property?.name}</p>
                            <p className='text-[10px] font-jakarta text-[#9A9FA5]'>{property?.location?.address}</p>
                        </div>
                    </div>
                    <div className='flex justify-between my-[15px] items-center'>
                        <p className='text-[14px] font-jakarta text-[#9A9FA5]'>Price per slot</p>
                        <p className='text-[14px] font-jakarta'>{property?.costings?.per_Slot.toLocaleString()}</p>
                    </div>

                    <div className='flex justify-between mb-[15px] items-center'>
                        <div className='flex flex-col justify-start text-start'>
                            <p className='text-[14px] font-jakarta'>Number of slots</p>
                            <p className='text-[10px] font-jakarta text-[#9A9FA5]'>You can buy at least 1 and at most 300 slots</p>
                        </div>
                        <div className="flex gap-10 rounded-[10px] items-center border border-[#d1cfcf]">
                            <span className="text-[14px] px-[10px] font-jakarta font-semibold">{slots}</span>
                            <div className='border font-jakarta px-[5px] border-[#d1cfcf]'>
                                <button
                                    onClick={increaseSlots}
                                    className="flex items-center justify-center"
                                >
                                    <IoIosArrowUp />
                                </button>
                                <button
                                    onClick={decreaseSlots}
                                    className="flex items-center justify-center"
                                >
                                    <IoIosArrowDown />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-between items-center'>
                        <p className='text-[14px] font-jakarta text-[#9A9FA5]'>Total Price</p>
                        <p className='text-[14px] font-jakarta'>{totalPrice.toLocaleString()}</p>
                    </div>
                    <div className='bg-[#33383F] mt-4 px-[10px] rounded-[10px]'>
                        <p className='flex items-center gap-1 text-[14px] font-jakarta text-[#9A9FA5]'>Amount <span>o</span></p>
                        <p className='text-start font-jakarta'>{totalPriceWithAdditional.toLocaleString()}</p> {/* Display total price with 100 */}
                    </div>
                    <div className='flex justify-end font-jakarta items-end w-full my-[10px]'>
                        <button
                            onClick={handleSubmit}
                            className="bg-[#DEA838] text-black p-2 w-[50%] rounded"
                        >
                            Buy Slot
                        </button>
                    </div>
                </div>
            ) : (
                <NewModal close={() => setIsSecondModalOpen(false)} slots={slots} property={property}/>
            )}
        </>
    );
};

export default ModalContent;
